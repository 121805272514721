import { all } from 'redux-saga/effects'

//public
import accountSaga from './auth/register/saga';
import loginSaga from './auth/login/saga';
import forgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';

//users
import usersSaga from './users/saga';
import carriersSaga from './carrier/saga';
import boxSaga from './boxes/saga';
import inboundSaga from './Inbound/saga';
import DocumentStates from './documentState/saga';
import LoadPlanSaga from './LoadPlans/saga';
import RemittanceSaga from './Remittance/saga';
import FilesSaga from './Files/saga';
import TransferSaga from './transfer/saga';
import NotificationSaga from './notification/saga';

export default function* rootSaga() {
    yield all([

        //public
        accountSaga(),
        loginSaga(),
        LayoutSaga(),
        usersSaga(),
        forgetSaga(),
        carriersSaga(),
        boxSaga(),
        inboundSaga(),
        DocumentStates(),
        LoadPlanSaga(),
        RemittanceSaga(),
        FilesSaga(),
        TransferSaga(),
        NotificationSaga(),
        
    ])
}