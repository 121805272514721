import toast from 'react-hot-toast';
import { createBrowserHistory } from 'history';



export default function SetupAxios(axios, store) {
  const history = createBrowserHistory();
  axios.interceptors.request.use(
    config => {
      const {
        Login: { user: { token } },
        Login: { user: { id } },
        Login: { user: { role } },
      } = store.getState();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if(id && role){
        if(role.name== 'Carrier' && config?.params!==undefined){
          config.params['CarrierId']= id;
        }
      }

      return config;
    },
    error => {
      return Promise.reject(error);

    }
  );
  axios.interceptors.response.use(undefined, error => {
    const { status, data, config } = error.response;
    if (status === 400) {
       if(!data?.errors){
        toast.error(data.message)
       }
      return Promise.reject(error);
    } else if (status === 401) {
      window.location.href = `/logout`;
      return Promise.reject(error);
    }else if(status === 404){
      return Promise.reject(error);
    }
  }
  );


}