import * as api from "../../helpers/api_helper";
import qs from "qs";
import { GET, GET_ALL, GET_ALL_XML, GET_TYPES, POST, PUT, PUT_ASSOCIATE, PUT_DISSASOCIATE, PUT_TOGGLE, PUT_OPEN, PUT_REJECT, DOWNLOAD, SAVE_PREVALORIZATION_URL } from "./url";

export const getAll = (filter) => api.get(GET_ALL, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});

export const getAllTransmissions = (filter) => api.get(GET_ALL_XML, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});

export const getByDocumentNumber = (filter) => api.get(GET_ALL, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});
export const getTypes = (filter) => api.get(GET_TYPES, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});

export const exportDocument = (filter) => api.download(DOWNLOAD, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});
export const disassociateService = (id) => api.put(PUT_DISSASOCIATE, { id: id });
export const associateService = (documentNumber) => api.put(PUT_ASSOCIATE, { ...documentNumber });
export const openService = (id) => api.put(PUT_OPEN, { id: id });
export const rejectService = (data) => api.put(PUT_REJECT, data );
export const get = (id) => api.get(`${GET}${id}`);
export const post = (user) => api.post(POST, user);
export const update = (user) => api.put(PUT, user);
export const putTypes = (entity) => api.put(PUT_TOGGLE, entity);