import {
  REMOVE,
  GET,
  GET_FAIL,
  GET_SUCCESS,
  GET_ALL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  SAVE,
  SAVE_FAIL,
  SAVE_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  DISSASOCIATE,
  DISSASOCIATE_FAIL,
  DISSASOCIATE_SUCCESS,
  GET_XML_TRANSSMITIONS,
  GET_XML_TRANSSMITIONS_SUCCESS,
  GET_XML_TRANSSMITIONS_FAIL,
  TYPES,
  TYPES_SUCCESS,
  TYPES_FAIL,
  UPDATE_TYPES,
  UPDATE_TYPES_SUCCESS,
  UPDATE_TYPES_FAIL,
  GET_BY_DOCUMENT_NUMBER,
  GET_BY_DOCUMENT_NUMBER_SUCCESS,
  GET_BY_DOCUMENT_NUMBER_FAIL,
  ASSOCIATE,
  ASSOCIATE_SUCCESS,
  ASSOCIATE_FAIL,
  OPEN,
  OPEN_SUCCESS,
  OPEN_FAIL,
  REJECT,
  REJECT_SUCCESS,
  REJECT_FAIL,
  DOWNLOAD,
  SET_FILTER,
  CLEAR_FILTER,
} from "./actionTypes"

export const getAll = (payload) => ({
  type: GET_ALL,
  payload
})

export const setFilter = (payload) => ({
  type: SET_FILTER,
  payload
})

export const clearFilter = () => ({
  type: CLEAR_FILTER
})

export const download = (payload) => ({
  type: DOWNLOAD,
  payload
})
export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})

export const getAllXml = (payload) => ({
  type: GET_XML_TRANSSMITIONS,
  payload
})

export const getAllXmlSuccess = list => ({
  type: GET_XML_TRANSSMITIONS_SUCCESS,
  payload: list,
})

export const getAllXmlFail = error => ({
  type: GET_XML_TRANSSMITIONS_FAIL,
  payload: error,
})

export const getAllTypes = (payload) => ({
  type: TYPES,
  payload
})

export const getAllTypesSuccess = list => ({
  type: TYPES_SUCCESS,
  payload: list,
})

export const getAllTypesFail = error => ({
  type: TYPES_FAIL,
  payload: error,
})


export const updateType = (entity) => ({
  type: UPDATE_TYPES,
  payload: entity
})

export const updateTypeSuccess = result => ({
  type: UPDATE_TYPES_SUCCESS,
  payload: result,
})

export const updateTypeFail = error => ({
  type: UPDATE_TYPES_FAIL,
  payload: error,
})
export const get = (id) => ({
  type: GET,
  payload: id,
})

export const getSuccess = entity => ({
  type: GET_SUCCESS,
  payload: entity,
})

export const getFail = error => ({
  type: GET_FAIL,
  payload: error,
})


export const getByDocument = (number) => ({
  type: GET_BY_DOCUMENT_NUMBER,
  payload: number,
})

export const getByDocumentSuccess = entity => ({
  type: GET_BY_DOCUMENT_NUMBER_SUCCESS,
  payload: entity,
})

export const getByDocumentFail = error => ({
  type: GET_BY_DOCUMENT_NUMBER_FAIL,
  payload: error,
})


export const save = (entity) => ({
  type: SAVE,
  payload: entity
})

export const saveSuccess = result => ({
  type: SAVE_SUCCESS,
  payload: result,
})

export const saveFail = error => ({
  type: SAVE_FAIL,
  payload: error,
})

export const update = (entity) => ({
  type: UPDATE,
  payload: entity
})

export const updateSuccess = result => ({
  type: UPDATE_SUCCESS,
  payload: result,
})

export const updateFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})

export const disassociate = (id) => ({
  type: DISSASOCIATE,
  payload: id
})

export const disassociateSuccess = result => ({
  type: DISSASOCIATE_SUCCESS,
  payload: result,
})

export const disassociateFail = error => ({
  type: DISSASOCIATE_FAIL,
  payload: error,
})


export const associate = (id) => ({
  type: ASSOCIATE,
  payload: id
})

export const associateSuccess = result => ({
  type: ASSOCIATE_SUCCESS,
  payload: result,
})

export const associateFail = error => ({
  type: ASSOCIATE_FAIL,
  payload: error,
})


export const open = (documentNumber) => ({
  type: OPEN,
  payload: documentNumber
})

export const openSuccess = result => ({
  type: OPEN_SUCCESS,
  payload: result,
})

export const openFail = error => ({
  type: OPEN_FAIL,
  payload: error,
})


export const reject = (id) => ({
  type: REJECT,
  payload: id
})

export const rejectSuccess = result => ({
  type: REJECT_SUCCESS,
  payload: result,
})

export const rejectFail = error => ({
  type: REJECT_FAIL,
  payload: error,
})
