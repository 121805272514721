let entity = "BOXES"
export const GET_ALL = `GET_ALL_${entity}`
export const GET_ALL_SUCCESS = `GET_ALL_${entity}_SUCCESS`
export const GET_ALL_FAIL = `GET_ALL_${entity}_FAIL`

export const GET = `GET_${entity}`
export const GET_SUCCESS = `GET_${entity}_SUCCESS`
export const GET_FAIL = `GET_${entity}_FAIL`

export const GET_BY_USER = `GET_BY_USER_${entity}`
export const GET_BY_USER_SUCCESS = `GET_BY_USER_${entity}_SUCCESS`
export const GET_BY_USER_FAIL = `GET_BY_USER_${entity}_FAIL`

export const REMOVE = `REMOVE_${entity}`
export const REMOVE_SUCCESS = `REMOVE_${entity}_SUCCESS`
export const REMOVE_FAIL = `REMOVE_${entity}_FAIL`

export const SAVE = `SAVE_${entity}`
export const SAVE_SUCCESS = `SAVE_${entity}_SUCCESS`
export const SAVE_FAIL = `SAVE_${entity}_FAIL`

export const UPDATE = `UPDATE_${entity}`
export const UPDATE_SUCCESS = `UPDATE_${entity}_SUCCESS`
export const UPDATE_FAIL = `UPDATE_${entity}_FAIL` 
