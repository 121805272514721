import React, { Component, useState } from 'react';
import {useSelector} from 'react-redux';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from '../../../assets/images/users/avatar-2.png';
import { useHistory } from 'react-router-dom';
import Premission from '../../Common/Premission';


const ProfileMenu = (props) => {
    const user = useSelector(state => state.Login?.user);
    const [menu, setMenu] = useState(false);
    const toggle = () => setMenu(!menu);
    const history = useHistory();

    let username = "Guess";
    let role = "";
    // if (localStorage.getItem("authUser")) {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     if (obj !== undefined) {
            const uNm = user?.email? user?.email?.split("@")[0]:"Guest";
            role = user?.role?.name??"Guest";
            username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    //     }
    // }
    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
                <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                    <img className="rounded-circle header-profile-user me-1" src={avatar2} alt={username} />
                    <span className="d-none d-xl-inline-block ms-1 text-transform">{username} [{role.slice(0, 1)}]</span>
                    <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem disabled href="#"><i className="ri-user-line align-middle me-1"></i> {props.t('Profile')}</DropdownItem>
                    <Premission userType={props.role?.name} userTypeAllowed={["Admin"]} >
                    <DropdownItem onClick={() => history.push('/users')}><i className="ri-user-2-line align-middle me-1"></i> {props.t('Users')}</DropdownItem>
                    <DropdownItem disabled className="d-block" href="#"><span className="badge badge-success float-end mt-1">11</span><i className="ri-settings-2-line align-middle me-1"></i> {props.t('Settings')}</DropdownItem>
                    </Premission>
                    {/* <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle me-1"></i> {props.t('Lock screen')}</DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem className="text-danger" href="/logout"><i className="ri-shut-down-line align-middle me-1 text-danger"></i> {props.t('Logout')}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}


export default withNamespaces()(ProfileMenu);
