let entity = "INBOUND"
export const GET_ALL = `GET_ALL_${entity}`
export const GET_ALL_SUCCESS = `GET_ALL_${entity}_SUCCESS`
export const GET_ALL_FAIL = `GET_ALL_${entity}_FAIL`

export const GET = `GET_${entity}`
export const GET_SUCCESS = `GET_${entity}_SUCCESS`
export const GET_FAIL = `GET_${entity}_FAIL`

export const GET_BY_DOCUMENT_NUMBER = `GET_BY_DOCUMENT_NUMBER_${entity}`
export const GET_BY_DOCUMENT_NUMBER_SUCCESS = `GET_BY_DOCUMENT_NUMBER_${entity}_SUCCESS`
export const GET_BY_DOCUMENT_NUMBER_FAIL = `GET_BY_DOCUMENT_NUMBER_${entity}_FAIL`

export const SAVE = `SAVE_${entity}`
export const SAVE_SUCCESS = `SAVE_${entity}_SUCCESS`
export const SAVE_FAIL = `SAVE_${entity}_FAIL`

export const UPDATE = `UPDATE_${entity}`
export const UPDATE_SUCCESS = `UPDATE_${entity}_SUCCESS`
export const UPDATE_FAIL = `UPDATE_${entity}_FAIL` 

export const DISSASOCIATE = `DISSASOCIATE_${entity}`
export const DISSASOCIATE_SUCCESS = `DISSASOCIATE_${entity}_SUCCESS`
export const DISSASOCIATE_FAIL = `DISSASOCIATE_${entity}_FAIL` 

export const REJECT = `REJECT_${entity}`
export const REJECT_SUCCESS = `REJECT_${entity}_SUCCESS`
export const REJECT_FAIL = `REJECT_${entity}_FAIL` 

export const ASSOCIATE = `ASSOCIATE_${entity}`
export const ASSOCIATE_SUCCESS = `ASSOCIATE_${entity}_SUCCESS`
export const ASSOCIATE_FAIL = `ASSOCIATE_${entity}_FAIL` 

export const OPEN = `OPEN${entity}`
export const OPEN_SUCCESS = `OPEN_${entity}_SUCCESS`
export const OPEN_FAIL = `OPEN_${entity}_FAIL` 

export const GET_XML_TRANSSMITIONS = `XML_TRANSSMITIONS_${entity}`
export const GET_XML_TRANSSMITIONS_SUCCESS = `XML_TRANSSMITIONS_${entity}_SUCCESS`
export const GET_XML_TRANSSMITIONS_FAIL = `XML_TRANSSMITIONS_${entity}_FAIL` 

export const TYPES = `TYPES_${entity}`
export const TYPES_SUCCESS = `TYPES_${entity}_SUCCESS`
export const TYPES_FAIL = `TYPES_${entity}_FAIL` 

export const UPDATE_TYPES = `UPDATE_TYPES_${entity}`
export const UPDATE_TYPES_SUCCESS = `UPDATE_TYPES_${entity}_SUCCESS`
export const UPDATE_TYPES_FAIL = `UPDATE_TYPES_${entity}_FAIL` 

export const DOWNLOAD = `DOWNLOAD_${entity}`;

export const SET_FILTER = `SET_FILTER_${entity}`;
export const CLEAR_FILTER = `CLEAR_FILTER_${entity}`;

