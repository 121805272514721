import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAll, markAsRead } from '../../store/notification/actions';
import NotificationsModal from './NotificationsModal';

const Notifications = ({Notifications, getAll, markAsRead}) => {
    useEffect(()=>{
        getAll();
    }, [])
    
    return (
        <div>
           {Notifications?.entities?.data?.length>0 && <NotificationsModal 
           Notifications={Notifications?.entities?.data??[]}
           MarkAsRead={markAsRead} />} 
        </div>
    );
};

const mapStatetoProps = state => {
    return {
    Notifications: state.Notification
    };
  };
export default connect(mapStatetoProps, {getAll, markAsRead})(Notifications);