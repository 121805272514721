import {
  GET_FAIL,
  GET_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_TYPES_FAIL,
  GET_USER_TYPES_SUCCESS,
  SAVE_FAIL,
  REMOVE,
  REMOVE_FAIL,
  REMOVE_SUCCESS,
  SAVE_SUCCESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  TOGGLE,
  TOGGLE_SUCCESS,
  TOGGLE_FAIL,
  UPDATE,
  SAVE,
  CLEAR_PASSWORD_VARIABLES,
  GET_COUNT,
  GET_COUNT_SUCCESS,
  GET_USERS,
  GET

} from "./actionTypes"

const INIT_STATE = {
  users: [],
  entity: undefined,
  types: [],
  error: {},
  updatingPassword: false,
  updatedPassword: false,
  updatingEntity: false,
  toggled: false,
  updatedEntity: false,
  saving: false,
  saved: false,
  totalCount: 0,
  isFetching:false,
}

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      }
    case GET_COUNT_SUCCESS:
      return {
        ...state,
      }

    case GET_USERS:
      return{
        ...state,
        error:[],
        isFetching: true
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.entities ?? action.payload,
        updatedEntity: false,
        updatedPassword: false,
        updatingEntity: false,
        toggled: false,
        saving: false,
        saved: false,
        isFetching: false,
        totalCount: action.payload?.totalCount,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }

    case GET: 
      return {...state,isFetching: true,}

    case GET_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        updatedEntity: false,
        updatedPassword: false,
        updatingEntity: false,
        toggled: false,
        isFetching: false,
      }

    case GET_FAIL:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }

    case REMOVE_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        updatedEntity: false,
        updatedPassword: false,
        updatingEntity: false,
        toggled: false,
        saved: true,
        saving: false,
        isFetching: false,
      }

    case REMOVE:
      return {
        ...state,
        error: {},
        saving: true,
        saved: false,
      }

    case REMOVE_FAIL:
      return {
        ...state,
        error: action.payload,
        saving: false,
        saved: false,
      }

    case SAVE:
      return {
        ...state,
        error: {},
        saving: true,
        saved: false,
      }

    case SAVE_SUCCESS:
      return {
        ...state,
        error: {},
        saving: false,
        saved: true,
        result: action.payload,
        updatedEntity: false,
        updatedPassword: false,
        updatingEntity: false,
        toggled: false,
      }

    case SAVE_FAIL:
      return {
        ...state,
        saving: false,
        saved: false,
        error: action.payload,
      }

    case UPDATE:
      return {
        ...state,
        updatedEntity: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        error: {},
        result: action.payload,
        updatedEntity: true,
      }

    case UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatedEntity: false
      }


    case CHANGE_PASSWORD:
      return {
        ...state,
        error: {},
        updatingPassword: true,
        updatedPassword: false,
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatingPassword: false,
        updatedPassword: true,
      }

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingPassword: false,
        updatedPassword: false,
      }

    case CLEAR_PASSWORD_VARIABLES:
      return {
        ...state,
        updatingPassword: false,
        updatedPassword: false,
      }


    case TOGGLE:
      return {
        ...state,
        error: {},
        updatingEntity: true,
        toggled: false,
      }

    case TOGGLE_SUCCESS:
      return {
        ...state,
        updatingEntity: false,
        toggled: true,
      }

    case TOGGLE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingEntity: false,
        toggled: false,
      }

    case GET_USER_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
      }

    case GET_USER_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Users
