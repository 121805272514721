import React, { Component } from "react";

import { connect, useSelector } from "react-redux";

import { Link, useHistory, useParams } from "react-router-dom";

// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, Form, Input, InputGroup, InputGroupAddon, Button, FormGroup } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";


// Redux Store
import ProcessDropdown from "../CommonForBoth/TopbarDropdown/ProcessDropdown";
import Premission from "../Common/Premission";

const Header = ({ user }) => {
    const params = useParams();
    const history = useHistory();
    const Role = useSelector(state => state.Login?.user?.role);
    const RouteCanUpload = history.location.pathname.includes("inbound") || history.location.pathname.includes("outbound");
    const GetRoute = (location) => location.includes("inbound") ? "/inbound" : "/outbound"
    return (<React.Fragment>
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <Link to="/" className="logo logo-dark">
                            <span className="logo-sm">
                                {/* <img src={logoSmDark} alt="" height="50" /> */}
                                Acindar
                            </span>
                            <span className="logo-lg">
                                {/* <img src={logoDark} alt="" height="40" /> */}
                                Acindar
                            </span>
                        </Link>

                        <Link to="/" className="logo logo-light">
                            <span className="logo-sm">
                                {/* <img src={logoSmLight} alt="" height="22" /> */}
                                Acindar
                            </span>
                            <span className="logo-lg text-white" style={{ fontSize: 25 }}>
                                {/* <img src={logoLight} alt="" height="20" /> */}
                                Acindar
                            </span>
                        </Link>
                    </div>

                    {/* <Button color="none" type="button" size="sm" onClick={() => toggleRightSidebar()} className="px-3 font-size-24 d-lg-none header-item" data-toggle="collapse" data-target="#topnav-menu-content">
                        <i className="ri-menu-2-line align-middle"></i>
                    </Button> */}



                </div>


                <div className="d-flex">
                    <Premission userType={Role?.name} userTypeAllowed={["Carrier"]} >
                        {
                            RouteCanUpload && <div onClick={() => history.push(`${GetRoute(history.location.pathname)}/upload`)} className="d-inline-block bg-success">
                                <Button type="button" color="none" className="header-item noti-icon right-bar-toggle waves-effect">
                                    <i className="ri-upload-cloud-line"></i>
                                </Button>
                            </div>
                        }
                    </Premission>
                    <Premission userType={Role?.name} userTypeAllowed={["Admin"]} >
                        <div onClick={() => history.push("/settings/transmissions/")} className="d-inline-block">
                            <Button type="button" color="none" title="Re-transmitir archivos XML" className="header-item noti-icon right-bar-toggle waves-effect">
                                <i className="mdi mdi-clipboard-flow-outline"></i>
                            </Button>
                        </div>
                    </Premission>
                    <Premission userType={Role?.name} userTypeAllowed={["Admin"]} >
                        <div onClick={() => history.push("/settings/parameterization")} className="d-inline-block">
                            <Button type="button" color="none" title="Tabla de parametrización" className="header-item noti-icon right-bar-toggle waves-effect">
                                <i className="mdi mdi-table-settings"></i>
                            </Button>
                        </div>
                    </Premission>
                    <ProcessDropdown />

                    <Premission userType={Role?.name} userTypeAllowed={["Admin", "Viewer"]} >
                        <div onClick={() => history.push("/boxes")} className="d-inline-block">
                            <Button type="button" color="none" className="header-item noti-icon right-bar-toggle waves-effect">
                                <i className="mdi mdi-package-variant"></i>
                            </Button>
                        </div>
                    </Premission>



                    <NotificationDropdown />


                    <ProfileMenu role={Role} />

                    {/* <div onClick={()=>toggleRightbar()} className="dropdown d-inline-block">
                        <Button type="button" color="none" className="header-item noti-icon right-bar-toggle waves-effect">
                            <i className="ri-settings-2-line"></i>
                        </Button>
                    </div> */}

                </div>
            </div>
        </header>
    </React.Fragment>)

}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    const { user } = state.Login?.user
    return { layoutType, user };
};

export default connect(mapStatetoProps, null)(withNamespaces()(Header));
