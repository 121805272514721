import React from "react";

function Icon({size="16px"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        fill="#211E48"
        d="M166.252 512c-7.227 0-14.4-2.275-20.498-6.737L16.706 410.806C7.667 404.19 2.48 393.97 2.48 382.768c0-11.203 5.185-21.424 14.224-28.042l129.051-94.457c10.619-7.772 24.507-8.903 36.244-2.949 11.737 5.954 19.027 17.829 19.027 30.99v10.616h248.521c33.069 0 59.973 26.904 59.973 59.973v47.737c0 33.069-26.904 59.973-59.973 59.973h-34.835c-8.348 0-15.115-6.769-15.115-15.115s6.767-15.115 15.115-15.115h34.835c16.4 0 29.743-13.342 29.743-29.743v-47.737c0-16.4-13.342-29.743-29.743-29.743H185.91c-8.348 0-15.115-6.769-15.115-15.115V288.31c0-2.293-1.345-3.457-2.474-4.03-1.129-.571-2.863-.97-4.711.384L34.56 379.121c-1.608 1.177-1.849 2.79-1.849 3.646 0 .856.24 2.467 1.849 3.644l129.051 94.457c1.852 1.354 3.584.957 4.711.384 1.129-.571 2.473-1.735 2.473-4.028v-25.731c0-8.347 6.767-15.115 15.115-15.115h162.796c8.348 0 15.115 6.769 15.115 15.115s-6.767 15.115-15.115 15.115h-147.68v10.616c0 13.161-7.292 25.036-19.027 30.99-5 2.533-10.387 3.786-15.747 3.786z"
      ></path>
      <path
        fill="#B0DACC"
        d="M357.317 239.541l129.049-94.457c10.715-7.843 10.715-23.841 0-31.685L357.317 18.941c-12.97-9.494-31.228-.231-31.228 15.842v25.731H62.454c-24.774 0-44.858 20.084-44.858 44.858v47.737c0 24.774 20.084 44.858 44.858 44.858h263.635v25.731c-.001 16.074 18.259 25.337 31.228 15.843z"
      ></path>
      <path
        fill="#211E48"
        d="M345.797 258.482h-.003c-19.201-.002-34.821-15.605-34.821-34.783v-10.616H62.454c-33.069 0-59.973-26.904-59.973-59.973v-47.737c0-33.069 26.904-59.973 59.973-59.973h248.521V34.783C310.973 15.604 326.595 0 345.796 0c7.351 0 14.423 2.332 20.45 6.744l129.049 94.457c9.039 6.616 14.225 16.836 14.225 28.037 0 11.204-5.185 21.425-14.224 28.042l-129.051 94.457c-6.028 4.414-13.098 6.745-20.448 6.745zM62.454 75.63c-16.4 0-29.743 13.342-29.743 29.743v47.737c0 16.4 13.342 29.743 29.743 29.743H326.09c8.348 0 15.115 6.769 15.115 15.115v25.731c0 3.439 3.094 4.553 4.594 4.553.91 0 1.758-.296 2.591-.907l129.049-94.457c1.608-1.177 1.849-2.79 1.849-3.646 0-.856-.24-2.467-1.849-3.644L348.388 31.139c-.834-.611-1.684-.908-2.594-.908-1.498 0-4.591 1.112-4.591 4.553v25.731c0 8.347-6.767 15.115-15.115 15.115H62.454z"
      ></path>
    </svg>
  );
}

export default Icon;
