import React from 'react';
import { Alert } from 'reactstrap';

const ApiErrorList = ({ apiResponse }) => {
  // Procesar la respuesta de la API y obtener una lista de mensajes de error
  const errorList = [];
  for (const key in apiResponse) {
    if (apiResponse.hasOwnProperty(key)) {
      const errorMessages = apiResponse[key];
      errorList.push(...errorMessages.map((message, index) => <li key={`${key}-${index}`}>{message}</li>));
    }
  }

  return (
    errorList.length>0 && <Alert color='danger' ><ul className='m-0'>{errorList}</ul></Alert>
  );
};

export default ApiErrorList;
