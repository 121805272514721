import {
  REMOVE,
  GET,
  GET_FAIL,
  GET_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_TYPES,
  GET_USER_TYPES_FAIL,
  GET_USER_TYPES_SUCCESS,
  SAVE,
  SAVE_FAIL,
  SAVE_SUCCESS,
  REMOVE_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  REMOVE_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  TOGGLE,
  TOGGLE_SUCCESS,
  TOGGLE_FAIL,
  CLEAR_PASSWORD_VARIABLES,
  GET_COUNT,
  GET_COUNT_SUCCESS,
} from "./actionTypes"


export const getCount = (payload) => ({
  type: GET_COUNT,
  payload
})


export const getCountSuccess = totalCount => ({
  type: GET_COUNT_SUCCESS,
  payload: totalCount,
})

export const getUsers = (filter) => ({
  type: GET_USERS,
  payload:filter
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const get = () => ({
  type: GET,
})

export const getSuccess = entity => ({
  type: GET_SUCCESS,
  payload: entity,
})

export const getFail = error => ({
  type: GET_FAIL,
  payload: error,
})

export const remove = (id) => ({
  type: REMOVE,
  payload:id
})

export const removeSuccess = entity => ({
  type: REMOVE_SUCCESS,
  payload: entity,
})

export const removeFail = error => ({
  type: REMOVE_FAIL,
  payload: error,
})
export const saveUser = (user) => ({
  type: SAVE,
  payload:user
})

export const saveUserSuccess = result => ({
  type: SAVE_SUCCESS,
  payload: result,
})

export const saveUserFail = error => ({
  type: SAVE_FAIL,
  payload: error,
})

export const update = (entity) => ({
  type: UPDATE,
  payload:entity
})

export const updateSuccess = result => ({
  type: UPDATE_SUCCESS,
  payload: result,
})

export const updateFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})


export const changePassword = (entity) => ({
  type: CHANGE_PASSWORD,
  payload:entity
})

export const changePasswordSuccess = result => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: result,
})

export const changePasswordFail = error => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: error,
})

export const toggle = (entity) => ({
  type: TOGGLE,
  payload:entity
})

export const toggleSuccess = result => ({
  type: TOGGLE_SUCCESS,
  payload: result,
})

export const toggleFail = error => ({
  type: TOGGLE_FAIL,
  payload: error,
})

// USERTYPE
export const getTypes = () => ({
  type: GET_USER_TYPES,
})

export const getTypesSuccess = users => ({
  type: GET_USER_TYPES_SUCCESS,
  payload: users,
})

export const getTypesFail = error => ({
  type: GET_USER_TYPES_FAIL,
  payload: error,
})

export const clearVariables = () => ({
  type: CLEAR_PASSWORD_VARIABLES,
})