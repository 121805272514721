import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET, GET_ALL,
} from "./actionTypes"

import {
  getFail,
  getSuccess,
  getAllFail,
  getAllSuccess,

} from "./actions"

import { get, getAll} from "./Service"

function* fetchAll({ payload }) {
  try {
    const response = yield call(getAll, payload)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}



function* fetchEntity({ payload }) {
  try {
    console.log(payload);
    const response = yield call(get, payload)
    yield put(getSuccess(response))
  } catch (error) {
    yield put(getFail(error))
  }
}


function* filesSaga() {
  yield takeEvery(GET, fetchEntity)
  yield takeEvery(GET_ALL, fetchAll)
}

export default filesSaga
