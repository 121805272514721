import {
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  GET_ALL,
} from "./actionTypes"

const INIT_STATE = {
  list: [],
  entity: undefined,
  types: [],
  error: {},
  isLoading:false,
}

const DocumentStates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL:
      return {
        ...state,
        isloading:true,
      }
    case GET_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isloading:false,
      }

    case GET_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default DocumentStates
