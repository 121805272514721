/* USERS */
let entity = "USER"
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const GET = "GET"
export const GET_SUCCESS = "GET_SUCCESS"
export const GET_FAIL = "GET_FAIL"

export const REMOVE = "REMOVE"
export const REMOVE_SUCCESS = "REMOVE_SUCCESS"
export const REMOVE_FAIL = "REMOVE_FAIL"

export const SAVE = `SAVE_${entity}`
export const SAVE_SUCCESS = `SAVE_${entity}_SUCCESS`
export const SAVE_FAIL = `SAVE_${entity}_FAIL`

export const UPDATE = `UPDATE_${entity}`
export const UPDATE_SUCCESS = `UPDATE_${entity}_SUCCESS`
export const UPDATE_FAIL = `UPDATE_${entity}_FAIL` 

export const TOGGLE = `TOGGLE_${entity}`
export const TOGGLE_SUCCESS = `TOGGLE_${entity}_SUCCESS`
export const TOGGLE_FAIL = `TOGGLE_${entity}_FAIL` 

export const CHANGE_PASSWORD = `CHANGE_PASSWORD_${entity}`
export const CHANGE_PASSWORD_SUCCESS = `CHANGE_PASSWORD_${entity}_SUCCESS`
export const CHANGE_PASSWORD_FAIL = `CHANGE_PASSWORD_${entity}_FAIL` 

/* USERTYPE */
export const GET_USER_TYPES= "GET_USERS_TYPES"
export const GET_USER_TYPES_SUCCESS = "GET_USERS_TYPES_SUCCESS"
export const GET_USER_TYPES_FAIL = "GET_USERS_TYPES_FAIL"


export const CLEAR_PASSWORD_VARIABLES = "CLEAR_PASSWORD_VARIABLES"

export const GET_COUNT = `GET_COUNT_${entity}`
export const GET_COUNT_SUCCESS = `GET_COUNT_${entity}_SUCCESS`