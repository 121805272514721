import { CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS } from './actionTypes';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const initialState = {
    loginError: "", 
    message: null, 
    loading: false, 
    user: { 
        token: ""
     },
     box:{}
}

const login = persistReducer(
    { storage, key: "auth", whitelist: ["user", "authToken"] }
    , (state = initialState, action) => {
        switch (action.type) {
            case CHECK_LOGIN:
                state = {
                    ...state,
                    loading: true, 
                    loginError: undefined 
                }
                break;
            case LOGIN_USER_SUCCESSFUL:
                state = {
                    ...state,
                    user: action.payload,
                    loading: false, 
                    loginError: undefined 
                }
                break;

            case LOGOUT_USER:
                state = { ...state, loginError: undefined, user:{
                    user:{
                        firstAccess:false,
                    }
                }  };
                break;

            case LOGOUT_USER_SUCCESS:
                state = { ...state, loginError: undefined };

                break;

            case API_ERROR:
                state = {
                    ...state,
                    loading: false,
                    loginError: action.payload
                }
                break;

            default:
                state = { ...state };
                break;
        }
        return state;
    })

export default login;