//ACCOUNT
export const POST_LOGIN =  `${process.env.REACT_APP_URL}user/authenticate`

//USERS
export const GET_USERS =  `${process.env.REACT_APP_URL}user/`
export const GET =  `${process.env.REACT_APP_URL}user/`
export const REMOVE =  `${process.env.REACT_APP_URL}user/`
export const POST_USER =  `${process.env.REACT_APP_URL}user/register`
export const PUT =  `${process.env.REACT_APP_URL}user/`
export const PUT_CHANGE_PASSWORD =  `${process.env.REACT_APP_URL}user/updatePassword`
export const PUT_TOGGLE =  `${process.env.REACT_APP_URL}account/toggle`
export const GET_TYPES =  `${process.env.REACT_APP_URL}user/roles`
export const GET_COUNT =  `${process.env.REACT_APP_URL}user/count`

//carriers
export const GET_CARRIERS =  `${process.env.REACT_APP_URL}carrier`
export const GET_ALL_NOTIFICATIONS =  `${process.env.REACT_APP_URL}notification/`
export const MARK_AS_READ =  `${process.env.REACT_APP_URL}notification/`