import {
  GET_ALL,
  GET_ALL_SUCCESS,
  GET_ALL_FAIL,
  MARK_AS_READ,

} from "./actionTypes"

const INIT_STATE = {
  entities: [],
  error: {},
  fetching:false
}

const Carriers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case MARK_AS_READ:
      return {
        ...state,
        fetching:true,
      }
    case GET_ALL:
      return {
        ...state,
        fetching:true,
      }

    case GET_ALL_SUCCESS:
      return {
        ...state,
        fetching:false,
        entities: action.payload,
      }

    case GET_ALL_FAIL:
      return {
        ...state,
        fetching:false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Carriers
