import { call, put, takeEvery } from "redux-saga/effects"
import FileSaver from 'file-saver';
// Ecommerce Redux States
import {
  ACCEPT,
  ACTIVATE,
  ASSOCIATE,
  DISSASOCIATE,
  DOWNLOAD,
  GET, GET_ALL, GET_ALL_STATES, GET_BY_DOCUMENT_NUMBER, GET_COUNT, GET_PREVALUATION, REJECT, SAVE, TYPES, UPDATE, UPDATE_TYPES
} from "./actionTypes"
import {
  getFail,
  getSuccess,
  getAllFail,
  getAllSuccess,
  saveFail,
  saveSuccess,
  updateFail,
  getAllTypes,
  getAllTypesFail,
  getAllTypesSuccess,
  updateTypeSuccess,
  updateTypeFail,
  getByDocumentSuccess,
  getByDocumentFail,
  getCountSuccess,
  rejectSuccess,
  rejectFail,
  acceptSuccess,
  acceptFail,
  activateSuccess,
  activateFail,
  getPrevaluationSuccess,
  getPrevaluationFail,
  getAllStatesSuccess,
  getAllStatesFail,

} from "./actions"

import { get, getAll, post, update, getTypes, putTypes, getByDocumentNumber, exportDocument, getCount, rejectService, acceptService, activateService, getPrevaluationService, getAllStatus } from "./Service"
import toast from "react-hot-toast"



function* fetchAll({ payload }) {
  try {
    const response = yield call(getAll, payload)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}

function* fetchAllStates({ payload }) {
  try {
    const response = yield call(getAllStatus, payload)
    yield put(getAllStatesSuccess(response))
  } catch (error) {
    yield put(getAllStatesFail(error))
  }
}
function* fetchCount({ payload }) {
  try {
    const response = yield call(getCount, payload)
    yield put(getCountSuccess(response))
  } catch (error) {
  }
}

function* getByDocument({ payload }) {
  try {
    const response = yield call(getByDocumentNumber, payload)
    yield put(getByDocumentSuccess(response))
  } catch (error) {
    yield put(getByDocumentFail(error))
  }
}

function* updateTypes({ payload }) {
  try {
    const response = yield call(putTypes, payload)
    yield put(updateTypeSuccess(response))
  } catch (error) {
    yield put(updateTypeFail(error))
  }
}


function* fetchEntity({ payload }) {
  try {
    const response = yield call(get, payload)
    yield put(getSuccess(response))
  } catch (error) {
    yield put(getFail(error))
  }
}

function* fetchPrevaluation({ payload }) {
  try {
    const response = yield call(getPrevaluationService, payload)
    yield put(getPrevaluationSuccess(response))
  } catch (error) {
    yield put(getPrevaluationFail(error))
  }
}

function* save(data) {
  try {
    const response = yield call(post, data.payload)
    yield put(saveSuccess(response))
  } catch (error) {
    yield put(saveFail(error))
  }
}

function* reject(data) {
  try {
    const response = yield call(rejectService, data.payload)
    yield put(rejectSuccess(response))
  } catch (error) {
    yield put(rejectFail(error))
  }
}

function* accept(data) {
  try {
    const response = yield call(acceptService, data.payload)
    yield put(acceptSuccess(response))
  } catch (error) {
    yield put(acceptFail(error))
  }
}

function* activate(data) {
  try {
    const response = yield call(activateService, data.payload)
    yield put(activateSuccess(response))
  } catch (error) {
    yield put(activateFail(error))
  }
}

function* updateEntity(data) {
  try {
    const response = yield call(update, data.payload)
    yield put(update(response))
  } catch (error) {
    yield put(updateFail(error))
  }
}
function* download(data) {
  try {
    const response = yield call(exportDocument, data.payload)
    yield call(FileSaver.saveAs, response,`PC_${new Date().getMilliseconds()}.xlsx`)
  } catch (error) {
  }
}




function* loadPlansSaga() {
  yield takeEvery(GET_ALL_STATES, fetchAllStates)
  yield takeEvery(GET, fetchEntity)
  yield takeEvery(GET_PREVALUATION, fetchPrevaluation)
  yield takeEvery(GET_BY_DOCUMENT_NUMBER, getByDocument)
  yield takeEvery(GET_ALL, fetchAll)
  yield takeEvery(GET_COUNT, fetchCount)
  yield takeEvery(SAVE, save)
  yield takeEvery(UPDATE, updateEntity)
  yield takeEvery(UPDATE_TYPES, updateTypes)
  yield takeEvery(DOWNLOAD, download)
  yield takeEvery(REJECT, reject)
  yield takeEvery(ACCEPT, accept)
  yield takeEvery(ACTIVATE, activate)
}

export default loadPlansSaga
