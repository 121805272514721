import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET, GET_ALL, GET_BY_USER, REMOVE, SAVE,UPDATE
} from "./actionTypes"
import {
  getFail,
  getSuccess,
  getAllFail,
  getAllSuccess,
  removeFail,
  removeSuccess,
  saveFail,
  saveSuccess,
  updateSuccess,
  updateFail,
  getByUserSuccess,
  getByUserFail
} from "./actions"
import { get, getAll, post, update, remove, GetByUserService } from "./Service"


function* fetchAll() {
  try {
    const response = yield call(getAll)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}

function* fetchEntity({payload}) {
  try {
    const response = yield call(get,payload)
    yield put(getSuccess(response))
  } catch (error) {
    yield put(getFail(error))
  }
}

function* fetchByUser({payload}) {
  try {
    const response = yield call(GetByUserService,payload)
 
    yield put(getByUserSuccess(response))
  } catch (error) {
    const { status } = error.response;
    if(status ===404)
      yield put(getByUserFail(error.response.data))
    else
      yield put(getByUserFail(error.response.data))
  }
}

function* save(data) {
  try {
    const response = yield call(post,data.payload)
    yield put(saveSuccess(response))
  } catch (error) {
    yield put(saveFail(error))

  }
}

function* updateEntity(data) {
  try {
    const response = yield call(update,data.payload)
    yield put(updateSuccess(response))
  } catch (error) {
    yield put(updateFail(error))
  }
}


function* removeEntity({payload}) {
  try {
    const response = yield call(remove,payload)
    yield call(fetchAll)
    yield put(removeSuccess(response))
  } catch (error) {
    yield put(removeFail(error))
  }
}


function* boxSaga() {
  yield takeEvery(REMOVE, removeEntity)
  yield takeEvery(GET, fetchEntity)
  yield takeEvery(GET_BY_USER, fetchByUser)
  yield takeEvery(GET_ALL, fetchAll)
  yield takeEvery(SAVE, save)
  yield takeLatest(UPDATE, updateEntity)
}

export default boxSaga
