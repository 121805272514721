let entity = "document"
export const GET_ALL =  `${process.env.REACT_APP_URL}${entity}/all`
export const GET_ALL_XML =  `${process.env.REACT_APP_URL}${entity}/transmissions`
export const GET_TYPES =  `${process.env.REACT_APP_URL}${entity}/types`
export const GET =  `${process.env.REACT_APP_URL}${entity}?id=`
export const REMOVE =  `${process.env.REACT_APP_URL}${entity}?id=`
export const POST =  `${process.env.REACT_APP_URL}${entity}/`
export const PUT =  `${process.env.REACT_APP_URL}${entity}/`
export const PUT_DISSASOCIATE =  `${process.env.REACT_APP_URL}${entity}/disassociate`
export const PUT_ASSOCIATE =  `${process.env.REACT_APP_URL}${entity}/associate`
export const PUT_OPEN =  `${process.env.REACT_APP_URL}${entity}/open`
export const PUT_REJECT =  `${process.env.REACT_APP_URL}${entity}/reject`
export const PUT_TOGGLE =  `${process.env.REACT_APP_URL}${entity}/type/toggle`
export const DOWNLOAD =  `${process.env.REACT_APP_URL}${entity}/download`