import {
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  GET_ALL,
  RETRANSFER,
  RETRANSFER_FAIL,
  RETRANSFER_SUCCESS

} from "./actionTypes"

const INIT_STATE = {
  list: [],
  error: {},
  fetching: false,
}

const Transfer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL:
      return {
        ...state,
        list: [],
        fetching: true,
      }

    case GET_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
      }

    case GET_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }

    case RETRANSFER:
      return {
        ...state,
        fetching: true,
      }

    case RETRANSFER_SUCCESS:
      return {
        ...state,
        rejecting: false,
        fetching:false,
      }

    case RETRANSFER_FAIL:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      }
    default:
      return state
  }
}

export default Transfer
