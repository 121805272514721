import {
  GET_ALL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  RETRANSFER,
  RETRANSFER_SUCCESS,
  RETRANSFER_FAIL,
} from "./actionTypes"

export const getAll = (payload) => ({
  type: GET_ALL,
  payload
})

export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})

export const retransfer = (payload) => ({
  type: RETRANSFER,
  payload
})

export const retransferSuccess = (payload) => ({
  type: RETRANSFER_SUCCESS,
  payload
})

export const retransferFail = (payload) => ({
  type: RETRANSFER_FAIL,
  payload
})


