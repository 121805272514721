import * as api from "../../helpers/api_helper";
import qs from "qs";
import { GET, GET_ALL, GET_COUNT, GET_TYPES, POST, PUT, PUT_TOGGLE, DOWNLOAD, REJECT, ACCEPT, ACTIVATE,GET_PREVALUATION_URL, GET_ALL_STATES } from "./url";

export const getAll = (filter) => {

  return api.get( GET_ALL, {
    params: { ...filter },
    paramsSerializer: params => qs.stringify(params)
  });
};

export const getAllStatus = (filter) => {

  return api.get( GET_ALL_STATES, {
  });
};


export const getCount = (filter) => api.get(GET_COUNT, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});

export const getByDocumentNumber = (filter) => api.get(GET_ALL, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});
export const getTypes = (filter) => api.get(GET_TYPES, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});

export const exportDocument = (filter) => api.download(DOWNLOAD, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});
export const get = (id) => api.get(`${GET}${id}`);
export const getPrevaluationService = (id) => api.get(`${GET_PREVALUATION_URL}${id}`);
export const post = (entity) => api.post(POST, entity);
export const rejectService = (data) => api.post(REJECT, { ...data });
export const acceptService = (id) => api.post(`${ACCEPT}${id}`);
export const activateService = (data) => api.post(ACTIVATE, { ...data });
export const update = (entity) => api.put(PUT, entity);
export const putTypes = (entity) => api.put(PUT_TOGGLE, entity);