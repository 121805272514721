import * as api from "../../helpers/api_helper";
import qs from "qs";
import { GET, GET_ALL, GET_PREVALORIZATION, PUT_REJECT, SAVE_PREVALORIZATION_URL, UPDATE_PREVALORIZATION_URL, UPLOAD, UPLOAD_AUTOMATIC_URL} from "./url";


export const getAll = (filter) => api.get(GET_ALL, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});

export const getPrevalorization = (filter) => api.get(GET_PREVALORIZATION, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});

export const get = (id) => api.get(`${GET}${id}`);
export const rejectService = (data) => api.put(PUT_REJECT, data );
export const uploadService = (data) =>  api.post(UPLOAD, data);
export const uploadAutomaticService = (data) =>  api.post(UPLOAD_AUTOMATIC_URL, data,{
  headers: { "Content-Type": "multipart/form-data" }
});
export const savePrevalorizationService = (data) => api.post(SAVE_PREVALORIZATION_URL, data);
export const updatePrevalorizationService = (data) => api.put(UPDATE_PREVALORIZATION_URL, data);