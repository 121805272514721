import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as actions from "../../store/boxes/actions";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Columns from './Columns';
import { useHistory } from "react-router-dom"

const List = ({list,getAll,remove}) => {
    const history = useHistory();
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Configuración", link: "#" },
        { title: "Cajas", link: "#" },
    ])

    const pageOptions = {
        sizePerPage: 10,
        totalSize: list.length, // replace later with size(users),
        custom: true,
    }

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const { SearchBar } = Search

    const selectRow = {
        mode: 'checkbox'
    };



    const handleNew =()=>history.push("/box/add");
      useEffect(() => {
          getAll()
        
        return () => {
        }
      }, [history])
      

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Cajas" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        data={list?.data??[]}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={Columns(remove)}
                                                data={list?.data??[]}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col sm="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar
                                                                        placeholder="Buscar"
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="8">
                                                                <div className="text-sm-end">
                                                                    <Button
                                                                        type="button"
                                                                        color="success"
                                                                        className="btn-rounded mb-2 me-2"
                                                                        onClick={handleNew}
                                                                    >
                                                                        <i className="mdi mdi-plus me-1" />{" "}
                                                                        Nueva caja
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={true}
                                                                defaultSorted={defaultSorted}
                                                                selectRow={selectRow}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    list:state.Boxes.list,
});



export default connect(mapStateToProps,actions)(List);