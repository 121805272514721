import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, ButtonDropdown, ButtonGroup, Card, CardBody, CardFooter, CardImg, CardText, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledAlert } from 'reactstrap';
import Loader from '../../../common/Loader';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import * as loadPlansActionTypes from "../../../store/LoadPlans/actionTypes";
import PdfIcon from '../../Icons/PdfIcon';
import './styles.css'
import { useState } from 'react';
import { useFormik } from 'formik';
const Control = ({
    Get,
    fetching,
    entity,
    reject,
    rejecting,
    rejected,
    accept
}) => {
    const [PcIdSelected, setPcIdSelected] = useState(undefined)
    const [ShowRejectionModal, setShowRejectionModal] = useState(false)
    const [ShowAddObservation, setShowAddObservation] = useState(false)

    const { id } = useParams();
    useEffect(() => {
        Get(id);
    }, [])

    const handleShowModalReject = (id) => {
        setPcIdSelected(id);
        setShowRejectionModal(true)

    }
    const handleReject = (id) => {
        reject({ Id: PcIdSelected, ...rejectFormik.values });
    }

    const rejectFormik = useFormik({
        initialValues: { RejectionType: "4e4faf45-88ae-43bc-a168-bd7fb3e40e14", reason: undefined },
        onSubmit: (values) => {
            console.log(values)
        }
    });

    const handleAccept = (id) => accept( id);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{`Plan de carga: # ${entity?.name}`}</h4>

                                <div className="page-title-right">
                                    <Link className='btn btn-light' to={"/outbound"}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}
                                    <Button disabled={rejected || entity?.rejectionTypeId !== null} onClick={() => handleAccept(entity?.id)} color='success'><i className="mdi mdi-check me-2"></i> Aceptar</Button>{' '}
                                    <Button disabled={rejected || entity?.rejectionTypeId !== null} onClick={() => handleShowModalReject(entity?.id)} color='danger'><i className="mdi mdi-cancel me-2"></i> Rechazar</Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Loader isLoading={fetching}>
                                {
                                    entity && entity?.remittances?.map((item, index) => (
                                        <>
                                            <Card key={`remittance_${index}`}>
                                                <Row key={`remittance_row_${index}`} className="no-gutters align-items-center">
                                                    {!item?.fileName && <UncontrolledAlert color="danger" role="alert">
                                                        <i className="mdi mdi-alert-circle-outline me-2"></i>
                                                        El transportista aún no ha digitalizado el documento.
                                                    </UncontrolledAlert>}
                                                    <Col md={8}>
                                                        <div style={{ height: '400px', width: 'auto', backgroundColor: 'gray' }}>
                                                            {
                                                                item?.fileName !== '' ?
                                                                    <iframe width={"100%"} height={"100%"} itemType="pdf" src={`${process.env.REACT_APP_URL}files/remittance/v2?data=${item?.fileName}`}></iframe>
                                                                    : <div className='no-pdf-digitalized align-items-center '></div>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="table-responsive">
                                                            <Table className="mb-0">


                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Número de remito:</th>
                                                                        <td align='center'>{item?.number}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Nro. Remito:</th>
                                                                        <td align='center'>{item?.remittanceNumberVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Duplicado:</th>
                                                                        <td align='center'>{item?.duplicatesVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Conformidad:</th>
                                                                        <td align='center'>{item?.conformityVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Observaciones:</th>
                                                                        <td align='center'>{item?.observationsVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            <div><CardText><small className="text-muted">Última actualización disponible: {item?.digitalized ?? '-'}</small></CardText></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </>
                                    ))
                                }
                            </Loader>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                size="lg"
                centered={true}
                isOpen={ShowRejectionModal}
                toggle={() => setShowRejectionModal(!ShowRejectionModal)}

            >
                <form onSubmit={rejectFormik.handleSubmit}>
                    <ModalHeader toggle={() => setShowRejectionModal(!ShowRejectionModal)}>
                        Rechazar plan de carga
                    </ModalHeader>
                    <ModalBody>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="12" lg="6" xl="4" xs="3">
                                        <select name='RejectionType' onChange={rejectFormik.handleChange} className="form-control mb-3">
                                            <option selected value={"4e4faf45-88ae-43bc-a168-bd7fb3e40e14"}> Error número de remito</option>
                                            <option value={"6886ff89-f7b0-4afc-9e01-a4bfe761399e"}> Error conformidad</option>
                                            <option value={"3d94bad7-1471-4094-86b5-2df2e8374a6a"}> Error observaciones</option>
                                            <option value={"eed3ca94-0d36-4e58-9887-32bf03048b86"}> Otros</option>
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <textarea name='reason' onChange={rejectFormik.handleChange} style={{ "height": "100%" }} className="form-control" rows={5}></textarea>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-lg-end block-inline">
                                        <Button disabled={false} className="btn btn-md" color="link" onClick={() => setShowRejectionModal(false)}>Cerrar</Button> {' '}
                                        <Button disabled={false} className="btn btn-md" color="danger" onClick={() => {
                                            setShowRejectionModal(false);
                                            handleReject();
                                        }} >Aceptar</Button>{' '}
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </ModalBody>
                </form>
            </Modal>

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    entity: state.LoadPlan.entity,
    fetching: state.LoadPlan?.fetching,
    rejecting: state.LoadPlan?.rejecting,
    rejected: state.LoadPlan?.rejected,
});


const mapDispatchToProps = (dispatch) => {
    return {
        Get: (id) => dispatch({ type: loadPlansActionTypes.GET, payload: id }),
        reject: (data) => dispatch({ type: loadPlansActionTypes.REJECT, payload: data }),
        accept: (id) => dispatch({ type: loadPlansActionTypes.ACCEPT, payload: id }),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Control);