let entity = "LOAD_PLANS"
export const GET_ALL = `GET_ALL_${entity}`
export const GET_ALL_SUCCESS = `GET_ALL_${entity}_SUCCESS`
export const GET_ALL_FAIL = `GET_ALL_${entity}_FAIL`

export const GET_ALL_STATES = `GET_ALL_STATES_${entity}`
export const GET_ALL_STATES_SUCCESS = `GET_ALL_STATES_${entity}_SUCCESS`
export const GET_ALL_STATES_FAIL = `GET_ALL_STATES_${entity}_FAIL`


export const GET_COUNT = `GET_COUNT_${entity}`
export const GET_COUNT_SUCCESS = `GET_COUNT_${entity}_SUCCESS`

export const GET = `GET_${entity}`
export const GET_SUCCESS = `GET_${entity}_SUCCESS`
export const GET_FAIL = `GET_${entity}_FAIL`

export const GET_PREVALUATION = `GET_PREVALUATION_${entity}`
export const GET_PREVALUATION_SUCCESS = `GET_PREVALUATION_${entity}_SUCCESS`
export const GET_PREVALUATION_FAIL = `GET_PREVALUATION_${entity}_FAIL`

export const GET_BY_DOCUMENT_NUMBER = `GET_BY_DOCUMENT_NUMBER_${entity}`
export const GET_BY_DOCUMENT_NUMBER_SUCCESS = `GET_BY_DOCUMENT_NUMBER_${entity}_SUCCESS`
export const GET_BY_DOCUMENT_NUMBER_FAIL = `GET_BY_DOCUMENT_NUMBER_${entity}_FAIL`

export const SAVE = `SAVE_${entity}`
export const SAVE_SUCCESS = `SAVE_${entity}_SUCCESS`
export const SAVE_FAIL = `SAVE_${entity}_FAIL`

export const UPDATE = `UPDATE_${entity}`
export const UPDATE_SUCCESS = `UPDATE_${entity}_SUCCESS`
export const UPDATE_FAIL = `UPDATE_${entity}_FAIL` 

export const TYPES = `TYPES_${entity}`
export const TYPES_SUCCESS = `TYPES_${entity}_SUCCESS`
export const TYPES_FAIL = `TYPES_${entity}_FAIL` 

export const UPDATE_TYPES = `UPDATE_TYPES_${entity}`
export const UPDATE_TYPES_SUCCESS = `UPDATE_TYPES_${entity}_SUCCESS`
export const UPDATE_TYPES_FAIL = `UPDATE_TYPES_${entity}_FAIL` 

export const REJECT = `REJECT_${entity}`
export const REJECT_SUCCESS = `REJECT_${entity}_SUCCESS`
export const REJECT_FAIL = `REJECT_${entity}_FAIL`

export const ACCEPT = `ACCEPT_${entity}`
export const ACCEPT_SUCCESS = `ACCEPT_${entity}_SUCCESS`
export const ACCEPT_FAIL = `ACCEPT_${entity}_FAIL`

export const ACTIVATE = `ACTIVATE${entity}`
export const ACTIVATE_SUCCESS = `ACTIVATE_${entity}_SUCCESS`
export const ACTIVATE_FAIL = `ACTIVATE_${entity}_FAIL`

export const DOWNLOAD = `DOWNLOAD_${entity}`;

export const SET_FILTER = `SET_FILTER_${entity}`;
export const CLEAR_FILTER = `CLEAR_FILTER_${entity}`;