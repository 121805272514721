import React, { Component, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

//i18n
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

// falgs
import usFlag from "../../../assets/images/flags/us.jpg";
import { Link } from "react-router-dom";


const ProcessDropdown = () => {

  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(!menu);
  }

  const selectProcess = () => {

  }
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} title="Procesos" className="d-inline-block">
        <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown">
          <i className="ri-truck-line"></i>
        </DropdownToggle>


        <DropdownMenu className="dropdown-menu-end">

          <DropdownItem active={false} href="" onClick={() => selectProcess()} className="notify-item">
            <div className="p-2 border-top">
              <Link to="/inbound" className="btn btn-sm btn-link font-size-18 btn-block text-center">
                <i className="mdi mdi-arrow-right-circle me-1"></i>Inbound
              </Link>
            </div>

          </DropdownItem>
          <DropdownItem active={false} href="" onClick={() => selectProcess()} className="notify-item">
            <div className="p-2 border-top">
              <Link to="/outbound" className="btn btn-sm btn-link font-size-18 btn-block text-center">
                <i className="mdi mdi-arrow-left-circle me-1"></i>Outbound
              </Link>
            </div>
          </DropdownItem>


        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withNamespaces()(ProcessDropdown);
