import { call, put, takeEvery } from "redux-saga/effects"
import {
   GET_ALL, 
} from "./actionTypes"
import {
  getAllFail,
  getAllSuccess,
} from "./actions"
import { getAll} from "./Service"



function* fetchAll({ payload }) {
  try {
    const response = yield call(getAll,payload)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}


function* DocumentStates() {
  yield takeEvery(GET_ALL, fetchAll)
}

export default DocumentStates
