import React from 'react';

const PadUnlockIcon = ({ width = '16px' }) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
     <path
        fill="#FFB655"
        d="M488.727 201.697H178.425c-12.853 0-23.273 10.42-23.273 23.273v263.758c0 12.853 10.42 23.273 23.273 23.273h310.304C501.58 512 512 501.58 512 488.727V224.97c0-12.853-10.42-23.273-23.273-23.273z"
      ></path>
      <path
        fill="#A9A8AE"
        d="M23.273 248.242c12.853 0 23.273-10.42 23.273-23.273v-93.092c0-47.051 38.281-85.332 85.333-85.332s85.333 38.281 85.333 85.332v69.82h46.545v-69.82C263.758 59.161 204.597 0 131.879 0S0 59.161 0 131.877v93.092c0 12.853 10.42 23.273 23.273 23.273z"
      ></path>
      <path
        fill="#EE8700"
        d="M178.424 201.697c-12.853 0-23.273 10.42-23.273 23.273v263.758c0 12.853 10.42 23.273 23.273 23.273h155.152V201.697H178.424z"
      ></path>
    </svg>
  );

}

export default PadUnlockIcon;