import {
GET_ALL,
GET_ALL_SUCCESS,
GET_ALL_FAIL,
MARK_AS_READ
} from "./actionTypes"

export const getAll = () => ({
  type: GET_ALL,
})

export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})

export const markAsRead = (id) => ({
  type: MARK_AS_READ,
  payload:id
})
