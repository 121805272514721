import React from 'react';
import { Alert } from 'reactstrap';

const Validations = ({errors}) => {
    return (
   <>
   {Object.keys(errors).length>0 &&
   <><h2>Errores detectados</h2>
   <h5>Incumplimiento de las políticas de seguridad.</h5>
   <Alert color='danger'>
   <ul>
     {Object.keys(errors).map((field, index) => (
       
         <ul>
           {errors[field].map((error, subIndex) => (
             <li key={subIndex}>{error}</li>
           ))}
         </ul>
     ))}
   </ul>
 </Alert>
 </>}
      </>
    );
};

export default Validations;
