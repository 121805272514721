import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
  GET_CARRIERS,
GET_USERS, GET_USER_TYPES, SAVE_USERS
} from "./actionTypes"
import {
  getCarriersFail,
  getCarriersSuccess,
} from "./actions"
import { getCarriers,  } from "./Service"



function* fetchCarriers() {
  try {
    const response = yield call(getCarriers)
    yield put(getCarriersSuccess(response))
  } catch (error) {
    yield put(getCarriersFail(error))
  }
}


function* carriersSaga() {
  yield takeEvery(GET_CARRIERS, fetchCarriers)
}

export default carriersSaga
