import {
  GET_FAIL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  SAVE_FAIL,
  SAVE_SUCCESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  TYPES_FAIL,
  UPDATE_TYPES_SUCCESS,
  UPDATE_TYPES_FAIL,
  GET_ALL,
  GET_BY_DOCUMENT_NUMBER,
  GET_BY_DOCUMENT_NUMBER_FAIL,
  GET_BY_DOCUMENT_NUMBER_SUCCESS,
  GET,
  GET_SUCCESS,
  GET_COUNT,
  GET_COUNT_SUCCESS,
  REJECT_SUCCESS,
  REJECT,
  REJECT_FAIL,
  ACCEPT,
  ACCEPT_SUCCESS,
  ACCEPT_FAIL,
  SET_FILTER,
  CLEAR_FILTER,
  GET_PREVALUATION,
  GET_PREVALUATION_SUCCESS,
  GET_PREVALUATION_FAIL,
  GET_ALL_STATES,
  GET_ALL_STATES_SUCCESS,
  GET_ALL_STATES_FAIL

} from "./actionTypes"

const INIT_STATE = {
  list: [],
  states:[],
  entity: undefined,
  types: [],
  error: {},
  fetching: false,
  mustBeUpdated: false,
  totalCount: 0,
  rejected:false,
  filter: {
    Name:'',
  },
  prevaluation:undefined,
}

const LoadPlan = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      }

      case CLEAR_FILTER:
        return {
          ...state,
          filter: {
            Name:''
          },
        }
    case GET_ALL:
      return {
        ...state,
        list: [],
        fetching: true,
        mustBeUpdated: false,
        entity: undefined,
        rejected:false,
        totalCount:0
      }

      case GET_ALL_STATES:
        return {
          ...state,
          states: [],
          fetching: true,
        }
        case GET_ALL_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        fetching: false,
      }

    case GET_ALL_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      }
    case GET_COUNT_SUCCESS:
      return {
        ...state,
        totalCount: action.payload,
      }

    case REJECT:
      return {
        ...state,
        list: [],
        fetching: true,
        
      }
    case REJECT_SUCCESS:
      return {
        ...state,
        fetching: false,
        rejected: action.payload
      }
    case REJECT_FAIL:
      return {
        ...state,
        fetching: false,
        rejected:false,
      }


      case ACCEPT:
        return {
          ...state,
          list: [],
          fetching: true,
          
        }
      case ACCEPT_SUCCESS:
        return {
          ...state,
          fetching: false,
          accepted: action.payload
        }
      case ACCEPT_FAIL:
        return {
          ...state,
          fetching: false,
          accepted:false,
        }


    case GET_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        mustBeUpdated: false,
        rejected:false,
      }

    case GET_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
        fetching: false,
        totalCount:0
      }

    case TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }
    case UPDATE_TYPES_SUCCESS:
      return {
        ...state,
        mustBeUpdated: false,
      }

    case UPDATE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

    case GET_BY_DOCUMENT_NUMBER:
      return {
        ...state,
        fetching: true,
      }

    case GET_BY_DOCUMENT_NUMBER_FAIL:
      return {
        ...state,
        fetching: false,
      }

    case GET_BY_DOCUMENT_NUMBER_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        fetching: false,
      }

    case GET:
      return {
        ...state,
        fetching: true,
      }

    case GET_SUCCESS:
      return {
        ...state,
        fetching: false,
        entity: action.payload,

      }

    case GET_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

      case GET_PREVALUATION:
      return {
        ...state,
        fetching: true,
        prevaluation: undefined,
      }

    case GET_PREVALUATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        prevaluation: action.payload,

      }

    case GET_PREVALUATION_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
        prevaluation: undefined,
      }

    case SAVE_SUCCESS:
      return {
        ...state,
        error: {},
        result: action.payload,
        mustBeUpdated: true,
      }

    case SAVE_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

    case UPDATE_SUCCESS:
      return {
        ...state,
        error: {},
        result: action.payload,
        mustBeUpdated: true,
      }

    case UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }
    default:
      return state
  }
}

export default LoadPlan
