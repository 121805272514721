import { call, put, takeEvery } from "redux-saga/effects"
import {
   GET_ALL,
   RETRANSFER
} from "./actionTypes"

import {
getAllSuccess,
getAllFail,
retransferFail,
retransfer,
retransferSuccess
} from "./actions"

import { getAll, retransferService } from "./Service"
import { toast } from "react-hot-toast"

function* fetchAll({ payload }) {
  try {
    const response = yield call(getAll, payload)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}



function* Retransfer(data) {
  try {
    const response = yield call(retransferService, data.payload)
    yield put(retransferSuccess(response))
    if (response)
      toast.success("Re-transferencia ejecutada correctamente.");
    else
      toast.error("Ha ocurrido un error al intentar ejecutar las re-transferencias.");
  } catch (error) {
    yield put(retransferFail(error))
  }
}
function* retranferSaga() {
  yield takeEvery(GET_ALL, fetchAll)
  yield takeEvery(RETRANSFER, Retransfer)
}

export default retranferSaga
