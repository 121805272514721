import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import {
  GET, GET_ALL, REJECT, SAVE_PREVALORIZATION, UPDATE_PREVALORIZATION, UPLOAD, UPLOAD_AUTOMATIC,
} from "./actionTypes"

import {
  getFail,
  getSuccess,
  getAllFail,
  getAllSuccess,
  rejectSuccess,
  rejectFail,
  uploadSuccess,
  uploadFail,
  savePrevalorizationSuccess,
  savePrevalorizationFail,
  updatePrevalorizationSuccess,
  updatePrevalorizationFail,
  uploadAutoSuccess,
  uploadAutoFail,

} from "./actions"

import { get, getAll, rejectService, savePrevalorizationService, updatePrevalorizationService, uploadAutomaticService, uploadService} from "./Service"
import { toast } from "react-hot-toast"
import { UPLOAD_AUTOMATIC_URL } from "./url"

function* fetchAll({ payload }) {
  try {
    const response = yield call(getAll, payload)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}



function* fetchEntity({ payload }) {
  try {
    const response = yield call(get, payload)
    yield put(getSuccess(response))
  } catch (error) {
    yield put(getFail(error))
  }
}


function* rejectDocument({ payload }) {
  try {
    const response = yield call(rejectService, payload)
    if (response)
      toast.success("Entrega con devolución.");
    else
      toast.error("No se puede rechazar el documento, verifique el estado");
    yield put(rejectSuccess(response))
  } catch (error) {
    toast.error("Error al rechazar el documento. Consulte a los administradores.");
    yield put(rejectFail(error))
  }
}


function* upload(data) {
  try {
    const response = yield call(uploadService, data.payload)
    if (response)
      toast.success("Remito subido correctamente");
    else
      toast.error("No se puede subir el remito.");
    yield put(uploadSuccess(response))
  } catch (error) {
    yield put(uploadFail(error))
  }
}

function* uploadAutomatic(data) {
  try {
    const formData = new FormData();
    for(let k of Object.keys(data.payload)) {
      formData.append("files",data.payload[k]);
    }
    yield axios
    .post(UPLOAD_AUTOMATIC_URL, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(({status}) =>{
      if(status === 200)
        toast.success("Remitos enviados correctamente");
      else
        toast.success("Error enviando remitos.");
    } );


    yield put(uploadAutoSuccess())
  } catch (error) {
    yield put(uploadAutoFail(error))
  }
}

function* savePrevalorization(data) {
  try {
    const response = yield call(savePrevalorizationService, data.payload)
    yield put(savePrevalorizationSuccess(response))
    if (response){
      toast.success("Prevalorización guardada correctamente.");
      data.callBack.goBack();
    }
    else
      toast.error("Ha ocurrido un error al intentar guardar la prevalorización");
  } catch (error) {
    yield put(savePrevalorizationFail(error))
  }
}

function* updatePrevalorization(data) {
  try {
    const response = yield call(updatePrevalorizationService, data.payload)
    yield put(updatePrevalorizationSuccess(response))
    if (response){
      toast.success("Prevalorización actualizada correctamente.");
      data.callBack.goBack();
    }
    else
      toast.error("Ha ocurrido un error al intentar actualizar la prevalorización");
  } catch (error) {
    yield put(updatePrevalorizationFail(error))
  }
}

function* FetchPrevalorization(data) {
  try {
    const response = yield call(savePrevalorizationService, data.payload)
    yield put(savePrevalorizationSuccess(response))
    if (response)
      toast.success("Prevalorización guardada correctamente.");
    else
      toast.error("Ha ocurrido un error al intentar guardar la prevalorización");
  } catch (error) {
    yield put(savePrevalorizationFail(error))
  }
}
function* remittanceSaga() {
  yield takeEvery(GET, fetchEntity)
  yield takeEvery(GET_ALL, fetchAll)
  yield takeEvery(REJECT, rejectDocument)
  yield takeEvery(UPLOAD, upload)
  yield takeEvery(UPLOAD_AUTOMATIC, uploadAutomatic)
  yield takeEvery(SAVE_PREVALORIZATION, savePrevalorization)
  yield takeEvery(UPDATE_PREVALORIZATION, updatePrevalorization)
}

export default remittanceSaga
