import {
  GET_FAIL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  GET_ALL,
  REJECT,
  REJECT_SUCCESS,
  REJECT_FAIL,
  UPLOAD_SUCCESS,
  GET_PREVALORIZATION,
  GET_PREVALORIZATION_SUCCESS,
  GET_PREVALORIZATION_FAIL,
  UPDATE_PREVALORIZATION,
  UPDATE_PREVALORIZATION_SUCCESS,
  UPDATE_PREVALORIZATION_FAIL,

} from "./actionTypes"

const INIT_STATE = {
  list: [],
  entity: undefined,
  types: [],
  error: {},
  fetching: false,
  mustBeUpdated: false,
  rejecting: false,
  rejected: false,
  boxUsage: 0,
  uploaded: false,
  updated: false
}

const Remittance = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL:
      return {
        ...state,
        list: [],
        fetching: true,
        mustBeUpdated: false,
        rejecting: false,
        rejected: false


      }

    case GET_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        mustBeUpdated: false,
        rejecting: false,
        rejected: false
      }

    case GET_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
        fetching: false,
        rejecting: false,
        rejected: false
      }

    case GET_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
        rejecting: false,
        rejected: false
      }
    case REJECT:
      return {
        ...state,
        fetching: true,
        rejecting: true,
        error: {},
        rejected: false
      }
    case REJECT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: {},
        result: action.payload,
        rejecting: false,
        rejected: true,
        mustBeUpdated: true,
      }

    case REJECT_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        mustBeUpdated: false,
        rejected: false,
        rejecting: false,
      }
    case UPLOAD_SUCCESS:
      return {
        ...state,
        boxUsage: action.payload
      }
    case UPDATE_PREVALORIZATION:
      return {
        ...state,
        fetching: true,
        updated: false
      }
    case UPDATE_PREVALORIZATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        result: action.payload,
        updated: true,
      }

    case UPDATE_PREVALORIZATION_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        updated:false,
      }
    case UPLOAD_SUCCESS:
      return {
        ...state,
        boxUsage: action.payload
      }


    case GET_PREVALORIZATION:
      return {
        ...state,
        list: [],
        fetching: true,
        mustBeUpdated: false,
        rejecting: false,
        rejected: false,
        entity: null,


      }

    case GET_PREVALORIZATION_SUCCESS:
      return {
        ...state,
        fetching: false,
        mustBeUpdated: false,
        rejecting: false,
        rejected: false,
        entity: action.payload
      }

    case GET_PREVALORIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
        fetching: false,
        rejecting: false,
        rejected: false,
        entity: null,
      }

    default:
      return state
  }
}

export default Remittance
