let entity = "LoadPlan"
export const GET_ALL =  `${process.env.REACT_APP_URL}${entity}`
export const GET_ALL_STATES =  `${process.env.REACT_APP_URL}${entity}/status`
export const GET_COUNT =  `${process.env.REACT_APP_URL}${entity}/count`
export const GET_TYPES =  `${process.env.REACT_APP_URL}${entity}/types`
export const GET =  `${process.env.REACT_APP_URL}${entity}/`
export const GET_PREVALUATION_URL =  `${process.env.REACT_APP_URL}${entity}/prevaluation/`
export const REMOVE =  `${process.env.REACT_APP_URL}${entity}?id=`
export const POST =  `${process.env.REACT_APP_URL}${entity}/`
export const PUT =  `${process.env.REACT_APP_URL}${entity}/`
export const PUT_TOGGLE =  `${process.env.REACT_APP_URL}${entity}/type/toggle`
export const DOWNLOAD =  `${process.env.REACT_APP_URL}${entity}/download`
export const REJECT =  `${process.env.REACT_APP_URL}${entity}/reject`
export const ACCEPT =  `${process.env.REACT_APP_URL}${entity}/accept/`
export const ACTIVATE =  `${process.env.REACT_APP_URL}${entity}/activate`