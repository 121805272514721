import React, { Component, useEffect, useState } from "react";
import { Switch, BrowserRouter as Router, useHistory } from "react-router-dom";
import { connect } from "react-redux";

//toastify
import { Toaster } from 'react-hot-toast';

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// actions
import * as userTypes from "./store/users/actionTypes";
import * as notificationTypes from "./store/notification/actionTypes";
// Import scss
import "./assets/scss/theme.scss";
import { Button, Card, CardBody, CardFooter, Col, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { useFormik } from "formik";

const App = ({ firstAccess,userType, userId, changePassword, updatingPassword, updatedPassword }) => {
    const history = useHistory();
    const [ShowUpdatePassword, setShowUpdatePassword] = useState(firstAccess);

    useEffect(() => {
        setShowUpdatePassword(firstAccess);

        return () => {
            setShowUpdatePassword(false);
        }
    }, [firstAccess])

    const changePasswordFormik = useFormik({
        initialValues: { password: " ", confirmPassoword: " " },
        onSubmit: values => {
            changePassword({ ...values, userId: userId });
        }
    })



    const handleChange = () => {
        changePasswordFormik.submitForm();
    }
   
   

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {publicRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={route.key ?? idx}
                            isAuthProtected={false}
                        />
                    ))}

                    {authProtectedRoutes.map((route, idx) => {
                        let allowedType  = route?.userAllowed;
                        if(allowedType===undefined){
                            return <AppRoute
                            path={route.path}
                            layout={HorizontalLayout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={true}/>
                        }else if(allowedType!==undefined && allowedType ===userType){
                            return <AppRoute
                            path={route.path}
                            layout={HorizontalLayout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={true}
                        />
                        }
                        
                        
                    })}
                </Switch>
            </Router>
            <Toaster />

            <Modal
                size="sm"
                fade={false}
                centered={true}
                isOpen={ShowUpdatePassword}
                toggle={() => false}

            >
                <ModalHeader toggle={() => false}>
                    Actualizar contraseña
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Input
                                            placeholder="Nueva contraseña"
                                            id='password'
                                            name="password"
                                            onChange={changePasswordFormik.handleChange}
                                            type="password" />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Input
                                            placeholder="Repita la nueva contraseña"
                                            id='confirmPassword'
                                            name="confirmPassword"
                                            onChange={changePasswordFormik.handleChange}
                                            type="password" />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    {updatedPassword ? <a className="btn btn-danger btn-lg btn-block  btn btn-primary " color="danger" href="/logout">Cerrar sesión</a> :
                                        <Button onClick={handleChange} className="btn btn-success btn-lg btn-block  btn btn-primary " color="success" type="submit" >
                                            {updatingPassword ? <Spinner size='sm'></Spinner> : 'Guardar'}
                                        </Button>}
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        userType: state.Login.user?.role?.name ?? "anonn",
        layout: state.Layout,
        firstAccess: state.Login.user?.user?.firstAccess,
        userId: state.Login.user?.user?.id,
        updatingPassword: state.Users?.updatingPassword,
        updatedPassword: state.Users?.updatedPassword
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (filter) => dispatch({ type: userTypes.CHANGE_PASSWORD, payload: filter }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
