let entity = "REMITTANCES"
export const GET_ALL = `GET_ALL_${entity}`
export const GET_ALL_SUCCESS = `GET_ALL_${entity}_SUCCESS`
export const GET_ALL_FAIL = `GET_ALL_${entity}_FAIL`

export const GET = `GET_${entity}`
export const GET_SUCCESS = `GET_${entity}_SUCCESS`
export const GET_FAIL = `GET_${entity}_FAIL`

export const REJECT = `REJECT_${entity}`
export const REJECT_SUCCESS = `REJECT_${entity}_SUCCESS`
export const REJECT_FAIL = `REJECT_${entity}_FAIL` 

export const UPLOAD = `UPLOAD_${entity}`
export const UPLOAD_SUCCESS = `UPLOAD_${entity}_SUCCESS`
export const UPLOAD_FAIL = `UPLOAD_${entity}_FAIL` 


export const UPLOAD_AUTOMATIC = `UPLOAD_AUTOMATIC_${entity}`
export const UPLOAD_AUTOMATIC_SUCCESS = `UPLOAD_AUTOMATIC_${entity}_SUCCESS`
export const UPLOAD_AUTOMATIC_FAIL = `UPLOAD_AUTOMATIC_${entity}_FAIL` 

export const SAVE_PREVALORIZATION = `SAVE_PREVALORIZATION_${entity}`
export const SAVE_PREVALORIZATION_SUCCESS = `SAVE_PREVALORIZATION_${entity}_SUCCESS`
export const SAVE_PREVALORIZATION_FAIL = `SAVE_PREVALORIZATION_${entity}_FAIL`

export const UPDATE_PREVALORIZATION = `UPDATE_PREVALORIZATION_${entity}`
export const UPDATE_PREVALORIZATION_SUCCESS = `UPDATE_PREVALORIZATION_${entity}_SUCCESS`
export const UPDATE_PREVALORIZATION_FAIL = `UPDATE_PREVALORIZATION_${entity}_FAIL`

export const GET_PREVALORIZATION = `GET_PREVALORIZATION__${entity}`
export const GET_PREVALORIZATION_SUCCESS = `GET_PREVALORIZATION__${entity}_SUCCESS`
export const GET_PREVALORIZATION_FAIL = `GET_PREVALORIZATION__${entity}_FAIL`
