import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row, Table, UncontrolledAlert } from 'reactstrap';
import Loader from '../../../common/Loader';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import * as loadPlansActionTypes from "../../../store/LoadPlans/actionTypes";
import * as remittanceActionTypes from "../../../store/Remittance/actionTypes";
import * as filesActionTypes from "../../../store/Files/actionTypes";
import './styles.css'
const Preview = ({
    Get,
    getPDF,
    fetching,
    entity,
    PDF,
    reject,
    userType

}) => {
    const { remittanceId, number, pc, automaticMode } = useParams();
    const [CanReject, setCanReject] = useState();
    const ACEPTADO = 'Aceptado';
    const INCOMPLETE = 'Incompleto';
    const COMPLETE = 'Completo';
    const COMPLETE_PENDING_FORM_ID_OLD = 3;
    const ZOTM = 'ZOTM'

    const Mode = () =>{
        switch (automaticMode) {
            case 'A':
                return true;
            case 'M':
                return false;
            default:
               return false;
        }
    }
    useEffect(() => {
        console.log(Mode())
        getPDF({ route: 'remittance', data: number, automatic: Mode() })
        Get(pc);
    }, [])

    const handleReject = () => {
        if (window.confirm("¿Desea proceder con la devolución de material?")) {
            reject({ id: remittanceId })
        }
    }

    useEffect(()=>{
        if(entity!==null){
            if(entity?.documentType?.name==ZOTM &&( entity?.status?.name==ACEPTADO ||  entity?.status?.name==INCOMPLETE)){
                setCanReject(true);
            }else if( entity?.status?.name==COMPLETE ||  entity?.status?.oldRefNumber == COMPLETE_PENDING_FORM_ID_OLD){
                setCanReject(true);
            }
        }
    },[entity])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{`Remito: # ${number}`}</h4>

                                <div className="page-title-right">
                                    <Link className='btn btn-light' to={`/outbound/files/${pc}`}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}
                                    <Button disabled={!CanReject} hidden={userType==='viewer'} onClick={handleReject} color='danger'><i className="mdi mdi-cancel me-2"></i> Devolución</Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Loader isLoading={fetching}>
                                {

                                    <Card>
                                        <Row className="no-gutters align-items-center">
                                            {!PDF && <UncontrolledAlert color="danger" role="alert">
                                                <i className="mdi mdi-alert-circle-outline me-2"></i>
                                                El transportista aún no ha digitalizado el documento.
                                            </UncontrolledAlert>}
                                            <Col md={12}>
                                                <div style={{ height: '800px', width: 'auto', backgroundColor: 'gray' }}>
                                                    {
                                                        PDF?.fileName !== '' ?
                                                            <iframe width={"100%"} height={"100%"} itemType="pdf" src={`${PDF}`} ></iframe>
                                                            : <div className='no-pdf-digitalized align-items-center '></div>
                                                    }
                                                </div>
                                            </Col>
                                            {/* <Col md={4}>
                                                        <div className="table-responsive">
                                                            <Table className="mb-0">


                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Número de remito:</th>
                                                                        <td align='center'>{item?.number}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Nro. Remito:</th>
                                                                        <td align='center'>{item?.remittanceNumberVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Duplicado:</th>
                                                                        <td align='center'>{item?.duplicatesVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Conformidad:</th>
                                                                        <td align='center'>{item?.conformityVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Chk. Observaciones:</th>
                                                                        <td align='center'>{item?.observationsVerified ?
                                                                            <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
                                                                            <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            <div><CardText><small className="text-muted">Última actualización disponible: {item?.digitalized ?? '-'}</small></CardText></div>
                                                        </div>
                                                    </Col> */}
                                        </Row>
                                    </Card>

                                }
                            </Loader>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    entity: state.LoadPlan.entity,
    fetching: state.LoadPlan?.fetching,
    PDF: state.Files.base64,
    userType: state.Login?.user?.user?.userType,
});


const mapDispatchToProps = (dispatch) => {
    return {
        Get: (id) => dispatch({ type: loadPlansActionTypes.GET, payload: id }),
        getPDF: ({ route, data, automatic }) => dispatch({ type: filesActionTypes.GET, payload: { route, data, automatic  } }),
        reject: (id) => dispatch({ type: remittanceActionTypes.REJECT, payload: id }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Preview);