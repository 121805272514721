import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Alert, Button, ButtonGroup, Card, CardBody, CardFooter, Col, Container, Input, Label, Row, Spinner } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as actions from "../../store/users/actions";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import UserColumns from './UserColumns';
import { Link, useHistory } from "react-router-dom"
import CustomModal from "../../components/Common/CustomModal"
import { useFormik } from "formik"
import Loader from "../../common/Loader"
import Validations from "./Form/Validations";
const Users = ({errors, users, getUsers, getTypes, totalCount, remove, changePassword, updatingPassword, updatedPassword, toggle, saved, toggled, UserId, isLoading, roles }) => {
    const [RegeneratePasswordModal, setRegeneratePasswordModal] = useState(false)
    const [ToggleUserModal, setToggleUserModal] = useState(false)
    const [FilterActive, setFilterActive] = useState(5)
    const [Filters, setFilters] = useState({typeId:null});
    const history = useHistory();
    const [SelectedUser, setSelectedUser] = useState();
    const [UserState, setUserState] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(1);
    const [UserType, setUserType] = useState();
    const [PageOptions, setPageOptions] = useState({
        hideSizePerPage: true,
        sizePerPage: 10,
        showTotal: true,
        totalSize: totalCount, // replace later with size(users),
        page: CurrentPage,
        active: true,
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Mostrando {from} a {to} de {size} Resultados
            </span>
        )
    })

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const { SearchBar } = Search

    const handleSelectedUser = id => {
        setSelectedUser(id)
        setRegeneratePasswordModal(true);
    }

    const handleToggle = (id, status) => {
        setSelectedUser(id);
        setUserState(status);
        setToggleUserModal(true);

    }

    const handleNewUser = () => history.push("/user/add");
    const handleFilterUsers = ( value ) =>{
        if(value.length%3 ==0){
            getUsers({Name:value, typeId:UserType})

        }else if(value.length==0){
            getUsers({typeId:UserType})
        }
    }

    const changePasswordFormik = useFormik({
        initialValues: { password: " ", userId:""},
        onSubmit: values => {
            changePassword({ ...values, userId: SelectedUser });
        }
    })

    useEffect(() => {
        if (updatedPassword) {
            setTimeout(() => {
                setRegeneratePasswordModal(false)
            }, 1000);
        }
    }, [updatedPassword])


    useEffect(() => {
        if (toggled) {
            getUsers();
            setTimeout(() => {
                setToggleUserModal(false)
            }, 1000);
        }
    }, [toggled])

    useEffect(() => {
        if (saved) {
            getUsers();
        }
    }, [saved])

    useEffect(() => {
        getTypes();
        getUsers({ page: 1, size: 10 })
    }, [])



    const handlePageChange = (style, values) => {
        setCurrentPage(values?.page);
        getUsers({ page: values?.page, size: 10 })
    }

    useEffect(() => {
        if (totalCount > 0) {
            setPageOptions({
                hideSizePerPage: true,
                sizePerPage: 10,
                showTotal: true,
                active: true,
                totalSize: totalCount, // replace later with size(users),
                paginationTotalRenderer: (from, to, size) => (
                    <span className="react-bootstrap-table-pagination-total">
                        Mostrando {from} a {to} de {size} Resultados
                    </span>
                )
            })
        }

    }, [totalCount])

    const handleGetUsers = (filter) =>{
        setUserType(filter.typeId)
        getUsers({...filter, page: 1, size: 10});
    }
    const handleRegeneratePasswordModal = () => setRegeneratePasswordModal(!RegeneratePasswordModal)
    const handleToggleModal = () => setToggleUserModal(!ToggleUserModal);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{`Usuarios`}</h4>

                                <div className="page-title-right">
                                    <Link className='btn btn-light' to={"/dashboard"}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>

                                    <React.Fragment>
                                        <Row>
                                            <Col sm="4">
                                            <div className="text-sm">
                                                <Input autoFocus placeholder="Buscar usuarios" onChange={({target:{value}})=>handleFilterUsers(value)} color="light" className="btn-rounded "></Input>
                                                </div>
                                            </Col>
                                            <Col sm="8">
                                              
                                                <div className="text-sm-end">
                                                <ButtonGroup size="md" className="mb-2 me-2">
                                                        <Loader isLoading={isLoading}></Loader>
                                                       {
                                                        roles && !isLoading && roles.map((i,indx)=>(
                                                        <Button active={FilterActive == indx-1} onClick={() => {handleGetUsers({ page: 1, pageSize: 10, RoleId: i.id}); setFilterActive(indx-1) }} color="light" className="btn-rounded " >
                                                         {i.name}
                                                    </Button>))
                                                       }
                                                       <Button active={FilterActive == 5} onClick={() => {handleGetUsers({ page: 1, pageSize: 10, typeId: null}); setFilterActive(5) }} color="light" className="btn-rounded " >
                                                        <i className="mdi mdi-account-group-outline font-size-14"></i>  Todos
                                                    </Button>
                                                    </ButtonGroup>
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        className="btn-rounded mb-2 me-2"
                                                        onClick={handleNewUser}
                                                    >
                                                        <i className="mdi mdi-plus me-1" />{" "}
                                                        Nuevo usuario
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table table-responsive">
                                            <BootstrapTable
                                                remote
                                                keyField={"id"}
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={
                                                    "table align-middle table-nowrap table-sm"
                                                }
                                                onTableChange={handlePageChange}
                                                pagination={paginationFactory({ ...PageOptions, page: CurrentPage })}

                                                columns={UserColumns(remove, handleSelectedUser, handleToggle, history, UserId)}
                                                data={users?.data ?? []}
                                                search={false}
                                                headerWrapperClasses={"thead-light"}
                                            />

                                        </div>

                                    </React.Fragment>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CustomModal
                title={"Regenerar Contraseña"}
                size='LG'
                handleToggle={handleRegeneratePasswordModal}
                centered
                isOpen={RegeneratePasswordModal}
                children={<>
                    <form onSubmit={changePasswordFormik.handleSubmit}>
                     <input hidden name="UserId"  value={SelectedUser}/>
                        <Card>
                          {errors && <Validations errors={errors} /> }
                            <CardBody>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Input
                                            placeholder="Nueva contraseña"
                                            id='password'
                                            name="password"
                                            onChange={changePasswordFormik.handleChange}
                                            type="password" />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Button disabled={updatingPassword} className="btn btn-success btn-lg btn-block  btn btn-primary " color="success" type="submit" >
                                        {updatingPassword ? <Spinner size='sm'></Spinner> : 'Guardar'}
                                    </Button>
                                </Row>
                            </CardFooter>
                        </Card>
                    </form>

                </>}
            />

            <CustomModal
                title={`${UserState ? 'Desactivar' : 'Activar'} usuario`}
                size='sm'
                handleToggle={handleToggleModal}
                centered
                isOpen={ToggleUserModal}
                children={<>
                    <Card>
                        <CardFooter>
                            <Row>
                                <Button disabled={updatingPassword} onClick={() => toggle({ userId: SelectedUser })} className="btn btn-success btn-lg btn-block  btn btn-primary " color={`${UserState ? 'danger' : 'success'}`}>
                                    {updatingPassword ? <Spinner size='sm'></Spinner> : UserState ? 'Desactivar' : 'Activar'}
                                </Button>
                            </Row>
                        </CardFooter>
                    </Card>
                </>}
            />
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    totalCount: state.Users?.totalCount,
    users: state?.Users?.users??undefined,
    isLoading: state?.Users?.isFetching??false,
    UserId: state.Login?.user?.id??null,
    updatingPassword: state.Users?.updatingPassword,
    updatedPassword: state.Users?.updatedPassword,
    saved: state.Users.saved,
    updateEntity: state.Users.updatingEntity,
    toggled: state.Users.toggled,
    roles: state.Users?.types,
    errors: state.Users?.error??undefined
});



export default connect(mapStateToProps, actions)(Users);