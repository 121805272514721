import {
  REMOVE,
  GET,
  GET_FAIL,
  GET_SUCCESS,
  GET_ALL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  SAVE,
  SAVE_FAIL,
  SAVE_SUCCESS,
  REMOVE_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  REMOVE_FAIL,
  GET_BY_USER,
  GET_BY_USER_SUCCESS,
  GET_BY_USER_FAIL,
} from "./actionTypes"

export const getAll = () => ({
  type: GET_ALL,
})

export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})

export const get = () => ({
  type: GET,
})

export const getSuccess = entity => ({
  type: GET_SUCCESS,
  payload: entity,
})

export const getFail = error => ({
  type: GET_FAIL,
  payload: error,
})


export const getByUser = (id) => ({
  type: GET_BY_USER,
  payload: id
})

export const getByUserSuccess = entity => ({
  type: GET_BY_USER_SUCCESS,
  payload: entity,
})

export const getByUserFail = error => ({
  type: GET_BY_USER_FAIL,
  payload: error,
})

export const remove = (id) => ({
  type: REMOVE,
  payload:id
})

export const removeSuccess = entity => ({
  type: REMOVE_SUCCESS,
  payload: entity,
})

export const removeFail = error => ({
  type: REMOVE_FAIL,
  payload: error,
})
export const save = (entity) => ({
  type: SAVE,
  payload:entity
})

export const saveSuccess = result => ({
  type: SAVE_SUCCESS,
  payload: result,
})

export const saveFail = error => ({
  type: SAVE_FAIL,
  payload: error,
})

export const update = (entity) => ({
  type: UPDATE,
  payload:entity
})

export const updateSuccess = result => ({
  type: UPDATE_SUCCESS,
  payload: result,
})

export const updateFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})
