import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input, Button, Alert, CardHeader } from "reactstrap";
import { useFormik, Field } from 'formik';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import * as actions from "../../../store/users/actions";
import * as userActionTypes from "../../../store/users/actionTypes";
import * as carrierActionType from "../../../store/carrier/actionTypes";
import * as boxActionTypes from "../../../store/boxes/actionTypes";
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const Form = ({
    save,
    update,
    getCarriers,
    get,
    error,
    list,
    entity,
    isLoading,
    mustBeUpdated }) => {
    let params = useParams();
    const history = useHistory();
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Cajas", link: "/boxes" },
        { title: "Nueva", link: "#" },
    ])

    const formik = useFormik({
        initialValues: params.id ? { ...entity } : {},
        onSubmit: values => {
            if (params.id)
                update({number: values.number, userId: values.userId, id: values.id})
            else
            {
                save(values);
            }
        },
        enableReinitialize: params.id,
    });


    useEffect(() => {
        if(params.id)
            get(params.id)
        else
        getCarriers()

        return () => {
            formik.resetForm();
        }
    }, [])

    //entidad cargada
    useEffect(() => {
        if(mustBeUpdated){
            getCarriers();
            history.goBack();
        }
        return () => {
        }
    }, [mustBeUpdated])

    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>

                    <Breadcrumbs title={params.id ? "Editar caja" : "Alta de caja"} breadcrumbItems={breadcrumbItems} />
                    <Row>

                        <Col xs={12}>

                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Row className="mb-3">
                                            <Label htmlFor="number" className="col-md-2 col-form-label">Número</Label>
                                            <Col md={2}>
                                                <Input
                                                    id='number'
                                                    name="number"
                                                    type="number"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.number} />
                                            </Col>
                                        </Row>
                                     
                                        <Row className="mb-3">
                                            <Label className="col-md-2 col-form-label">Transportista</Label>
                                            <Col md={10}>
                                                <select onChange={formik.handleChange} name='userId' className="form-control">
                                                    <option>Seleccione un transportista</option>
                                                    {list && list.data?.map(x => (<option selected={x.id == formik.values?.userId} value={x.id}>{x.givenName}</option>))}
                                                </select>
                                            </Col>
                                        </Row>
                                      
                                        <div style={{ float: 'right' }}>
                                            <Button onClick={() => history.goBack()} color='link'>Volver</Button>{' '}
                                            <Button type='submit' color='primary'>Guardar</Button>

                                        </div>

                                    </form>

                                </CardBody>

                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = state => ({
    list: state.Carriers?.entities,
    isloading:state.Boxes?.isLoading,
    mustBeUpdated:state.Boxes?.mustBeUpdated,
    entity:state.Boxes?.entity,
});

const mapDispatchToProps = (dispatch) => {
    return {
        save: (data) => dispatch({ type: boxActionTypes.SAVE, payload: data }),
        update: (data) => dispatch({ type: boxActionTypes.UPDATE, payload: data }),
        getCarriers: (filter) => dispatch({ type: carrierActionType.GET_CARRIERS, payload: filter }),
        get: (id) => dispatch({ type: boxActionTypes.GET, payload: id }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);