import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input, Button, Alert, CardHeader, Table } from "reactstrap";
import { isEmptyChildren, useFormik } from 'formik';
//Import Breadcrumb
import { connect } from 'react-redux';
import * as userActionTypes from "../../../store/users/actionTypes";
import * as boxActionTypes from "../../../store/boxes/actionTypes";
import * as remittanceActionTypes from "../../../store/Remittance/actionTypes";
import * as plansActionTypes from "../../../store/LoadPlans/actionTypes";
import { Link, useHistory, useParams } from 'react-router-dom';


const Form = ({
    savePrevalue,
    get,
    prevalorization,
    updatePrevalue,
}) => {
    const [IsReturn, setIsReturn] = useState(false)
    const [IsReturnEmpty, setIsReturnEmpty] = useState(false)
    const [ReturnSymbol, setReturnSymbol] = useState('-')
    const [returnEmptyType, setReturnEmptyType] = useState(0);
    const [returnType, setReturnType] = useState(0);
    let params = useParams();
    const history = useHistory();
    // const [Tipo, setTipo] = useState(    )

    const formik = useFormik({
        initialValues: params.id ? {} : {},
        onSubmit: values => {
                savePrevalue({
                    ...values,
                    pc: params.pc,
                    LoadPlanId: params.id,
                    tonsAssessed: formik.values.capacity,
                    returnEmptyType: returnEmptyType,
                }, history)
        },
        enableReinitialize: params.id,
    });

    const Reset = () => {
        formik.setFieldValue("capacityLoad", "0.00")
        formik.setFieldValue("from", "Villa constitución")
        formik.setFieldValue("capacity", "26.00")
        formik.setFieldValue("unitType", "$/Viaje")
        formik.setFieldValue("kilometers", "0.00")
        formik.setFieldValue("tonsAssessed", "0.00")
        formik.setFieldValue("totalToPay", "0.00")
        formik.setFieldValue("destination", "")
        formik.setFieldValue("baseRateValue", "0.00")
        formik.setFieldValue("baseRate", "0.00")
        formik.setFieldValue("additionalKilometers", "0")
        formik.setFieldValue("additionalKilometersRate", "0.00")
        formik.setFieldValue("additionalKilometersRateValue", "0.00")
        formik.setFieldValue("ReturnValue", "0.00")
        formik.setFieldValue("Return", 0)
        formik.setFieldValue("returnType", 0)
        formik.setFieldValue("returnEmptyType", 0)
        formik.setFieldValue("quantityDelivery", "0")
        formik.setFieldValue("deliveryFee", "0.00")
        formik.setFieldValue("distributionRateValue", "0.00")
        formik.setFieldValue("ReturnRate", "0.90")
        formik.setFieldValue("quantityHoursStays", "0")
        formik.setFieldValue("rateHourStays", "0.00")
        formik.setFieldValue("valueRateHourStays", "0.00")
        formik.setFieldValue("returnEmptyRate", "0.00")
        formik.setFieldValue("emptyReturnValue", "0.00")
        formik.setFieldValue("drivingLicense", "0.00")
        formik.setFieldValue("observation", "");
        setReturnType(0);
        setIsReturn(false)
        setReturnEmptyType(0)
        setIsReturnEmpty(false)
    }

    useEffect(() => {
        if(prevalorization){
            if (Object.keys(prevalorization).length > 0) {
                formik.setFieldValue("capacity", prevalorization.capacity)
                formik.setFieldValue("capacityLoad", prevalorization.capacityLoad)
                formik.setFieldValue("destination", prevalorization.destination)
                formik.setFieldValue("unitType", prevalorization.unitType)
                formik.setFieldValue("kilometers", prevalorization.kilometers)
                formik.setFieldValue("tonsAssessed", prevalorization.tonsAssessed)
                formik.setFieldValue("totalToPay", prevalorization.totalToPay)
                formik.setFieldValue("baseRateValue", prevalorization.baseRateValue)
                formik.setFieldValue("baseRate", prevalorization.baseRate)
                formik.setFieldValue("additionalKilometers", prevalorization.additionalKilometers)
                formik.setFieldValue("additionalKilometersRate", prevalorization.additionalKilometersRate)
                formik.setFieldValue("additionalKilometersRateValue", prevalorization.additionalKilometersRateValue)
                formik.setFieldValue("ReturnValue", "0.00")
                formik.setFieldValue("quantityDelivery", prevalorization.quantityDelivery)
                formik.setFieldValue("deliveryFee", prevalorization.deliveryFee)
                formik.setFieldValue("distributionRateValue", prevalorization.distributionRateValue)
                formik.setFieldValue("ReturnRate", "0.90")
                formik.setFieldValue("quantityHoursStays", prevalorization.quantityHoursStays)
                formik.setFieldValue("rateHourStays", prevalorization.rateHourStays)
                formik.setFieldValue("valueRateHourStays", prevalorization.valueRateHourStays)
                formik.setFieldValue("returnEmptyRate", prevalorization.returnEmptyRate)
                formik.setFieldValue("emptyReturnValue", prevalorization.emptyReturnValue)
                formik.setFieldValue("drivingLicense", prevalorization.drivingLicense)
                formik.setFieldValue("from", prevalorization.from)
                formik.setFieldValue("observation", prevalorization.observation)
                formik.setFieldValue("returnEmptyType", prevalorization.returnEmptyType)
                formik.setFieldValue("returnType", prevalorization.returnType)
                setReturnEmptyType(prevalorization.returnEmptyType);
                setReturnType(prevalorization.returnType);
        }
        }
    }, [prevalorization]);


    useEffect(() => {
        get(params.id)
        if(!prevalorization){
                formik.setFieldValue("capacityLoad", "0.00")
                formik.setFieldValue("from", "Villa constitución")
                formik.setFieldValue("capacity", "26.00")
                formik.setFieldValue("unitType", "$/Viaje")
                formik.setFieldValue("kilometers", "0.00")
                formik.setFieldValue("tonsAssessed", "0.00")
                formik.setFieldValue("totalToPay", "0.00")
                formik.setFieldValue("baseRateValue", "0.00")
                formik.setFieldValue("baseRate", "0.00")
                formik.setFieldValue("additionalKilometers", "0")
                formik.setFieldValue("additionalKilometersRate", "0.00")
                formik.setFieldValue("additionalKilometersRateValue", "0.00")
                formik.setFieldValue("ReturnValue", "0.00")
                formik.setFieldValue("Return", false)
                formik.setFieldValue("returnType", 0)
                formik.setFieldValue("returnEmptyType", 0)
                formik.setFieldValue("Return", false)
                formik.setFieldValue("quantityDelivery", "0")
                formik.setFieldValue("deliveryFee", "0.00")
                formik.setFieldValue("distributionRateValue", "0.00")
                formik.setFieldValue("ReturnRate", "0.90")
                formik.setFieldValue("quantityHoursStays", "0")
                formik.setFieldValue("rateHourStays", "0.00")
                formik.setFieldValue("valueRateHourStays", "0.00")
                formik.setFieldValue("returnEmptyRate", "0.00")
                formik.setFieldValue("emptyReturnValue", "0.00")
                formik.setFieldValue("drivingLicense", "0.00")
                formik.setFieldValue("observation", "");
        }
        



    }, [])

    const handleReturnEmpty = (value) => {
        setReturnEmptyType(parseInt(value));
        switch (value) {
            case "0":
                formik.setFieldValue("returnEmptyRate", "0.00")
                setReturnSymbol("-")
                break;
            case "1":
                formik.setFieldValue("returnEmptyRate", "60")
                setIsReturnEmpty(true)
                setReturnSymbol("%")
                break;
            case "2":
                formik.setFieldValue("returnEmptyRate", "0.00")
                setIsReturnEmpty(true)
                setReturnSymbol("$")
                break;
            default:
                break;
        }
    }

    const Calculate = () => {
        let _MeasuredTons = parseFloat("0.00");
        let _LoadedTons = parseFloat(formik.values.capacityLoad ?? 0);
        let _Capacity = parseFloat(formik.values.capacity ?? 0);
        let _BaseRate = parseFloat(formik.values.baseRate ?? 0);
        let _Kilometers = parseFloat(formik.values.kilometers ?? 0);
        let _RateType = formik.values.unitType;
        let _DeliveryQtty = (parseFloat(formik.values.quantityDelivery) - 1 <= 0) ? 0 : parseFloat(formik.values.quantityDelivery) - 1;
        let _DeliveryRate = parseFloat(formik.values.deliveryFee ?? 0);
        let _AditionalKM = parseFloat(formik.values.additionalKilometers ?? 0);
        let _ReturnRate = parseFloat(formik.values.ReturnRate ?? 0);
        let _HoursQtty = parseFloat(formik.values.quantityHoursStays ?? 0);
        let _HoursRate = parseFloat(formik.values.rateHourStays ?? 0);
        let _DrivingLicense = parseFloat(formik.values.drivingLicense ?? 0);
        let _ReturnEmptyRate = parseFloat(formik.values.returnEmptyRate ?? 0);
        let _ReturnEmptyValue = 0;
        let _HoursValue = 0;
        let _TotalToPay = 0;
        let _BaseRateValue = 0;
        let _DeliveryValue = 0;


        //#region calculate measured tons
        if ((_Capacity - _LoadedTons) < 0) {
            formik.setFieldValue("tonsAssessed", "0.00");
        } else {
            formik.setFieldValue("tonsAssessed", parseFloat(_Capacity - _LoadedTons));
        }

        //#endregion

        //#region Base rate 
        switch (_RateType) {
            case '$/Viaje':
                _BaseRateValue = Math.round(_BaseRate).toFixed(2)
                break;
            case '$/Km':
                _BaseRateValue = Math.round(_BaseRate * _Kilometers).toFixed(2);
                break;
            case '$/Tn':
                _BaseRateValue = Math.round((_LoadedTons + _MeasuredTons) * _BaseRate).toFixed(2);
                break;
            default:
                break;
        }
        formik.setFieldValue("baseRateValue", _BaseRateValue);
        //#endregion
        _TotalToPay = parseFloat(_TotalToPay) + parseFloat(_BaseRateValue);
        //#region delivery 
        switch (_RateType) {
            case '$/Viaje':
                _DeliveryValue = Math.round(_DeliveryQtty * _DeliveryRate).toFixed(2);
                break;
            case '$/Km':
                _DeliveryValue = Math.round(_DeliveryQtty * _DeliveryRate).toFixed(2);
                break;
            case '$/Tn':
                _DeliveryValue = Math.round((_DeliveryQtty + _DeliveryRate) ).toFixed(2);
                break;
            default:
                break;
        }
        formik.setFieldValue("distributionRateValue", _DeliveryValue);
        _TotalToPay = parseFloat(_TotalToPay) + parseFloat(_DeliveryValue);
        //#endregion

        //#region KM Adicional 
        let _AditionalKMRate = 0;
        if (_Kilometers > 0) {
            switch (_RateType) {
                case '$/Viaje':
                    _AditionalKMRate = parseFloat(_BaseRate / _Kilometers).toFixed(2);
                    break;
                case '$/Km':
                    _AditionalKMRate = _BaseRate.toFixed(2);
                    break;
                case '$/Tn':
                    _AditionalKMRate = (_BaseRate / _Kilometers) * (_MeasuredTons + _LoadedTons).toFixed(2);
                    break;
                default:
                    break;
            }
        }
        formik.setFieldValue("additionalKilometersRate", _AditionalKMRate);
        //#endregion
        formik.setFieldValue("additionalKilometersRateValue", Math.round(_AditionalKMRate * _AditionalKM).toFixed(2));
        _TotalToPay = parseFloat(_TotalToPay) + parseFloat(Math.round(_AditionalKMRate * _AditionalKM).toFixed(2));

        if (IsReturn) {
            formik.setFieldValue("ReturnValue", (_BaseRateValue * _ReturnRate).toFixed(2));
            _TotalToPay = parseFloat(_TotalToPay) + parseFloat(_BaseRateValue * _ReturnRate);
        } else {
            formik.setFieldValue("ReturnValue", "0.00")
        }

        _HoursValue = parseFloat(_HoursQtty) * parseFloat(_HoursRate);
        formik.setFieldValue("valueRateHourStays", parseFloat(_HoursValue).toFixed(2))

        if (returnEmptyType > 0) {
            if (returnEmptyType == 1) {
                _ReturnEmptyValue = (parseFloat(_BaseRate) * 0.60) * 26
            } else if (returnEmptyType == 2) {
                _ReturnEmptyValue = (parseFloat(_ReturnEmptyRate) * 0.60) * 26
            }
        } else {
            _ReturnEmptyValue = 0.00;
        }

        formik.setFieldValue("emptyReturnValue", parseFloat(_ReturnEmptyValue).toFixed(2))

        _TotalToPay = parseFloat(_TotalToPay) + parseFloat(_HoursValue) + parseFloat(_DrivingLicense) + _ReturnEmptyValue;

        formik.setFieldValue("totalToPay", parseFloat(_TotalToPay).toFixed(2))
    }

    const handleSelectFocus = (event) => {
        const { name, value } = event.target;
        formik.setFieldValue([name], "");
    }

    const handleBlur = (event) => {
        const { name, value } = event.target;
        if (value == "")
            formik.setFieldValue([name], "0.00");
    }
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{`Plan de carga: # `}</h4>

                                <div className="page-title-right">
                                    <Link className='btn btn-light' to={"/outbound"}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={12}>

                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Row className="mb-3" >
                                            <div className="table-responsive">
                                                <Table size="sm" className="m-0">
                                                    <thead>
                                                        <tr >
                                                            <th colSpan={4} style={{ textAlign: 'center', backgroundColor: '#17a2b8', color: 'white', fontSize: '16px' }}>Datos del viaje    </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Tipo unidad</th>
                                                            <td>
                                                                <select name='capacity' onChange={formik.handleChange} className='form-control-sm'>
                                                                    <option value={`15.00`}>Balancín</option>
                                                                    <option value={`22.00`}>Semi Corto</option>
                                                                    <option selected value={`26.00`}>Semi</option>
                                                                    <option value={`26.00`}>Panal de abeja</option>
                                                                    <option value={`36.00`}>Escalable 1+1+1</option>
                                                                    <option value={`34.00`}>Escalable 2+1/3</option>
                                                                    <option value={`45.50`}>Bitren 25 Mts</option>
                                                                    <option value={`51.50`}>Bitren 30 Mts</option>
                                                                </select>
                                                            </td>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>Plan de Carga</td>
                                                            <td><input disabled className='form-control-sm' value={`# ${params.pc}`}></input></td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Capacidad</th>
                                                            <td><Input name='capacity' disabled value={`${formik.values.capacity} TN`} style={{ width: '40%', textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>Origen</td>
                                                            <td>
                                                                <select name='from' onChange={formik.handleChange} className='form-control-sm'>
                                                                    <option selected={formik.values.from === 'Alvear'} value={'Alvear'}>Alvear</option>
                                                                    <option selected={formik.values.from === 'CLMP'} value={'CLMP'}>CLMP</option>
                                                                    <option selected={formik.values.from === 'CLMZ'} value={'CLMZ'}>CLMZ</option>
                                                                    <option selected={formik.values.from === 'General Rodríguez'} value={'General Rodríguez'}>General Rodríguez</option>
                                                                    <option selected={formik.values.from === 'Rosario'} value={'Rosario'}>Rosario</option>
                                                                    <option selected={formik.values.from === 'San Nicolas'} value={'San Nicolas'}>San Nicolas</option>
                                                                    <option selected={formik.values.from === 'T100'} value={'T100'}>T100</option>
                                                                    <option selected={formik.values.from === 'Tablada'} value={'Tablada'}>Tablada</option>
                                                                    <option selected={formik.values.from === 'Villa constitución'} value={'Villa constitución'}>Villa constitución</option>
                                                                    <option selected={formik.values.from === 'Villa mercedes'} value={'Villa mercedes'}>Villa mercedes</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <td></td>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>Destino final</td>
                                                            <td><Input name='destination' value={formik.values.destination} onChange={formik.handleChange} className='form-control-sm'></Input></td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <td></td>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>Kilometros</td>
                                                            <td><Input onBlur={handleBlur} type='number' onFocus={handleSelectFocus} name='kilometers' value={formik.values.kilometers} onChange={formik.handleChange} style={{ width: '40%', textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <td></td>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>Tipo de tarifa  </td>
                                                            <td>
                                                                <select name='unitType' onChange={formik.handleChange} className='form-control-sm'>
                                                                    <option selected={formik.values.unitType == '$/Viaje'} >$/Viaje</option>
                                                                    <option selected={formik.values.unitType == '$/Km'}>$/Km</option>
                                                                    <option selected={formik.values.unitType == '$/Tn'}>$/Tn</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <td></td>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>TON. Cargadas</td>
                                                            <td><Input  onBlur={handleBlur} onFocus={handleSelectFocus} type='number' name='capacityLoad' value={formik.values.capacityLoad} onChange={formik.handleChange} style={{ width: '40%', textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <td></td>
                                                            <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>TON. Aforadas</td>
                                                            <td><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' disabled value={formik.values.tonsAssessed} style={{ width: '40%', textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>

                                            </div>
                                        </Row>


                                        <Row className="mb-3">
                                            <div className="table-responsive">
                                                <Table size="sm" className="m-0">
                                                    <thead>
                                                        <tr >
                                                            <th style={{ textAlign: 'center', backgroundColor: 'white', color: 'white', fontSize: '16px' }}></th>
                                                            <th style={{ textAlign: 'center', backgroundColor: '#17a2b8', color: 'white', fontSize: '16px' }}>Cantidad</th>
                                                            <th style={{ textAlign: 'center', backgroundColor: '#17a2b8', color: 'white', fontSize: '16px' }}>Tarifa</th>
                                                            <th style={{ textAlign: 'center', backgroundColor: '#17a2b8', color: 'white', fontSize: '16px' }}>Unidad de Medida</th>
                                                            <th style={{ textAlign: 'center', backgroundColor: '#17a2b8', color: 'white', fontSize: '16px' }}>Valorización</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Tarifa Base</th>
                                                            <th style={{ textAlign: 'center', verticalAlign: 'middle' }}></th>
                                                            <td><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' name='baseRate' onChange={formik.handleChange} value={formik.values.baseRate} className='form-control-sm' style={{ textAlign: 'right' }}></Input></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{formik.values.unitType}</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' name='baseRateValue' value={formik.values.baseRateValue} style={{ textAlign: 'right' }} disabled className='form-control-sm'></Input></td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Repartos / P. de cargas</th>
                                                            <th style={{ textAlign: 'center', verticalAlign: 'middle' }}><Input onBlur={handleBlur} onFocus={handleSelectFocus}  type='number' onChange={formik.handleChange} value={formik.values.quantityDelivery} name='quantityDelivery' style={{ textAlign: 'right' }} className='form-control-sm'></Input></th>
                                                            <td><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' onChange={formik.handleChange} value={formik.values.deliveryFee} name='deliveryFee' style={{ textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{formik.values.unitType}</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}><Input onBlur={handleBlur} onFocus={handleSelectFocus}  type='number' value={formik.values.distributionRateValue} name='distributionRateValue' style={{ textAlign: 'right' }} disabled className='form-control-sm'></Input></td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Kilometros adicionales</th>
                                                            <th style={{ textAlign: 'center', verticalAlign: 'middle' }}><Input onBlur={handleBlur} onFocus={handleSelectFocus}  type='number' onChange={formik.handleChange} value={formik.values.additionalKilometers} name='additionalKilometers' style={{ textAlign: 'right' }} className='form-control-sm'></Input></th>
                                                            <td><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' name='additionalKilometersRate' value={formik.values.additionalKilometersRate} disabled style={{ textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{formik.values.unitType}</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}><Input onBlur={handleBlur} onFocus={handleSelectFocus}  type='number' name='additionalKilometersRateValue' value={formik.values.additionalKilometersRateValue} style={{ textAlign: 'right' }} disabled className='form-control-sm'></Input></td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Devolución</th>
                                                            <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                <select name='Return' onChange={(value) => {
                                                                    setIsReturn(value.target.value == 1 ? true : false)
                                                                    setReturnType(value);
                                                                }}>
                                                                    <option selected={returnType == 1} value={1}>Si</option>
                                                                    <option selected={returnType == 0} value={0}>No</option>
                                                                </select>
                                                            </th>
                                                            <td><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' disabled={!IsReturn} value={formik.values.ReturnRate} onChange={formik.handleChange} name='ReturnRate' style={{ textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>Tons.</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}><Input type='number' onBlur={handleBlur} onFocus={handleSelectFocus} name='ReturnValue' value={formik.values.ReturnValue} style={{ textAlign: 'right' }} disabled className='form-control-sm'></Input></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <div className="table-responsive">
                                                <Table size="sm" className="m-0">
                                                    <thead>
                                                        <tr >
                                                            <th style={{ backgroundColor: 'white', color: 'white', fontSize: '16px' }}></th>
                                                            <th colSpan={4} style={{ backgroundColor: '#17a2b8', color: 'white', fontSize: '16px' }}>Datos adicionales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Hora de estadía</th>
                                                            <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                <Input onBlur={handleBlur} onFocus={handleSelectFocus}  type='number' onChange={formik.handleChange} value={formik.values.quantityHoursStays} name='quantityHoursStays' style={{ textAlign: 'right' }} className='form-control-sm'></Input>
                                                            </th>
                                                            <td><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' onChange={formik.handleChange} value={formik.values.rateHourStays} name='rateHourStays' style={{ textAlign: 'right' }} className='form-control-sm'></Input></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>$/Hora</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                <Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' value={formik.values.valueRateHourStays} name='valueRateHourStays' style={{ textAlign: 'right' }} disabled className='form-control-sm'></Input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Permiso circulación mallas e. Anchas</th>
                                                            <th style={{ textAlign: 'center', verticalAlign: 'middle' }}></th>
                                                            <td></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>$</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                <Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' name='drivingLicense' onChange={formik.handleChange} value={formik.values.drivingLicense} style={{ textAlign: 'right' }} className='form-control-sm'></Input>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>Retorno por tarifario/vacio</th>
                                                            <th style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                <select name='IsReturnEmpty' onChange={({ target }) => handleReturnEmpty(target.value)} className='form-control-sm' style={{ width: '100%' }}>
                                                                    <option selected={returnEmptyType == 1} value={1}>Si</option>
                                                                    <option selected={returnEmptyType == 0} value={0}>No</option>
                                                                    <option selected={returnEmptyType == 2} value={2}>Otro</option>
                                                                </select>
                                                            </th>
                                                            <td><Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' value={formik.values.returnEmptyRate} name='returnEmptyRate' onChange={formik.handleChange} style={{ textAlign: 'right' }} disabled={!IsReturnEmpty} className='form-control-sm'></Input></td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{ReturnSymbol}</td>
                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                                                <Input onBlur={handleBlur} onFocus={handleSelectFocus} type='number' value={formik.values.emptyReturnValue} name='emptyReturnValue' style={{ textAlign: 'right' }} disabled className='form-control-sm'></Input>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </Table>

                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <div className="table-responsive" >
                                                <Table size="sm" className="m-0">
                                                    <thead>
                                                        <tr >
                                                            <th style={{ textAlign: 'right', fontSize: '16px', width: '80%', verticalAlign: 'middle' }}>Total a pagar</th>
                                                            <th style={{ color: 'white', fontSize: '16px' }}><Input type='number' disabled value={formik.values.totalToPay} style={{ textAlign: 'right' }} className='form-control'></Input></th>
                                                            <th style={{ color: 'white', fontSize: '16px' }}><Button onClick={Calculate} color='primary'><i className='fa fa-calculator'></i></Button></th>
                                                        </tr>
                                                    </thead>
                                                </Table>

                                            </div>
                                        </Row>

                                        <hr></hr>
                                        <Row className='m-1'>
                                            <textarea rows={2} onChange={formik.handleChange} value={formik.values.observation} name='observation' placeholder="Observaciones" className='form-control'></textarea>
                                        </Row>
                                        <Row className='m-1'>
                                            <p>Para los siguientes casos deberán enviar vía email los documentos escaneados de respaldo:</p>
                                            <ul>
                                                <li>KM Adicionales (Recorrido Satelital)</li>
                                                <li>Estadías (Recorrido Satelital)</li>
                                                <li>Permisos por MS. Especiales Anchas (Permiso por carga especial)</li>
                                                <li>Devoluciones (Mail de autorización más ticket descarga de Acindar)</li>
                                            </ul>
                                        </Row>

                                        <div style={{ float: 'right' }}>
                                            <Button onClick={() => history.goBack()} color='link'>Volver</Button>{' '}
                                            <Button onClick={Reset} color='danger'><i className="mdi mdi-cancel me-2"></i> Resetear</Button>{' '}
                                            <Button color='success'><i className="mdi mdi-check me-2"></i> Guardar</Button>{' '}

                                        </div>

                                    </form>

                                </CardBody>

                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = state => ({
    users: state.Users?.users,
    prevalorization: state?.LoadPlan?.prevaluation 
});

const mapDispatchToProps = (dispatch) => {
    return {
        get: (id) => dispatch({ type: plansActionTypes.GET_PREVALUATION, payload: id }),
        save: (data) => dispatch({ type: boxActionTypes.SAVE, payload: data }),
        update: (data) => dispatch({ type: remittanceActionTypes.UPDATE_PREVALORIZATION, payload: data }),
        getUsers: (filter) => dispatch({ type: userActionTypes.GET_USERS, payload: filter }),
        savePrevalue: (data, histo) => dispatch({ type: remittanceActionTypes.SAVE_PREVALORIZATION, payload: data, callBack: histo }),
        updatePrevalue: (data, histo) => dispatch({ type: remittanceActionTypes.UPDATE_PREVALORIZATION, payload: data, callBack: histo })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);