import React from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import KeyIcon from "../Icons/KeyIcon";
import PencilIcon from "../Icons/PencilIcon";
import moment from "moment"
const UserColumns = (remove, handleSelectedUser, handleToggle, history, currentUserId) => [
  {
    text: '',
    formatter: (cell, row) => (
      
      <div className="btn-group  mt-md-0" size="sm" role="group">
        <Button style={{ padding: '0.5em' }} className="btn btn-light" title="Editar" onClick={() => history.push(`/user/edit/${row.id}`)} type="button" color="light"><PencilIcon width="20px" /></Button>
        <Button style={{ padding: '0.5em' }} className="btn btn-light" onClick={() => handleSelectedUser(row.id)} type="button" color="light" title="Regenerar contraseña"><KeyIcon width="22px"></KeyIcon></Button>
        <Button style={{ padding: '0.5em' }} className="btn btn-light" disabled={currentUserId === row.id} onClick={() => handleToggle(row.id, row.active)} type="button" color="light" title={`${row.active ? 'Activar' : 'Desactivar'} usuario`}><i className={`mdi mdi-account-${row.active ? 'off-' : ''}outline font-size-20 ${row.active ? 'text-success' : 'text-muted'}`}></i></Button>
        <Button hidden={row.id==currentUserId} style={{ padding: '0.5em' }} className="btn btn-light" disabled={currentUserId === row.id} onClick={() => remove(row.id)} type="button" title="Eliminar" color="light"><i className="mdi mdi-trash-can-outline font-size-20 text-danger"></i></Button>
      </div>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '8%', textAlign: 'center' };
    }
  },
  {
    dataField: "role.name",
    text: "Rol",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '20%' };
    }
  },
  {
    // dataField: "fullName",
    text: "Nombre",
    sort: true,
    formatter: (cell, row) =>`${row.firstName?row.firstName:""} ${row.lastName? ', '+row.lastName:""}` ,
    headerStyle: (colum, colIndex) => {
      return { width: '20%' };
    }
  },
  {
    text: "Email",
    dataField: "email",
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.email}</p>
      </>
    ),
    headerStyle: (colum, colIndex) => {
      return { width: '20%' };
    }
  },
  {
    dataField: "createdAt",
    text: "Registrado",
    sort: true,
    formatter: (cell,row)=>(moment(cell).format("DD-MM-YYYY")),
    headerStyle: (colum, colIndex) => {
      return { width: '10%' };
    }
  },
  {
    dataField: "lastLogin",
    text: "Últ. Acceso",
    sort: true,
    formatter: (cell,row)=>(cell?moment(cell).format("DD-MM-YYYY hh:mm"): '-:-'),
    headerStyle: (colum, colIndex) => {
      return { width: '10%', textAlign:'center'  };
    }
  },
  {
    dataField: "loginFailCount",
    text: "Accesos fallidos",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '10%' };
    }
  },
  {
    dataField: "resetPasswordCount",
    text: "Reseteos de contraseña",
    sort: true,
    headerStyle: (colum, colIndex) => {
      return { width: '10%' };
    }
  },
  {
    dataField: "activeDescription",
    text: "Activo",
    sort: true,
    formatter: (cell, row) => <p className="text-center"><i className={`mdi ${row.active? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline'} font-size-20 text-${row.isActive?'success':'warning'}`}></i></p>,
    headerStyle: (colum, colIndex) => {
      return { width: '10%', textAlign:'center' };
    }
  }
]

export default UserColumns
