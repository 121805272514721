import * as qs from "qs";
import * as api from "../../helpers/api_helper";
import { GET, GET_TYPES, GET_USERS, POST_USER, PUT, PUT_CHANGE_PASSWORD, PUT_TOGGLE, REMOVE,GET_COUNT } from "../../helpers/url_helper";

export const getUsers = (filter) => {
  
  return api.get( GET_USERS, {
    params: { ...filter },
    paramsSerializer: params => qs.stringify(params)
  });
};

export const getCount = (filter) => api.get(GET_COUNT, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});
export const get = (id) => api.get(`${GET}${id}`);
export const remove = (id) => api.del(`${REMOVE}${id}`);
export const postUser = (user) => api.post(POST_USER,user);
export const updateEntity = (user) => api.put(PUT,user);
export const changePassword = (password) => api.put(PUT_CHANGE_PASSWORD,password);
export const toggle = (id) => api.put(PUT_TOGGLE,id);
export const getTypes = () => api.get(GET_TYPES);