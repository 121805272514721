import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Carriers from './carrier/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';

import Users from "./users/reducer";
import Boxes from "./boxes/reducer";
import Inbound from "./Inbound/reducer";
import DocumentStates from "./documentState/reducer";
import LoadPlan from './LoadPlans/reducer'
import Remittance from './Remittance/reducer'
import Files from './Files/reducer'
import Transfer from './transfer/reducer'
import Notification from './notification/reducer'

const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    Account,
    Login,
    Users,
    Carriers,
    Boxes,
    Inbound,
    DocumentStates,
    LoadPlan,
    Remittance,
    Files,
    Transfer,
    Notification,
});

export default rootReducer;