import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as actions from "../../../store/Inbound/actions";
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Columns from './Columns';
import { useHistory } from "react-router-dom"

const List = ({ list, getAllTypes, updateType, remove }) => {
    const history = useHistory();
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Inbound", link: "#" },
        { title: "Parametrización", link: "#" },
    ])

    const pageOptions = {
        sizePerPage: 10,
        totalSize: list.length, // replace later with size(users),
        custom: true,
    }

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const { SearchBar } = Search

    const selectRow = {
        mode: 'checkbox'
    };




    const handleToggle = (id, field, value) => updateType({ id: id, [field]: value });

    useEffect(() => {
        getAllTypes();

        return () => {
        }
    }, [history])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="Tabla de parametrización" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        data={list}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={Columns(handleToggle)}
                                                data={list}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>

                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={true}
                                                                defaultSorted={defaultSorted}
                                                                selectRow={selectRow}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                            />

                                                        </div>
                                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    list: state.Inbound.types ?? []
});



export default connect(mapStateToProps, actions)(List);