import React from "react"
import { Link } from "react-router-dom"

const Columns = (remove) => [
  

  {
    dataField: "user.givenName",
    text: "Transportista / proveedor",
    sort: true,
  },
  {
    dataField: "user.email",
    text: "Email contacto",
    sort: true,
  },
  {
    dataField: "number",
    text: "Número",
    sort: true,

  },
  {
    dataField: "openingDateOnly",
    text: "Fecha alta",
    sort: true,

  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Acciones",
    formatter: (cellContent,row) => (
      <>
        <Link to={`/box/edit/${row.id}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
        <Link to="#" onClick={()=>remove(row.id)} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
      </>
    ),
  },
]

export default Columns
