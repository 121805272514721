
const Columns = (handleToggle) => [
  

  {
    dataField: "name",
    text: "Nombre",
    sort: true,
  },
  {
    dataField: "type",
    text: "Valor",
    sort: true,
  },
  {
    dataField: "inbound",
    text: "Inbound",
    sort: true,
    formatter: (cell,row,b)=> <input type="checkbox"  onClick={()=>handleToggle(row.id,"inbound",!cell)} defaultChecked={Boolean(cell)}></input>

  },
  {
    dataField: "outbound",
    text: "Outbound",
    sort: true,
    formatter: (cell,row,b)=> <input type="checkbox"  onClick={()=>handleToggle(row.id,"outbound",!cell)} defaultChecked={cell}></input>

  },
  {
    dataField: "sapInterface",
    text: "Interface SAP",
    sort: true,
    formatter: (cell,row,b)=> <input type="checkbox"  onClick={()=>handleToggle(row.id,"sapInterface",!cell)} defaultChecked={cell}></input>

  },
  {
    dataField: "otmInterface",
    text: "Interface OTM",
    sort: true,
    align:'center',
    formatter: (cell,row,b)=> <input type="checkbox"  onClick={()=>handleToggle(row.id,"otmInterface",!cell)} defaultChecked={cell}></input>

  }
]


export default Columns
