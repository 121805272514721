import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";



//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import FAQs from "../pages/Utility/FAQs";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";



//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//users
import Users from "../pages/Users/";
import Form from "../pages/Users/Form";

//boxxes
import Boxes from "../pages/Boxes/";
import BoxesForm from "../pages/Boxes/Form";

import UploadFileOtm from "../pages/UploadFileOtm";
import Inbound from "../pages/Inbound";
import InboundXlm from "../pages/Outbound/Transmissions";
import InboundParametrization from "../pages/Inbound/Parameterization";
import Outbound from "../pages/Outbound";
import OutboundFiles from '../pages/Outbound/Files'
import UploadOutbound from '../pages/Outbound/Upload';
import Prevalue from "../pages/Outbound/Prevalue";
import VisualControl from "../pages/Outbound/Control";
import Preview from "../pages/Outbound/Preview";

const authProtectedRoutes = [

	//users
	{path: "/user/add", component: Form, key:'add-users', userAllowed:'Admin'},
	{path: "/user/edit/:id", component: Form,key:'edit-users', userAllowed:'Admin'},
	{path: "/users", component: Users, key:'users', userAllowed:'Admin'},

	//Boxes
	{path: "/box/add", component: BoxesForm, key:'add-box', userAllowed:'Admin'},
	{path: "/box/edit/:id", component: BoxesForm ,key:'edit-box', userAllowed:'Admin'},
	{path: "/boxes", component: Boxes, key:'box', userAllowed:'Admin'},

	//upload otm
	{ path: "/upload/file/otm", component: UploadFileOtm },

	//Inbound
	{ path: "/inbound", component: Inbound },
	//Outbound 
	{ path: "/outbound/files/:pc", component: OutboundFiles },
	{ path: "/outbound/prevalue/:id/:pc", component: Prevalue },
	{ path: "/outbound/control/:id", component: VisualControl },
	{ path: "/outbound/preview/:remittanceId/:number/:pc/:automaticMode", component: Preview },
	{ path: "/outbound/upload", component: UploadOutbound },
	{ path: "/outbound", component: Outbound },
	//settings
	{ path: "/settings/transmissions", component: InboundXlm },
	{ path: "/settings/parameterization", component: InboundParametrization },

	// Tables
	{ path: "/basic-tables", component: BasicTables },
	{ path: "/datatable-table", component: DatatableTables },
	{ path: "/responsive-table", component: ResponsiveTables },
	{ path: "/editable-table", component: EditableTables },

	
	{ path: "/dashboard", component: Dashboard },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/lock-screen", component: AuthLockScreen },

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw", component: ForgetPwd1 },

	{ path: "/maintenance", component: Maintenance },
	{ path: "/comingsoon", component: CommingSoon },
	{ path: "/404", component: Error404 },
	{ path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
