import React from 'react';
import { Modal, ModalBody, ModalHeader } from "reactstrap"
const CustomModal = ({
    size,
    centered,
    isOpen,
    title,
    children,
    handleToggle

}) => {

    return (

        <Modal
            size={size}
            centered={centered ?? false}
            isOpen={isOpen}
            toggle={handleToggle}

        >
            <ModalHeader toggle={handleToggle}>
                {title}
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>

    )

}

export default CustomModal;