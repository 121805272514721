import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Alert, Button, ButtonDropdown, ButtonGroup, Card, CardBody, CardFooter, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as loadPlanActionTypes from "../../../store/LoadPlans/actionTypes";
import * as statesActionTypes from "../../../store/documentState/actionTypes";
import * as remittanceActionTypes from "../../../store/Remittance/actionTypes";
import * as filesActionTypes from "../../../store/Files/actionTypes";
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Columns from './Columns';
import { Link, useHistory, useParams } from "react-router-dom"
import { useFormik } from "formik"
import Loader from "../../../common/Loader"

const List = ({
    list,
    getAll,
    users,
    fetching,
    mustBeUpdated,
    getAllDocumentsSTates,
    documentStates,
    getPDF,
    PDF, loading,
    reject
}) => {
    const history = useHistory();
    const [ShowVisualControl, setShowVisualControl] = useState(false)
    const [ShowDisassociate, setShowDisassociate] = useState(false)
    const [ShowAssociateDocument, setShowAssociateDocument] = useState(false)
    const [ShowOpenDocument, setShowOpenDocument] = useState(false)
    const [SelectedDocumentId, setSelectedDocumentId] = useState(undefined)
    const [SelectedDocumentNumber, setSelectedDocumentNumber] = useState(undefined)
    let params = useParams();
    const pageOptions = {
        sizePerPage: 10,
        totalSize: list.length, // replace later with size(users),
        custom: true,
    }

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const { SearchBar } = Search

    const selectRow = {
        mode: 'checkbox'
    };

    useEffect(() => {

        getAll({ loadPlanId: params.pc })
        return () => {
        }
    }, [])


    useEffect(() => {
        mustBeUpdated && getAll({ ...formikFilter.values });
    }, [mustBeUpdated])



    useEffect(() => {
        getAllDocumentsSTates();

    }, [])


    const formikFilter = useFormik({
        initialValues: {},
        onSubmit: values => {
            getAll({ ...values })
        }
    });

    const handleReject = (id) => reject(id);

    const handleView = (remittanceId,number,pc,automaticMode) => {
        // getPDF({ route: 'remittance', data: `OUT-${id}` })
        // setSelectedDocumentId(id);
        // setShowVisualControl(true);
        history.push(`/outbound/preview/${remittanceId}/${number}/${pc}/${automaticMode?'A':'M'}`)
    }

    const handleDisassociate = (id) => {
        setSelectedDocumentId(id);
        setShowDisassociate(true)
    }
    const handleAssociate = (documentNumber) => {
        setSelectedDocumentNumber(documentNumber);
        setShowAssociateDocument(true)
    }

    const handleOpen = (id) => {
        setSelectedDocumentId(id);
        setShowOpenDocument(true)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{`Outbound`}</h4>

                                <div className="page-title-right">
                                    <Link className='btn btn-light' to={"/outbound"}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}
                                    <Button color='success'><i className="mdi mdi-folder-download-outline"></i> Descargar</Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                  
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField='id'
                                        data={list}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField='id'
                                                columns={Columns(handleView, null, null, handleOpen)}
                                                data={list}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col sm="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar
                                                                            placeholder="Buscar"
                                                                            className="input-sm"
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                           
                                                        </Row>

                                                        <Loader isLoading={fetching}>
                                                            <div className="table-responsive">

                                                                <BootstrapTable

                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    // selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                />

                                                            </div>
                                                            <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Loader>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <Modal
                size="lg"
                centered={true}
                isOpen={ShowAssign}
                toggle={() => setShowAssign(!ShowAssign)}

            >
                <ModalHeader toggle={() => setShowAssign(!ShowAssign)}>
                    Asociar documento
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>

                                <Row>
                                    <div className="text-sm-end block-inline">
                                        <InputGroup>
                                            <Input
                                                onChange={(e)=>setDocumentNro(e.target.value)}
                                                size='sm'
                                                name="DocumentNro"
                                                id="DocumentNro" />
                                            <Button className="btn btn-sm btn-success" onClick={()=>getByDocument({documentNumber:DocumentNro})} >Agregar</Button>
                                        </InputGroup>

                                    </div>

                                </Row>
                                <hr></hr>
                                <Row>
                                    <div className="table-responsive">
                                        <Table className="mb-0">

                                            <thead>
                                                <tr>
                                                    <th>Nro IRCH</th>
                                                    <th>Remito</th>
                                                    <th>N° P insumo	</th>
                                                    <th>Ticket</th>
                                                    <th>Peso</th>
                                                    <th>Fecha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                               {selected && selected.map(row=>(
                                                <tr>
                                                    <td>{row.documentNumber}</td>
                                                    <td>{row.remittanceNumber}</td>
                                                    <td>{row.providerCode}</td>
                                                    <td>{row.ticket}</td>
                                                    <td>{row.totalWeight}</td>
                                                    <td>{moment(row.dateSM).format("dd-mm-YYYY")}</td>
                                                </tr>
                                               ))}                    
                                            </tbody>
                                        </Table>
                                    </div>

                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-end block-inline">
                                        <Button className="btn btn-sm" color="link" onClick={() => setShowAssign(false)}>Cerrar</Button> {' '}
                                        <Button className="btn btn-sm" color="primary" onClick={() => setShowAssign(false)} >Si, Asociar</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal> */}

            <Modal
                size="xl"
                centered={true}
                isOpen={ShowVisualControl}
                toggle={() => setShowVisualControl(!ShowVisualControl)}
                fade={false}
            >
                <ModalHeader toggle={() => setShowVisualControl(!ShowVisualControl)}>
                    Control visual
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={8}>
                                        {
                                            loading ? <Spinner ></Spinner> :
                                                PDF ? <iframe itemType="pdf" width="100%" height="500px" src={`${PDF}`} ></iframe>
                                                    :
                                                    <div className="text-center">
                                                        <Alert color="warning"> Archivo no encontrado </Alert>
                                                    </div>
                                        }

                                    </Col>
                                    <Col sm={4}>
                                        <Row>
                                            <Alert color="info"> El transporte no agrego ningún comentario </Alert>
                                            {/* <textarea style={{ "height": "60%" }} className="form-control" rows={3}></textarea> */}
                                        </Row>

                                        {/* <Row>

                                            {ShowAddObservation && <>
                                                <hr></hr>
                                                <select onChange={(x) => setReason(x.target.value)} className="form-control mb-3">
                                                    <option selected value={0}> Asignación incorrecta</option>
                                                    <option value={1}> Inconsistencia en documentos</option>
                                                </select>
                                                <textarea onChange={x => setReasonDescription(x.target.value)} style={{ "height": "100%" }} className="form-control" rows={5}></textarea>
                                            </>
                                            }
                                        </Row> */}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-end block-inline">
                                        <Button disabled={false} className="btn btn-sm" color="link" onClick={() => setShowVisualControl(false)}>Cerrar</Button> {' '}
                                        <Button disabled={false} className="btn btn-sm" color="danger" onClick={() => handleReject(SelectedDocumentId)} >Devolución</Button>{' '}
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal>

            <Modal
                key={"disassociate"}
                size="sm"
                fade={false}
                centered={true}
                isOpen={ShowDisassociate}
                toggle={() => setShowDisassociate(!ShowDisassociate)}

            >
                <ModalHeader toggle={() => setShowDisassociate(!ShowDisassociate)}>
                    Desasociar documento
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Realmente desea desasociar el documento: </p>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-center block-inline">
                                        <Button className="btn" color="link" onClick={() => setShowDisassociate(false)}>Cerrar</Button> {' '}
                                        <Button className="btn" color="danger" onClick={() => {
                                            setShowDisassociate(false)
                                        }} >Si, Desasociar</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal>

            <Modal
                size="sm"
                fade={false}
                centered={true}
                isOpen={ShowAssociateDocument}
                toggle={() => setShowAssociateDocument(!ShowAssociateDocument)}

            >
                <ModalHeader toggle={() => setShowAssociateDocument(!ShowAssociateDocument)}>
                    Asociar documento
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Asociar documento: {SelectedDocumentNumber} ? </p>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-center block-inline">
                                        <Button className="btn" color="link" onClick={() => setShowAssociateDocument(false)}>Cerrar</Button> {' '}
                                        <Button className="btn" color="success" onClick={() => {
                                            setShowAssociateDocument(false)
                                        }} >Si, Asociar</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal>


            <Modal
                key={"open"}
                size="sm"
                fade={false}
                centered={true}
                isOpen={ShowOpenDocument}
                toggle={() => setShowOpenDocument(!ShowOpenDocument)}

            >
                <ModalHeader toggle={() => setShowOpenDocument(!ShowOpenDocument)}>
                    Abrir documento
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Realmente desea abrir el documento: {SelectedDocumentNumber} </p>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-center block-inline">
                                        <Button className="btn" color="link" onClick={() => setShowOpenDocument(false)}>Cerrar</Button> {' '}
                                        <Button className="btn" color="danger" onClick={() => {
                                            setShowOpenDocument(false)
                                        }} >Si, Abrir</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    list: state.Remittance.list?.data??[],
    PDF: state.Files.base64,
    loading: state.Files.loading,
    selected: state.Inbound.entity,
    users: state.Users?.users,
    fetching: state.Inbound?.fetching,
    mustBeUpdated: state.Inbound?.mustBeUpdated,
    documentStates: state.DocumentStates?.list
});


const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (filter) => dispatch({ type: remittanceActionTypes.GET_ALL, payload: filter }),
        getPDF: ({ route, data }) => dispatch({ type: filesActionTypes.GET, payload: { route, data } }),
        getAllDocumentsSTates: (filter) => dispatch({ type: statesActionTypes.GET_ALL, payload: filter }),
        reject: (data) => dispatch({ type: remittanceActionTypes.REJECT, payload: data }),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(List);