import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Ecommerce Redux States
import {
  CHANGE_PASSWORD,
  GET,
  GET_COUNT,
  GET_USERS, GET_USER_TYPES, REMOVE, SAVE, TOGGLE, UPDATE
} from "./actionTypes"
import {
  getFail,
  getSuccess,
  getTypesFail,
  getTypesSuccess,
  getUsersFail,
  getUsersSuccess,
  removeFail,
  removeSuccess,
  saveUserFail,
  saveUserSuccess,
  updateSuccess,
  updateFail,
  changePasswordFail,
  changePasswordSuccess,
  toggleSuccess,
  getCountSuccess,

} from "./actions"
import { changePassword, get, getCount, getTypes, getUsers, postUser, remove, toggle, updateEntity } from "./Service"
import toast from 'react-hot-toast';


function* fetchCount({ payload }) {
  try {
    const response = yield call(getCount, payload)
    yield put(getCountSuccess(response))
  } catch (error) {
  }
}

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsers, payload)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchEntity({ payload }) {
  try {
    const response = yield call(get, payload)
    yield put(getSuccess(response))
  } catch (error) {
    yield put(getFail(error))
  }
}

function* saveUser(data) {
  try {
    const response = yield call(postUser, data.payload)
    yield put(saveUserSuccess(response))
    toast.success("Usuario agregado correctamente");
  } catch (error) {
    yield put(saveUserFail(error.response?.data?.errors))
  }
}

function* updateuser(data) {
  try {
    const response = yield call(updateEntity, data.payload)
    yield put(updateSuccess(response))
    toast.success("Usuario actualizado");
  } catch (error) {
    yield put(updateFail('Error inesperado.'))
  }
}

function* updatePassword(data) {
  try {
    const response = yield call(changePassword, data.payload)
    yield put(changePasswordSuccess(response))
    toast.success("Contraseña actualizada");
  } catch (error) {
    yield put(changePasswordFail(error.response?.data?.errors))
  }
}

function* updateToggle(data) {
  try {
    const response = yield call(toggle, data.payload)
    yield put(toggleSuccess(response))
    toast.success("Usuario actualizado");
  } catch (error) {
    yield put(toggleSuccess(error.response?.data))
    toast.error(error.response?.data)
  }
}

function* fetchTypes() {
  try {
    const response = yield call(getTypes)
    yield put(getTypesSuccess(response))
  } catch (error) {
    yield put(getTypesFail(error))
  }
}

function* removeEntity({ payload }) {
  try {
    const response = yield call(remove, payload)
    yield put(removeSuccess(response))
    toast.success("Usuario eliminado correctamente");
  } catch (error) {
    yield put(removeFail(error))
  }
}
function* usersSaga() {
  yield takeEvery(REMOVE, removeEntity)
  yield takeEvery(GET, fetchEntity)
  yield takeLatest(GET_USERS, fetchUsers)
  yield takeEvery(GET_COUNT, fetchCount)
  yield takeEvery(SAVE, saveUser)
  yield takeEvery(UPDATE, updateuser)
  yield takeEvery(GET_USER_TYPES, fetchTypes)
  yield takeEvery(CHANGE_PASSWORD, updatePassword)
  yield takeEvery(TOGGLE, updateToggle)
}

export default usersSaga
