import {
  GET_FAIL,
  GET_SUCCESS,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  SAVE_FAIL,
  REMOVE,
  REMOVE_FAIL,
  REMOVE_SUCCESS,
  SAVE_SUCCESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  GET_ALL,
  SAVE,
  UPDATE,
  GET_BY_USER,
  GET_BY_USER_SUCCESS,
  GET_BY_USER_FAIL,

} from "./actionTypes"

const INIT_STATE = {
  list: [],
  entity: undefined,
  types: [],
  error: {},
  isLoading:false,
  fetching: false,
  mustBeUpdated: false,
}

const Box = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL:
      return {
        ...state,
        isloading:true,
        fetching: true,
        mustBeUpdated: false,
      }

    case GET_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isloading:false,
        fetching: false,
        mustBeUpdated: false,
      }

    case GET_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        mustBeUpdated: false,
      }

    case GET_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        fetching: false,
        mustBeUpdated: false,
      }

    case GET_FAIL:
      return {
        ...state,
        error: action.payload,
      }


      case GET_BY_USER:
      return {
        ...state,
        entity: undefined,
        fetching: true,
      }

      case GET_BY_USER_SUCCESS:
        return {
          ...state,
          entity: action.payload,
          fetching: false,
          error: undefined
        }

    case GET_BY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        fetching: false
      }

    case REMOVE_SUCCESS:
      return {
        ...state,
        entity: action.payload,
      }

    case REMOVE_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case SAVE:
      return {
        ...state,
        error: {},
        result: action.payload,
        isloading:true,
        mustBeUpdated:false,
      }
    case SAVE_SUCCESS:
      return {
        ...state,
        error: {},
        result: action.payload,
        isloading:false,
        mustBeUpdated:true,
      }

    case SAVE_FAIL:
      return {
        ...state,
        error: action.payload,
        isloading:false,
        mustBeUpdated:false,
      }

      case UPDATE:
        return {
          ...state,
          error: {},
          result: action.payload,
          isloading:true,
          mustBeUpdated:false,
        }
      case UPDATE_SUCCESS:
        return {
          ...state,
          error: {},
          result: action.payload,
          isloading:false,
          mustBeUpdated:true,
        }
  
      case UPDATE_FAIL:
        return {
          ...state,
          error: action.payload,
          isloading:true,
          mustBeUpdated:false,
        }
  
    default:
      return state
  }
}

export default Box
