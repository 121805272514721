import {
  GET_FAIL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  GET_ALL,
  GET,
  GET_SUCCESS

} from "./actionTypes"

const INIT_STATE = {
  list: [],
  entity: undefined,
  types: [],
  error: {},
  fetching: false,
  mustBeUpdated: false,
  base64:null,
}

const Files = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET:
      return{
        ...state,
        fetching:true,
        base64:null
      }
      case GET_SUCCESS:
        return{
          ...state,
          fetching:false,
          base64:action.payload
        }
        case GET_FAIL:
          return{
            ...state,
            fetching:false,
            base64:null
          }
    case GET_ALL:
      return {
        ...state,
        list: [],
        fetching: true,
        mustBeUpdated: false,

      }

    case GET_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        mustBeUpdated: false,
      }

    case GET_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
        fetching: false,
      }

    case GET_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

    default:
      return state
  }
}

export default Files
