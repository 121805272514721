import {
  GET,
  GET_FAIL,
  GET_SUCCESS,
  GET_ALL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  REJECT,
  REJECT_SUCCESS,
  REJECT_FAIL,
  UPLOAD,
  UPLOAD_SUCCESS,
  UPLOAD_FAIL,
  SAVE_PREVALORIZATION,
  SAVE_PREVALORIZATION_SUCCESS,
  SAVE_PREVALORIZATION_FAIL,
  GET_PREVALORIZATION,
  GET_PREVALORIZATION_SUCCESS,
  GET_PREVALORIZATION_FAIL,
  UPDATE_PREVALORIZATION,
  UPDATE_PREVALORIZATION_SUCCESS,
  UPDATE_PREVALORIZATION_FAIL,
  UPLOAD_AUTOMATIC_SUCCESS,
  UPLOAD_AUTOMATIC_FAIL
} from "./actionTypes"

export const getAll = (payload) => ({
  type: GET_ALL,
  payload
})

export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})

export const getPrevalorization = (payload) => ({
  type: GET_PREVALORIZATION,
  payload
})

export const getPrevalorizationSuccess = list => ({
  type: GET_PREVALORIZATION_SUCCESS,
  payload: list,
})

export const getPrevalorizationFail = error => ({
  type: GET_PREVALORIZATION_FAIL,
  payload: error,
})


export const updatePrevalorization = (payload) => ({
  type: UPDATE_PREVALORIZATION,
  payload
})

export const updatePrevalorizationSuccess = result => ({
  type: UPDATE_PREVALORIZATION_SUCCESS,
  payload: result,
})

export const updatePrevalorizationFail = error => ({
  type: UPDATE_PREVALORIZATION_FAIL,
  payload: error,
})
export const get = (id) => ({
  type: GET,
  payload: id,
})

export const getSuccess = entity => ({
  type: GET_SUCCESS,
  payload: entity,
})

export const getFail = error => ({
  type: GET_FAIL,
  payload: error,
})

export const reject = (id) => ({
  type: REJECT,
  payload: id
})

export const rejectSuccess = result => ({
  type: REJECT_SUCCESS,
  payload: result,
})

export const rejectFail = error => ({
  type: REJECT_FAIL,
  payload: error,
})


export const uploadSuccess = result => ({
  type: UPLOAD_SUCCESS,
  payload: result,
})

export const uploadFail = error => ({
  type: UPLOAD_FAIL,
  payload: error,
})

export const uploadAutoSuccess = result => ({
  type: UPLOAD_AUTOMATIC_SUCCESS,
  payload: result,
})

export const uploadAutoFail = error => ({
  type: UPLOAD_AUTOMATIC_FAIL,
  payload: error,
})

export const savePrevalorization = (entity) => ({
  type: SAVE_PREVALORIZATION,
  payload: entity
})

export const savePrevalorizationSuccess = result => ({
  type: SAVE_PREVALORIZATION_SUCCESS,
  payload: result,
})

export const savePrevalorizationFail = error => ({
  type: SAVE_PREVALORIZATION_FAIL,
  payload: error,
})