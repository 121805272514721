import React from 'react';

const Premission = ({ userType, userTypeAllowed, children }) => {

    return (
        userTypeAllowed.some(x=> x === userType) &&  children 
    )

}

export default Premission;