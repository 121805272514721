import {
  GET_CARRIERS,
  GET_CARRIERS_FAIL,
  GET_CARRIERS_SUCCESS,
} from "./actionTypes"

export const getCarriers = () => ({
  type: GET_CARRIERS,
})

export const getCarriersSuccess = users => ({
  type: GET_CARRIERS_SUCCESS,
  payload: users,
})

export const getCarriersFail = error => ({
  type: GET_CARRIERS_FAIL,
  payload: error,
})


// export const saveUser = (user) => ({
//   type: SAVE_USERS,
//   payload:user
// })

// export const saveUserSuccess = result => ({
//   type: SAVE_USERS_SUCCESS,
//   payload: result,
// })

// export const saveUserFail = error => ({
//   type: SAVE_USERS_FAIL,
//   payload: error,
// })


// // USERTYPE
// export const getTypes = () => ({
//   type: GET_USER_TYPES,
// })

// export const getTypesSuccess = users => ({
//   type: GET_USER_TYPES_SUCCESS,
//   payload: users,
// })

// export const getTypesFail = error => ({
//   type: GET_USER_TYPES_FAIL,
//   payload: error,
// })