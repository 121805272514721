import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input, Button, Alert, CardHeader, Spinner } from "reactstrap";
import { useFormik, Field } from 'formik';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import * as actions from "../../../store/users/actions";
import * as userActionTypes from "../../../store/users/actionTypes";
import * as carrierActionType from "../../../store/carrier/actionTypes";
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Validations from './Validations';

const Form = ({
    saveUser,
    update,
    getTypes,
    getUser,
    result,
    error,
    roles,
    entity,
    getUsers,
    saving,
    saved }) => {
    let params = useParams();
    const history = useHistory();
    const [IsCarrier, setIsCarrier] = useState(false)
    const [SelectedUser, setSelectedUser] = useState();
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Usuarios", link: "/users" },
        { title: "Nuevo", link: "#" },
    ])

    const formik = useFormik({
        initialValues: params.id ? { ...entity } : {typeId:0},
        onSubmit: values => {
            if (params.id)
                update({...values,id: params.id})
            else {
                if (values.confirmPassword !== values.password) {
                    toast.error("Las contraseñas no coinciden.");
                } else
                    saveUser(values);
            }
        },
        enableReinitialize: params.id,
    });

    const changeRole = (value) => {
        formik.setFieldValue("roleId", roles.find(rol=>rol.id == value)?.id??0)
        if (roles.find(rol=>rol.id == value)?.name == "Transportista" ) 
            setIsCarrier(true);
        else
            setIsCarrier(false);
    };
    useEffect(() => {
        getTypes();
        if (params.id) {
            getUser(params.id);
        }

        return () => {
            formik.resetForm();
            setIsCarrier(false);
        }
    }, [])

    useEffect(() => {
        if (saved) {
            getUsers();
            history.goBack();
        }
    }, [saved])

    //entidad cargada
    useEffect(() => {
        changeRole(entity?.role?.id)
        return () => {
        }
    }, [entity])

    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>

                <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{`Usuarios`}</h4>

                                <div className="page-title-right">
                                    <Link className='btn btn-light' to={"/users"}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col xs={12}>

                            <Card>
                                <CardBody>
                   
                                    <form onSubmit={formik.handleSubmit}>
                                        <Row className="mb-3">
                                            <Label htmlFor="Name" className="col-md-2 col-form-label">Nombre</Label>
                                            <Col md={4}>
                                                <Input
                                                    id='Name'
                                                    name="givenName"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.givenName} />
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Label htmlFor="LastName" className="col-md-2 col-form-label">Apellido</Label>
                                            <Col md={4}>
                                                <Input
                                                    id='familyName'
                                                    name="familyName"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.familyName} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="Email" className="col-md-2 col-form-label">Email</Label>
                                            <Col md={4}>
                                                <Input
                                                    disabled={entity?.role?.name=='Admin' && params.id? true: false}
                                                    id='Email'
                                                    name="email"
                                                    type="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.email} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="Email" className="col-md-2 col-form-label">Usuario</Label>
                                            <Col md={2}>
                                                <Input
                                                    disabled={entity?.role?.name=='Admin'  && params.id ? true: false}
                                                    id='userName'
                                                    name="username"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.username} />
                                            </Col>
                                            <small>*Usuario para acceder a la plataforma</small>
                                        </Row>
                                        {!params.id &&
                                            <>
                                                <Row className="mb-3">
                                                    <Label htmlFor="Password" className="col-md-2 col-form-label">Contraseña</Label>
                                                    <Col md={3}>
                                                        <Input
                                                        autoComplete="new-password"
                                                            id='Password'
                                                            name="password"
                                                            type="password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values?.password} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="ConfirmPassword" className="col-md-2 col-form-label">Confirme Contraseña</Label>
                                                    <Col md={3}>
                                                        <Input
                                                        autoComplete="new-password"
                                                            id='ConfirmPassword'
                                                            name="confirmPassword"
                                                            type="password"
                                                            onChange={formik.handleChange}
                                                            value={formik.values?.confirmPassword} />
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <Row className="mb-3">
                                            <Label className="col-md-2 col-form-label">Rol</Label>
                                            <Col md={3}>
                                                <select
                                                 disabled={entity?.role?.name=='Admin' && params.id? true: false}
                                                  onChange={(e) => changeRole(e.target.value)} name='roleId' className="form-control">
                                                    
                                                    <option>Seleccione un rol</option>
                                                    {roles && roles.map(x => (<option selected={x.id == formik.values?.roleId} value={x.id}>{x.name}</option>))}
                                                </select>
                                            </Col>
                                        </Row>
                                        {IsCarrier &&
                                            <>
                                                <Row className="mb-3">
                                                    <Label htmlFor="Name" className="col-md-2 col-form-label">Número de transporte</Label>
                                                    <Col md={4}>
                                                        <Input
                                                            id='carrierNumber'
                                                            name="carrierNumber"
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            value={formik.values?.carrierNumber} />
                                                    </Col>
                                                </Row>
                                            </>}


                                        <FormGroup row className="mb-2 mt-2">
                                            <Label className="col-md-2 col-form-label"></Label>
                                            <Col md={10}>
                                                <div className="form-check form-switch mb-3" dir="ltr">
                                                    <Input type="checkbox" className="form-check-input" id="customSwitch1" defaultChecked />
                                                    <Label className="form-check-label" htmlFor="customSwitch1" onClick={(e) => null}>Activo</Label>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    {error && <Validations errors={error} />}
                                        <div style={{ float: 'right' }}>
                                            <Button onClick={() => history.goBack()} color='link'>Volver</Button>{' '}
                                            <Button disabled={saving ?? false} type='submit' color='primary'>{saving ? <Spinner size='sm'></Spinner> : 'Guardar'}</Button>

                                        </div>

                                    </form>
                                </CardBody>

                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = state => ({
    result: state.Users.result,
    error: state.Users?.error,
    roles: state.Users?.types,
    entity: state.Users?.entity,
    carriers: state.Carriers?.entities,
    saving: state.Users?.saving,
    saved: state.Users?.saved,
});

const mapDispatchToProps = (dispatch) => {
    return {
        saveUser: (data) => dispatch({ type: userActionTypes.SAVE, payload: data }),
        update: (data) => dispatch({ type: userActionTypes.UPDATE, payload: data }),
        getUser: (id) => dispatch({ type: userActionTypes.GET, payload: id }),
        getUsers: () => dispatch({ type: userActionTypes.GET_USERS }),
        getTypes: () => dispatch({ type: userActionTypes.GET_USER_TYPES }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);