import {
  GET_CARRIERS_FAIL,
  GET_CARRIERS_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  entities: [],
  error: {},
}

const Carriers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CARRIERS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
      }

    case GET_CARRIERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // case SAVE_USERS_SUCCESS:
    //   return {
    //     ...state,
    //     error: {},
    //     result: action.payload,
    //   }

    // case SAVE_USERS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }
    //   case GET_USER_TYPES_SUCCESS:
    //     return {
    //       ...state,
    //       types: action.payload,
    //     }
  
    //   case GET_USER_TYPES_FAIL:
    //     return {
    //       ...state,
    //       error: action.payload,
    //     }
    default:
      return state
  }
}

export default Carriers
