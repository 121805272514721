import React from 'react';

const DollarIcon = ({width='16px'}) => {

    return(

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        fill="#26ad01"
        stroke="#26ad01"
        version="1.1"
        viewBox="0 0 42.063 42.063"
        xmlSpace="preserve"
      >
        <path d="M42.063 37.588V0H4.477A4.476 4.476 0 000 4.476v37.587h37.586a4.477 4.477 0 004.477-4.475zm-19.172-4.817s.033.53.078 1.183c.043.654-.757 1.184-1.784 1.184-1.029 0-1.838-.513-1.805-1.144l.058-1.144c-1.421-.158-2.987-.711-4.307-1.863-1.109-.963-.784-2.684.311-3.452l1.976-1.399c.352 1.438 2.02 2.602 3.456 2.641.931.074 1.938-.312 2.367-1.282.504-1.243-.116-2.679-1.979-3.489-3.919-1.748-7.915-2.722-7.915-7.993 0-4.001 1.862-6.368 5.937-6.794l-.059-1.144c-.034-.637.775-1.146 1.804-1.146 1.028 0 1.838.501 1.806 1.122l-.06 1.127a7.576 7.576 0 013.471 1.016c1.271.745 1.21 2.556.236 3.564l-1.767 1.822c-.35-1.435-1.903-2.328-3.261-2.328-1.512-.038-2.988.854-2.91 2.563 0 2.908 3.57 3.413 5.704 4.306 2.795 1.161 4.54 3.182 4.464 6.439-.038 3.183-2.252 6.094-5.821 6.211z"></path>
      </svg>

    )

}

export default DollarIcon;