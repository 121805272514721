import {
  GET_ALL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,

} from "./actionTypes"

export const getAll = () => ({
  type: GET_ALL,
})

export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})
