import {
  GET_FAIL,
  GET_SUCCESS,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  SAVE_FAIL,
  SAVE_SUCCESS,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  DISSASOCIATE,
  DISSASOCIATE_SUCCESS,
  DISSASOCIATE_FAIL,
  GET_XML_TRANSSMITIONS_SUCCESS,
  GET_XML_TRANSSMITIONS_FAIL,
  TYPES_SUCCESS,
  TYPES_FAIL,
  UPDATE_TYPES_SUCCESS,
  UPDATE_TYPES_FAIL,
  GET_ALL,
  GET_BY_DOCUMENT_NUMBER,
  GET_BY_DOCUMENT_NUMBER_FAIL,
  GET_BY_DOCUMENT_NUMBER_SUCCESS,
  ASSOCIATE,
  ASSOCIATE_SUCCESS,
  ASSOCIATE_FAIL,
  OPEN,
  OPEN_SUCCESS,
  OPEN_FAIL,
  REJECT,
  REJECT_SUCCESS,
  REJECT_FAIL,
  SET_FILTER,
  CLEAR_FILTER

} from "./actionTypes"

const INIT_STATE = {
  list: [],
  xls: [],
  entity: undefined,
  types: [],
  error: {},
  fetching: false,
  mustBeUpdated: false,
  opening: false,
  rejecting:false,
  rejected: false,
  filter: undefined
}

const Inbound = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      }

      case CLEAR_FILTER:
        return {
          ...state,
          filter: undefined,
        }

    case GET_ALL:
      return {
        ...state,
        list: [],
        fetching: true,
        mustBeUpdated: false,
        rejected: false

      }

    case GET_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
        fetching: false,
        mustBeUpdated: false,
        rejected: false
      }

      

    case GET_ALL_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
        fetching: false,
      }
    case GET_XML_TRANSSMITIONS_SUCCESS:
      return {
        ...state,
        xls: action.payload,
        mustBeUpdated: false,
        fetching: false,
      }

    case GET_XML_TRANSSMITIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

    case TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
        mustBeUpdated: false,
      }

    case TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }
    case UPDATE_TYPES_SUCCESS:
      return {
        ...state,
        mustBeUpdated: false,
      }

    case UPDATE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

    case GET_BY_DOCUMENT_NUMBER:
      return {
        ...state,
        fetching: true,
      }

    case GET_BY_DOCUMENT_NUMBER_FAIL:
      return {
        ...state,
        fetching: false,
      }

    case GET_BY_DOCUMENT_NUMBER_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        fetching: false,
      }

    case GET_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

    case SAVE_SUCCESS:
      return {
        ...state,
        error: {},
        result: action.payload,
        mustBeUpdated: true,
      }

    case SAVE_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }

    case UPDATE_SUCCESS:
      return {
        ...state,
        error: {},
        result: action.payload,
        mustBeUpdated: true,
      }

    case UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
        mustBeUpdated: false,
      }
    case DISSASOCIATE:
      return {
        ...state,
        fetching: true,
        error: {},
        result: action.payload,
      }
    case DISSASOCIATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: {},
        result: action.payload,
        mustBeUpdated: true,
      }

    case DISSASOCIATE_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        mustBeUpdated: false,
      }

    case ASSOCIATE:
      return {
        ...state,
        fetching: true,
        error: {},
        result: action.payload,
      }
    case ASSOCIATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: {},
        result: action.payload,
        mustBeUpdated: true,
      }

    case ASSOCIATE_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        mustBeUpdated: false,
      }

    case OPEN:
      return {
        ...state,
        fetching: true,
        opening: true,
        error: {}
      }
    case OPEN_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: {},
        result: action.payload,
        opening: false,
        mustBeUpdated: true,
      }

    case OPEN_FAIL:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        mustBeUpdated: false,
        opening: false,
      }

      case REJECT:
        return {
          ...state,
          fetching: true,
          rejecting: true,
          error: {},
          rejected: false
        }
      case REJECT_SUCCESS:
        return {
          ...state,
          fetching: false,
          error: {},
          result: action.payload,
          rejecting: false,
          rejected: true,
          mustBeUpdated: true,
        }
  
      case REJECT_FAIL:
        return {
          ...state,
          fetching: false,
          error: action.payload,
          mustBeUpdated: false,
          rejected: false,
          rejecting: false,
        }
    default:
      return state
  }
}

export default Inbound
