let entity = "remittance"
export const GET_ALL =  `${process.env.REACT_APP_URL}${entity}`
export const GET_PREVALORIZATION =  `${process.env.REACT_APP_URL}${entity}/prevalorization`
// export const GET_TYPES =  `${process.env.REACT_APP_URL}${entity}/types`
export const GET =  `${process.env.REACT_APP_URL}${entity}?id=`
export const PUT_REJECT =  `${process.env.REACT_APP_URL}${entity}/reject`
export const UPLOAD =  `${process.env.REACT_APP_URL}${entity}/upload/manual`
export const UPLOAD_AUTOMATIC_URL =  `${process.env.REACT_APP_URL}${entity}/upload/auto`
export const SAVE_PREVALORIZATION_URL =  `${process.env.REACT_APP_URL}LoadPlan/prevaluation`
export const UPDATE_PREVALORIZATION_URL =  `${process.env.REACT_APP_URL}LoadPlan/updatePrevalorization`
// export const REMOVE =  `${process.env.REACT_APP_URL}${entity}?id=`
// export const POST =  `${process.env.REACT_APP_URL}${entity}/`
// export const PUT =  `${process.env.REACT_APP_URL}${entity}/`
// export const PUT_TOGGLE =  `${process.env.REACT_APP_URL}${entity}/type/toggle`
// export const DOWNLOAD =  `${process.env.REACT_APP_URL}${entity}/download`