import { Button } from "reactstrap"
import TransferIcon from '../../Icons/Transfer'
import moment from "moment"

const Columns = (retransfer) => [
  
  {
    style: { width: '5%'},
    dataField: "actions",
    text: "",
    sort: true,
    formatter: (col,row)=> <>
    <Button onClick={()=>retransfer([row.id])} title="Re-Transferir archivo " color="light"><TransferIcon /></Button></>
  },
  {
    style: { width: '15%'},
    text: "Cód. Proveedor",
    sort: true,
    formatter: (col,row)=><>{row.carrier?.carrierNumber }</>
  },
  {
    style: { width: '30%'},
    text: "Transportista / Proveedor",
    sort: true,
    formatter: (col,row)=><>{row.carrier?.givenName } {row.carrier?.familyName} </>
  },
  {
    style: { width: '10%'},
    dataField: "loadPlanNumber",
    text: "# Plan de carga",
    sort: true,
  },
  {
    style: { width: '10%'},
    dataField: "invoiceOTM",
    text: "Invoice OTM",
    sort: true,

  },
  {
    style: { width: '10%'},
    dataField: "transferredOn",
    text: "Fecha",
    sort: true,
    formatter: (cellContent, row) => moment(cellContent).format("DD-MM-YYYY")

  },
  {
    style: { width: '10%'},
    dataField: "reTransfered",
    text: "Retransmición",
    sort: true,
    formatter: (cellContent, row) => cellContent? moment(cellContent).format("DD-MM-YYYY"):' - / - / -'

  },
  {
    style: { width: '10%'},
    dataField: "times",
    text: "Veces R.",
    sort: true,

  }
]

export default Columns
