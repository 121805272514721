import moment from "moment"
import React from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import PdfIcon from "../../Icons/PdfIcon"
import DangerIcon from "../../Icons/DangerIcon"

const Columns = (view, disassociate, associate, open) => [

  {
    dataField: "menu",
    isDummyField: true,
    text: "Acciones",
    style: (ro,row) => ({ borderLeft: `${row.rejected && '2px'} solid red` }),
    formatter: (cellContent, row) => {
      return (
        <>
          <div className="btn-group mt-4 mt-md-0" size="sm" role="group" aria-label="Basic example">
            <Button
              title={row.fileName === '' ? 'No se ha asociado ningún documento para este remito' : 'Ver documento asociado'}
              disabled={row.fileName === '' ?? false}
              style={{ padding: '0.5em' }} onClick={() => { view(row.id, row.number, row?.loadPlanId, row.automaticMode) }} type="button" color="light"><PdfIcon width="20px" /></Button>
              {row.rejected &&<Button title={`Motivo de devolución: ${row?.rejectionObservation}`}  type="button" color="light"><DangerIcon size="20px"/></Button>}
          </div>
        </>
      )
    },
  },
  {
    dataField: "loadPlanNumber",
    text: "P. Carga",
    sort: true,
  },
  {
    dataField: "providerName",
    text: "Proveedor",
    sort: true,
  },
  {
    dataField: "number",
    text: "N. Remito",
    sort: true

  },
  {
    dataField: "date",
    text: "Fecha",
    sort: true,
    formatter: (cellContent, row) => moment(cellContent).format("DD-MM-YYYY")
  },
  {
    dataField: "box",
    text: "Caja",
    sort: true,
    formatter: (cell) => cell?.number

  },
  {
    dataField: "remittanceNumberVerified",
    text: "Chk. Núm. R.",
    sort: true,
    align: 'center',
    formatter: (cell) => cell ? <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
      <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>

  },
  {
    dataField: "duplicatesVerified",
    text: "Chk. Duplicado",
    sort: true,
    align: 'center',
    formatter: (cell) => cell ? <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
      <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>

  }
  ,
  {
    dataField: "conformityVerified",
    text: "Chk. Conformidad",
    sort: true,
    align: 'center',
    formatter: (cell) => cell ? <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
      <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>

  },
  {
    dataField: "observationsVerified",
    text: "Chk. Observ.",
    sort: true,
    align: 'center',
    formatter: (cell) => cell ? <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
      <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>

  },
  {
    dataField: "visualInspectionVerified",
    text: "Ctrl. Visual",
    sort: true,
    align: 'center',
    formatter: (cell) => cell ? <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
      <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>

  }
]

export default Columns
