import moment from "moment"
import React from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import DollarIcon from "../Icons/DollarIcon";
import PdfIcon from "../Icons/PdfIcon";
import PencilIcon from "../Icons/PencilIcon";
import PadLockIcon from "../Icons/PadLockIcon";
import PadUnlockIcon from "../Icons/PadUnlockIcon";

const Columns = (view, disassociate, onControl, valorization, handleActivate, role) => [

  {
    dataField: "menu",
    isDummyField: true,
    text: "Acciones",
    style:{width:'5%'},
    formatter: (cellContent, row) => {
      return (
        <>
          <div key={`outboundcolumns_${row?.id}`} className="btn-group  mt-md-0" size="sm" role="group">
            {
              role.name === "Admin" ?
                <>

                  <Button style={{ padding: '0.5em' }} title="Ver remitos" onClick={() => view(row.id)} type="button" color="light"><PdfIcon width="20px" /></Button>
                  <Button disabled={row.statusId==5} style={{ padding: '0.5em' }} title="Prevalorizar" onClick={() => valorization(row.id, row.name)} type="button" color="light"><DollarIcon width="20px" /></Button>
                  <Button style={{ padding: '0.5em' }} title="Control visual" onClick={() => onControl(row.id)} type="button" color="light"><PencilIcon width="20px" /></Button>
                </>
                :
                role.name === 'Viewer' ?
                  <Button style={{ padding: '0.5em' }} title="Ver remitos" onClick={() => view(row.id)} type="button" color="light"><PdfIcon width="20px" /></Button>
                  :
                  role.name === 'Liquidator' ?
                    <>
                      <Button style={{ padding: '0.5em' }} title="Ver remitos" onClick={() => view(row.id)} type="button" color="light"><PdfIcon width="20px" /></Button>
                      <Button disabled={row.statusId==5} style={{ padding: '0.5em' }} title="Prevalorizar" onClick={() => valorization(row.id, row.name)} type="button" color="light"><DollarIcon width="20px" /></Button>
                      <Button style={{ padding: '0.5em' }} title="Control visual" onClick={() => onControl(row.id)} type="button" color="light"><PencilIcon width="20px" /></Button>
                    </>
                    :
                    <>
                      <Button style={{ padding: '0.5em' }} title="Ver remitos" onClick={() => view(row.id)} type="button" color="light"><PdfIcon width="20px" /></Button>
                      <Button disabled={row.statusId==5} style={{ padding: '0.5em' }} title="Prevalorizar" onClick={() => valorization(row.id, row.name)} type="button" color="light"><DollarIcon width="20px" /></Button>
                      {row.rejectionTypeId == null ?
                        <Button disabled style={{ padding: '0.5em' }} title="Remito no rechazado" onClick={() => onControl(row.id)} type="button" color="light"><PadLockIcon width="20px" /></Button>
                        : <Button style={{ padding: '0.5em' }} title="Reactivar remito" onClick={() => handleActivate(row.id)} type="button" color="light"><PadUnlockIcon width="20px" /></Button>
                      }
                      {/* <Button style={{ padding: '0.5em' }} title="Control visual" onClick={() => onControl(row.id)} type="button" color="light"><PadUnlockIcon width="20px" /></Button> */}
                    </>
            }

          </div>
        </>
      )
    },
  },
  {
    dataField: "name",
    text: "P. Carga",
    sort: true,
    style:{width:'5%'},
  },
  {
    dataField: "documentType.name",
    text: "Típo",
    sort: true,
    style:{width:'5%'},
  },
  {
    dataField: "ptrp",
    text: "Ctro. Pl.",
    sort: true,
    style:{width:'5%'},
  },
  {
    dataField: "carrierName",
    text: "Proveedor",
    sort: true,
    style:{width:'20%'},
  },
  {
    dataField: "qttyRemittance",
    text: "Q. Rtos.",
    sort: true,
    align: 'right',
    style:{width:'5%'},
  },
  {
    dataField: "box",
    text: "Caja",
    sort: true,
    style:{width:'5%'},
    formatter: (cell) => cell?.number

  },
  {
    dataField: "preValuation.totalToPay",
    text: "Pre. Val.",
    sort: true,
    align: 'right',
    style:{width:'5%'},
  },
  {
    dataField: "date",
    text: "Fecha",
    sort: true,
    style:{width:'5%'},
    formatter: (cellContent, row) => moment(cellContent).format("DD-MM-YYYY")
  },
  {
    dataField: "status.name",
    text: "Estado",
    sort: true,
    style:{width:'5%'},
    formatter: (cell, row) => <p className="m-1" title={`${row.rejectionType && row.rejectionType?.name}: ${row?.lastReasonRejection} `}>{cell} {cell === 'Rechazado' && <i className="mdi mdi-information-outline font-size-16 text-warning"></i>} </p>
  },
  {
    dataField: "remittances",
    text: "Toneladas",
    sort: true,
    align: 'right',
    style:{width:'5%'},
    formatter: (cell, row) => {
      const sum = cell ? cell?.reduce((accumulator, object) => {
        return accumulator + object?.totalWeight;
      }, 0) : 0;
      return sum.toFixed(2)?.replace(".",",");
    }
  },
  {
    dataField: "visualControl",
    text: "Q. Rtos",
    sort: true,
    align: 'center',
    style:{width:'5%'},
    formatter: (cell) => cell ? <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
      <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>
  },
  {
    dataField: "visualControl",
    text: "Ctrl. Visual",
    sort: true,
    align: 'center',
    style:{width:'5%'},
    formatter: (cell) => cell ? <i className="mdi mdi-check-circle-outline font-size-20 text-success"></i> :
      <i className="mdi mdi-checkbox-blank-circle-outline font-size-20 text-warning"></i>

  },
  {
    dataField: "timeRejection",
    text: "Rechazos",
    sort: true,
    align: 'right',
    style:{width:'5%'},
  },
  {
    dataField: "remittances",
    text: "E. devolución",
    sort: true,
    align: 'center',
    style:{width:'5%'},
    formatter: (cell, row) => {
      if ( row.rejectionUnity == 'T')
        return 'Total';
      else if ( row.rejectionUnity == 'P')
        return 'Parcial';
      else return '-'
    }
  },
]

export default Columns
