import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input, Button, Alert, CardHeader } from "reactstrap";
import { useFormik, Field } from 'formik';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import * as actions from "../../store/users/actions";
import * as userActionTypes from "../../store/users/actionTypes";
import * as carrierActionType from "../../store/carrier/actionTypes";
import * as boxActionTypes from "../../store/boxes/actionTypes";
import { useHistory, useParams } from 'react-router-dom';

const Form = ({
    save,
    update,
    getUsers,
    error,
    users,
    entity,
    carriers }) => {
    let params = useParams();
    const history = useHistory();
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Subir archivos", link: "/upload/file/otm" },
        { title: "OTM", link: "#" },
    ])

    const formik = useFormik({
        initialValues: params.id ? { ...entity } : {},
        onSubmit: values => {
            if (params.id)
                update(values)
            else
                save(values);
        },
        enableReinitialize: params.id,
    });

    
    useEffect(() => {
            getUsers({code:'carrier'})

        return () => {
            formik.resetForm();
        }
    }, [])

    //entidad cargada
    useEffect(() => {
        return () => {
        }
    }, [entity])

    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>

                    <Breadcrumbs title={"Subir archivo OTM"} breadcrumbItems={breadcrumbItems} />
                    <Row>

                        <Col xs={12}>

                            <Card>
                                <CardBody>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Row className="mb-3">
                                            <Label className="col-md-2 col-form-label">N°. Documento</Label>
                                            <Col md={3}>
                                                <select onChange={formik.handleChange} name='userId' className="form-control">
                                                    <option>Seleccione un documento</option>
                                                    {users && users.map(x => (<option selected={x.id == formik.values?.userId} value={x.id}>{x.name}</option>))}
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label className="col-md-2 col-form-label">Archivo</Label>
                                            <Col md={10}>
                                               <input type='file' />
                                            </Col>
                                        </Row>
                                        <Row className="mb-10">
                                            <Label className="col-md-2 col-form-label">Observaciones</Label>
                                            <Col md={10}>
                                               <textarea className="form-control" maxLength={250}></textarea>
                                            </Col>
                                        </Row>
                                      <br></br>
                                        <div style={{ float: 'right' }}>
                                            <Button onClick={() => history.goBack()} color='link'>Volver</Button>{' '}
                                            <Button type='submit' color='primary'>Guardar</Button>

                                        </div>

                                    </form>

                                </CardBody>

                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = state => ({
    users: state.Users?.users,
});

const mapDispatchToProps = (dispatch) => {
    return {
        save: (data) => dispatch({ type: boxActionTypes.SAVE, payload: data }),
        update: (data) => dispatch({ type: boxActionTypes.UPDATE.UPDATE, payload: data }),
        getUsers: (filter) => dispatch({ type: userActionTypes.GET_USERS, payload: filter }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);