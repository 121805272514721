import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Card, CardBody, CardFooter, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import EnvelopeOpen from '../../pages/Icons/EnvelopeOpen';
import paginationFactory from 'react-bootstrap-table2-paginator';
const NotificationColumns = (MarkAsRead)=> [
    {
      text: '',
      formatter: (cell, row) => (
        
        <div className="btn-group  mt-md-0" size="sm" role="group">
          <Button style={{ padding: '0.5em' }} className="btn btn-light" title="Marcar notificación como leída" onClick={() =>MarkAsRead(row.id)} type="button" color="light"><EnvelopeOpen width="20px" /></Button>
        </div>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: '8%', textAlign: 'center' };
      }
    },
    {
      dataField: "content",
      text: "Mensaje",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '92%' };
      }
    },];
const NotificationsModal = ({Notifications, MarkAsRead}) => {
    const [Show, setShow] = useState(true);
    const [PageOptions, setPageOptions] = useState({
        hideSizePerPage: true,
        sizePerPage: 10,
        showTotal: true,
        totalSize: 1, 
        page: 1,
        active: true,
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Mostrando {from} a {to} de {size} Resultados
            </span>
        )
    })
    return (
        <Modal
                size="xl"
                fade={true}
                centered={true}
                isOpen={Show}
                toggle={() => setShow(!Show)}

            >
                <ModalHeader toggle={() => setShow(!Show)}>
                    Mensajes de rechazo
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                            {/* <Spinner size='sm'></Spinner> */}
                            <div className="table table-responsive">
                                            <BootstrapTable
                                                remote
                                                keyField={"id"}
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                classes={
                                                    "table align-middle table-nowrap table-sm"
                                                }
                                                columns={NotificationColumns(MarkAsRead)}
                                                data={ Notifications}
                                                search={false}
                                                headerWrapperClasses={"thead-light"}
                                            />
                                        </div>
                            </CardBody>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal>
    );
};

export default NotificationsModal;