import React from 'react';
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';

const Loader = ({ children, isLoading }) => {

    return (

        isLoading ? <div style={{ width: '100%', textAlign: 'center' }}><Spinner className='primary'></Spinner></div> : <>{children}</>

    )

}

export default Loader;