import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty, size } from "lodash"
import { Alert, Button, ButtonDropdown, ButtonGroup, Card, CardBody, CardFooter, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from "reactstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as loadPlansActionTypes from "../../store/LoadPlans/actionTypes";
import * as statesActionTypes from "../../store/documentState/actionTypes";
import * as carriersActionTypes from "../../store/carrier/actionTypes";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Columns from './Columns';
import { Link, useHistory } from "react-router-dom"
import { Formik, useFormik } from "formik"
import Loader from "../../common/Loader"
import Select from 'react-select'
const List = ({
    list,
    getAll,
    getUsers,
    associate,
    users,
    disassociate,
    totalCount,
    fetching,
    mustBeUpdated,
    getAllDocumentsSTates,
    documentStates,
    open,
    role,
    download,
    rejected,
    carrierCode,
    filter,
    setFilter,
    clearFilter,
    activate }) => {
    const history = useHistory();
    const [ShowAssign, setShowAssign] = useState(false)
    const [Reason, setReason] = useState(0)
    const [ReasonDescription, setReasonDescription] = useState(undefined)
    const [ShowMoreOptions, setShowMoreOptions] = useState(false)
    const [ShowAddObservation, setShowAddObservation] = useState(false)
    const [ShowVisualControl, setShowVisualControl] = useState(false)
    const [ShowDisassociate, setShowDisassociate] = useState(false)
    const [ShowAssociateDocument, setShowAssociateDocument] = useState(false)
    const [ShowOpenDocument, setShowOpenDocument] = useState(false)
    const [SelectedDocumentId, setSelectedDocumentId] = useState(undefined)
    const [SelectedDocumentNumber, setSelectedDocumentNumber] = useState(undefined)
    const [CurrentPage, setCurrentPage] = useState(1)
    const [StateId, setStateId] = useState(filter?.StateId??undefined)
    const [DocumentStatesOptions, setDocumentStatesOptions] = useState([]);
    const [PageOptions, setPageOptions] = useState({
        hideSizePerPage: true,
        sizePerPage: list?.pageSize??0,
        showTotal: true,
        totalSize: list?.rowCount??0, 
        page: list?.currentPage??1,
        active: true,
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Mostrando {from} a {to} de {size} Resultados
            </span>
        )
    })
    const handleControl = (pcId) => {
        history.push(`/outbound/control/${pcId}`)
    }
    useEffect(() => {
        getAll({ size: 10, page: 1, carrierCode: carrierCode,...formikFilter.values })
        getUsers({ typeId: -4 })

        return () => {
        }
    }, [history, carrierCode])

    useEffect(() => {
        mustBeUpdated && getAll({ ...formikFilter.values, carrierCode: carrierCode });


    }, [mustBeUpdated, carrierCode])

    const handleDownload = () => {
        download({ ...formikFilter.values, carrierCode: carrierCode });
    }


    useEffect(() => {
        getAllDocumentsSTates({ type: 1 });
       setDocumentStatesOptions(documentStates.map((x)=>({value:x.id, label:x.name})));
    }, [])

    useEffect(() => {
        if (rejected) {
            setShowVisualControl(false);
        }
    }, [rejected])

    const formikFilter = useFormik({
        initialValues: filter,
        onSubmit: values => {
            setFilter(values);
            getAll({ ...values, page: 1, size: 10, carrierCode: carrierCode, stateId: StateId?.map(x=>(x.value))??[] })
        },
        enableReinitialize:true
        
    });

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.code === "Enter") {
            formikFilter.handleSubmit();
            }
      
    };
    
    const handleClearFilter = () =>{
        clearFilter();
        setFilter({
            Name:'',
            CarrierName:'',
            remittanceNumber:'',
            DateFrom:'',
            DateTo:'',
            documentType: '',

        });
        formikFilter.setValues({})
        
    }
    const handleView = (id) => {
        history.push(`outbound/files/${id}`);
    }

    const handleActivate = (id) =>{
        let res = window.confirm("¿Desea re-activar el plan de carga?");
        if(res){
            activate({id});
        }
    }

    const handleDisassociate = (id) => {
        setSelectedDocumentId(id);
        setShowDisassociate(true)
    }
  

    const handleValorizate = (id,number) => {
        history.push(`outbound/prevalue/${id}/${number}`);
    }

    useEffect(() => {
        setPageOptions({
            hideSizePerPage: true,
            sizePerPage: list?.pageSize??0,
            showTotal: true,
            totalSize: list?.rowCount??0, 
            page: list?.currentPage??1,
            active: true,
            paginationTotalRenderer: (from, to, size) => (
                <span className="react-bootstrap-table-pagination-total">
                    Mostrando {from} a {to} de {size} Resultados
                </span>
            )
        })

    }, [list.data])

    const handlePageChange = (style, values) => {
        setCurrentPage(values?.page);
        getAll({ pageNumber: values?.page, size: 10,carrierCode: carrierCode, ...formikFilter.values})
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{`Inbound`}</h4>

                                <div className="page-title-right">
                                    <Link className='btn btn-light' to={"/dashboard"}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}
                                    <Button onClick={handleDownload} color='primary'><i className="mdi mdi-link-variant"></i> Asignar</Button>{' '}
                                    <Button onClick={handleDownload} color='success'><i className="mdi mdi-folder-download-outline"></i> Descargar</Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                 <Row>

                                        <form onSubmit={formikFilter.handleSubmit}>
                                            <Row > 
                                                <Col sm={3} md={3} lg={2}>
                                                    <Input
                                                    key={"Document_Number"}
                                                        onKeyDown={handleKeypress}
                                                        onChange={formikFilter.handleChange}
                                                        id='DocumentNumber'
                                                        name="Name"
                                                        type="text"
                                                        value={formikFilter?.values?.Name ?? ""}
                                                        placeholder="Ej.: 123456789, 45678912" />
                                                </Col>
                                                <Col sm={3} md={3} lg={2}>
                                                <Input
                                                        onChange={formikFilter.handleChange}
                                                        onKeyDown={handleKeypress}
                                                        id='CarrierName'
                                                        name="CarrierName"
                                                        type="text"
                                                        value={formikFilter.values?.CarrierName ?? ""}
                                                        placeholder="Proveedor" />
                                                </Col>
                                                <Col sm={3} md={3} lg={2}>
                                                    <Input
                                                        onChange={formikFilter.handleChange}
                                                        onKeyDown={handleKeypress}
                                                        id='documentType'
                                                        name="documentType"
                                                        value={formikFilter.values?.documentType ?? ""}
                                                        placeholder="P. Insumo" />
                                                </Col>
                                                <Col sm={2} md={2} lg={1}>
                                                    <Input
                                                        onChange={formikFilter.handleChange}
                                                        id='DateFrom'
                                                        name="DateFrom"
                                                        type="date"
                                                        value={formikFilter.values?.DateFrom ?? ""}
                                                        placeholder="Desde" />
                                                </Col>
                                                <Col sm={2} md={2} lg={1}>
                                                    <Input
                                                        onChange={formikFilter.handleChange}
                                                        onKeyDown={handleKeypress}
                                                        id='DateTo'
                                                        name="DateTo"
                                                        type="date"
                                                        value={formikFilter.values?.DateTo ?? ""}
                                                        placeholder="Hasta" />
                                                </Col>
                                                <Col sm={2}>
                                                    <Select key={filter?.StateId?.lenght?1:0} onKeyDown={handleKeypress} value={StateId} placeholder='Seleccione estado'  isMulti onChange={(x)=>setStateId(x)} name='stateId'  options={DocumentStatesOptions}/>
                                                </Col>
                                                <Col sm="12" className="pt-2" style={{ textAlign: 'end' }}>
                                                    <Button className="btn btn-sm" color="link" onClick={() => {
                                                        handleClearFilter();
                                                        getAll({ page: 1, size: 10 });
                                                    }}>Limpiar</Button>
                                                    <Button className="btn btn-sm" color="primary" onClick={() => {
                                                        formikFilter.handleSubmit();
                                                    }}>Aplicar</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                
                                            </Row>
                                        </form>

                                    </Row>

                                     <hr />
                                    <Loader isLoading={fetching}>

                                        <div className="table table-responsive">

                                            <BootstrapTable
                                                key={"outbound_grid"}
                                                remote={true}
                                                pagination={paginationFactory({ ...PageOptions, page: CurrentPage })}
                                                columns={Columns(handleView, handleDisassociate, handleControl, handleValorizate,handleActivate, role)}

                                                data={list?.data ?? []}
                                                search={Search}
                                                keyField={"id"}
                                                responsive={true}
                                                bordered={false}
                                                striped={true}

                                                onTableChange={handlePageChange}
                                                classes={
                                                    "table align-middle table-nowrap table-sm"
                                                }
                                                

                                                headerWrapperClasses={"thead-light"}
                                            />
                                        </div>
                                    </Loader>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* <Modal
                key={"disassociate"}
                size="sm"
                fade={false}
                centered={true}
                isOpen={ShowDisassociate}
                toggle={() => setShowDisassociate(!ShowDisassociate)}

            >
                <ModalHeader toggle={() => setShowDisassociate(!ShowDisassociate)}>
                    Desasociar documento
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Realmente desea desasociar el documento: </p>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-center block-inline">
                                        <Button className="btn" color="link" onClick={() => setShowDisassociate(false)}>Cerrar</Button> {' '}
                                        <Button className="btn" color="danger" onClick={() => {
                                            setShowDisassociate(false)
                                            disassociate(SelectedDocumentId);
                                        }} >Si, Desasociar</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal> */}

            {/* <Modal
                size="sm"
                fade={false}
                centered={true}
                isOpen={ShowAssociateDocument}
                toggle={() => setShowAssociateDocument(!ShowAssociateDocument)}

            >
                <ModalHeader toggle={() => setShowAssociateDocument(!ShowAssociateDocument)}>
                    Asociar documento
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Asociar documento: {SelectedDocumentNumber} ? </p>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-center block-inline">
                                        <Button className="btn" color="link" onClick={() => setShowAssociateDocument(false)}>Cerrar</Button> {' '}
                                        <Button className="btn" color="success" onClick={() => {
                                            setShowAssociateDocument(false)
                                            associate({ documentNumber: SelectedDocumentNumber });
                                        }} >Si, Asociar</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal> */}


            {/* <Modal
                key={"open"}
                size="sm"
                fade={false}
                centered={true}
                isOpen={ShowOpenDocument}
                toggle={() => setShowOpenDocument(!ShowOpenDocument)}

            >
                <ModalHeader toggle={() => setShowOpenDocument(!ShowOpenDocument)}>
                    Abrir documento
                </ModalHeader>
                <ModalBody>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Realmente desea abrir el documento: {SelectedDocumentNumber} </p>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="text-sm-center block-inline">
                                        <Button className="btn" color="link" onClick={() => setShowOpenDocument(false)}>Cerrar</Button> {' '}
                                        <Button className="btn" color="danger" onClick={() => {
                                            setShowOpenDocument(false)
                                            open(SelectedDocumentId);
                                        }} >Si, Abrir</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </ModalBody>
            </Modal> */}
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    list: state.LoadPlan?.list,
    totalCount: state.LoadPlan?.totalCount,
    selected: state.Inbound?.entity,
    users: state.Carriers.entities,
    role: state.Login?.user?.role??"guest",
    carrierCode: state.Login?.user?.user?.carrierCode,
    fetching: state.LoadPlan?.fetching,
    mustBeUpdated: state.Inbound?.mustBeUpdated,
    documentStates: state.LoadPlan?.states??[],
    rejecting: state.Inbound?.rejecting,
    rejected: state.Inbound?.rejected,
    filter: state.LoadPlan.filter,
    
});


const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (filter) => dispatch({ type: loadPlansActionTypes.GET_ALL, payload: filter }),
        download: (filter) => dispatch({ type: loadPlansActionTypes.DOWNLOAD, payload: filter }),
        reject: (id) => dispatch({ type: loadPlansActionTypes.REJECT, payload: id }),
        activate: (id) => dispatch({ type: loadPlansActionTypes.ACTIVATE, payload: id }),
        getAllDocumentsSTates: (filter) => dispatch({ type: loadPlansActionTypes.GET_ALL_STATES, payload: filter }),
        getUsers: (filter) => dispatch({ type: carriersActionTypes.GET_CARRIERS, payload: filter }),
        setFilter: (filter) => dispatch({ type: loadPlansActionTypes.SET_FILTER, payload: filter }),
        clearFilter: (filter) => dispatch({ type: loadPlansActionTypes.CLEAR_FILTER}),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(List);