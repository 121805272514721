import React from 'react';

const KeyIcon = ({width='16px'}) => {

    return(
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={width}
              height={width}
              viewBox="0 0 72 72"
            >
              <g>
                <path
                  fill="#F4AA41"
                  d="M33.536 31.991c-1.402-4.288-.225-9.41 3.428-13.063 5.018-5.018 12.808-5.364 17.4-.773s4.244 12.381-.774 17.4c-4.057 4.056-10.434 5.512-14.268 2.588"
                ></path>
                <path
                  fill="#F4AA41"
                  stroke="#F4AA41"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M33.652 31.7364L31.2181 34.1872 14.6444 50.5142 14.6444 57.6603 22.0426 57.6603 22.0426 53.0835 27.0544 53.0835 27.0544 47.3024 32.04 47.3024 34.3913 44.9292 34.3913 40.6274 36.3618 40.6274 39.4524 37.5368"
                ></path>
                <path
                  fill="#E27022"
                  d="M15.9847 53.3457L15.9857 51.4386 31.8977 35.8744 32.8505 36.8484z"
                ></path>
                <circle cx="48.52" cy="23.998" r="3.952" fill="#E27022"></circle>
              </g>
              <g
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              >
                <path d="M30.735 34.6557L14.3026 50.6814 14.3026 57.9214 21.868 57.9214 21.868 53.2845 26.9929 53.2845 26.9929 47.4274 32.0913 47.4274 34.4957 45.023 34.4957 40.6647 36.5107 40.6647"></path>
                <circle cx="48.52" cy="23.998" r="3.952"></circle>
                <path d="M34.226 31.178c-1.43-4.238-.347-9.22 3.18-12.695 4.845-4.771 12.465-4.889 17.022-.263s4.322 12.244-.523 17.016c-3.917 3.857-9.648 4.673-14.108 2.4"></path>
              </g>
            </svg>
          );
}

export default KeyIcon;