import React, { Component } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";
import { Link } from "react-router-dom";



class Sources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col lg={12}>
                    <Card>
                        <CardBody>

                            <div>
                                <div className="text-center mt-4 d-flex justify-content-around">
                                    <Link to="/outbound" className="btn btn-success btn-lg">Outbound</Link>
                                    <h3>Seleccionar proceso</h3>
                                    <Link to="/inbound" className="btn btn-success btn-lg">Inbound</Link>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default Sources;