import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, NavLink, NavItem, CardText, Nav, Card, Row, Col, CardBody, CardHeader, Container, Input, Label, Button, Alert } from "reactstrap";
import classnames from "classnames";
import Breadcrumb from '../../../components/Common/Breadcrumb';
import Knob from '../../AllCharts/knob/knob';
import { useFormik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import * as actionTypes from "../../../store/Remittance/actionTypes";
import * as boxActionTypes from "../../../store/boxes/actionTypes";
import { Link } from 'react-router-dom';
import Loader from '../../../common/Loader';

const Upload = ({ upload, uploadAutomatic, boxes, getBox,userId, error}) => {
    const [SelectedFile, setSelectedFile] = useState(null);
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { title: "Dashboard", link: "/Dashboard" },
        { title: "Outbound", link: "/outbound" },
        { title: "Subir archivos", link: "#" },
    ]);
    const [ActiveTab, setActiveTab] = useState(2);
    const [BoxUsage, setBoxUsage] = useState(0)
    const [Uploading, setUploading] = useState(false);

    //ACTOMATIC
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();

    const saveFile =(e)=>{
        setFile(e.target.files);
    }

    const uploadFile = async (e)=>{
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName",fileName);
        
    }

    function getBase64(file) {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    const SelectFile = async (event) => {

        setSelectedFile(await getBase64(event.target.files[0]));
    }
    const convertToBoolean = (object) => {
        return object.includes("on")
    }
    const formik = useFormik({
        initialValues: { remittanceNumber: "", file: null, sign: [], observation: [], duplicated: [] },
        onSubmit: async values => {
            setUploading(true);
            if(ActiveTab === 1){
                upload(
                    {
                        number: values.remittanceNumber,
                        file: SelectedFile,
                        hasSign: convertToBoolean(values.sign),
                        hasObservation: convertToBoolean(values.observation),
                        hasDuplicated: convertToBoolean(values.duplicated),
                    })
            }else if(ActiveTab===2){
                uploadAutomatic(file)
            }
            
            unlock();

        },
    });
    useEffect(() => {
        getBox(userId)
        setBoxUsage(boxes?.usage ?? 0);
    }, [])

    const unlock = () => {
        setTimeout(() => {
            setUploading(false);
        }, 3600);
    }
   


    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                  
                    <Loader isLoading={Uploading}  >
                        <Row>
                            <Col xs="12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">{`Outbound`}</h4>

                                    <div className="page-title-right">
                                        <Link className='btn btn-light' to={"/dashboard"}> <i className="mdi mdi-keyboard-backspace me-2"></i> Volver</Link> {' '}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                        <Col xs="12">
                           {error && <Alert color='warning'>{error?.message}</Alert>}
                        </Col>
                    </Row>
                        {!error &&
                        <form onSubmit={formik.handleSubmit} >
                        <Row>
                            <Col xs="10">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: ActiveTab === 2
                                            })}
                                            onClick={() => {
                                                setActiveTab(2);
                                            }}
                                        >
                                            Automatico
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: ActiveTab === 1
                                            })}
                                            onClick={() => {
                                                setActiveTab(1);
                                            }}
                                        >
                                            Manual
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={ActiveTab}>
                                    <TabPane tabId={1} className="p-3">
                                        <Row className='mb-4'>
                                            <Col sm="4">
                                                <Input autoFocus={true} onChange={formik.handleChange} placeholder='Nro. Remito' name='remittanceNumber' />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4" className='mb-4'>
                                                <input type="file" className="form-control" name="upload_file" onChange={SelectFile} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="8">
                                                <div className="form-check form-switch mb-3">
                                                    <Input onChange={formik.handleChange} type="checkbox" name='sign' className="form-check-input theme-choice" id="checkbox_1" />
                                                    <Label className="form-check-label" htmlFor="checkbox_1"> Firma</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="8">
                                                <div className="form-check form-switch mb-3">
                                                    <Input onChange={formik.handleChange} type="checkbox" name='observation' className="form-check-input theme-choice" id="checkbox_2" />
                                                    <Label className="form-check-label" htmlFor="checkbox_2"> Observaciones</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="8">
                                                <div className="form-check form-switch mb-3">
                                                    <Input onChange={formik.handleChange} type="checkbox" name='duplicated' className="form-check-input theme-choice" id="checkbox_3" />
                                                    <Label className="form-check-label" htmlFor="checkbox_3"> Duplicado</Label>
                                                </div>
                                            </Col>
                                        </Row>


                                    </TabPane>
                                    <TabPane tabId={2} className="p-3">
                                        <Row>
                                            <Col sm="4" className='mb-4'>
                                                <input type="file" className="form-control" multiple name="upload_file" onChange={saveFile} />
                                            </Col>
                                        </Row>
                                    </TabPane>


                                </TabContent>
                            </Col>
                            
                            <Col xs="2" className='mt-4'>
                              {boxes.fetching ? <div className='my-4'>
                              <Loader isLoading={true}></Loader>
                              </div>
                              : 
                              <div className="text-center" dir="ltr">
                              <h5 className="font-size-14 mb-3">Caja: {boxes?.entity?.number ?? 'N/A'}</h5>
                              <Knob
                                  value={boxes?.entity?.usage}
                                  max={6000}
                                  fgColor="#5468da"
                                  thickness={0.12}
                                  readOnly={true}
                                  height={150}
                                  width={100}
                                  onChange={(e) => { }}
                              />
                          </div>}
                            </Col>
                        </Row>
                        <Row>
                            <div className="text-center mt-4">
                                <Link className='btn btn-secondary' to={"/outbound"} > Volver </Link> {' '}
                                <Button color="primary" onClick={() => formik.handleSubmit()} type='button' className="waves-effect waves-light">Enviar archivos</Button>
                            </div>
                        </Row>
                    </form>}
                    </Loader>
                </Container>
            </div>
        </React.Fragment>


    )

}

const mapDispatchToProps = (dispatch) => {
    return {
        upload: (filter) => dispatch({ type: actionTypes.UPLOAD, payload: filter }),
        uploadAutomatic: (filter) => dispatch({ type: actionTypes.UPLOAD_AUTOMATIC, payload: filter }),
        getRemittance: (filter) => dispatch({ type: actionTypes.GET, payload: filter }),
        getBox: (id)=>dispatch({type:boxActionTypes.GET_BY_USER, payload:id})
    }
}
const mapStateToProps = state => ({
    boxes: state.Boxes,
    userId: state.Login?.user?.id,
    error:state.Boxes?.error,

});
export default connect(mapStateToProps, mapDispatchToProps)(Upload);