import * as api from "../../helpers/api_helper";
import qs from "qs";
import { GET_ALL, PUT_RETRANFER} from "./url";

export const getAll = (filter) => api.get(GET_ALL, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});
export const retransferService = (data) => api.post(PUT_RETRANFER, data );