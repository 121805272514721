import React from 'react';

const PencilIcon = ({width='16px'}) => {

    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        viewBox="0 0 24 24"
        >
          <g transform="translate(0 -1028.4) scale(1.5) rotate(45 354.147 934.48)">
            <path fill="#ecf0f1" d="M-63 1003.4v13l2 2 2-2v-13h-4z"></path>
            <path fill="#bdc3c7" d="M-61 1003.4v15l2-2v-13h-2z"></path>
            <path fill="#e67e22" d="M-63 1004.4H-59V1015.4H-63z"></path>
            <path
              fill="#7f8c8d"
              d="M-61 1000.4c-1.105 0-2 .9-2 2v1h4v-1c0-1.1-.895-2-2-2z"
            ></path>
            <path
              fill="#34495e"
              d="M-55.406 1016l1.406 1.4 1.406-1.4h-2.812z"
              transform="translate(-7 1)"
            ></path>
            <path
              fill="#2c3e50"
              d="M-54 1016v1.4l1.406-1.4H-54z"
              transform="translate(-7 1)"
            ></path>
            <path fill="#95a5a6" d="M-61 1000.4c-1.105 0-2 .9-2 2v1h2v-3z"></path>
            <path fill="#d35400" d="M-61 1004.4H-59V1015.4H-61z"></path>
          </g>
        </svg>
    )

}

export default PencilIcon;