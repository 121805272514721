import { call, put, takeEvery } from "redux-saga/effects"
import FileSaver from 'file-saver';
// Ecommerce Redux States
import {
  ASSOCIATE,
  DISSASOCIATE,
  DOWNLOAD,
  GET, GET_ALL, GET_BY_DOCUMENT_NUMBER, GET_XML_TRANSSMITIONS, OPEN, REJECT, SAVE, SAVE_PREVALORIZATION, TYPES, UPDATE, UPDATE_TYPES
} from "./actionTypes"
import {
  getFail,
  getSuccess,
  getAllFail,
  getAllSuccess,
  saveFail,
  saveSuccess,
  updateFail,
  disassociateSuccess,
  disassociateFail,
  getAllXmlSuccess,
  getAllXmlFail,
  getAllTypes,
  getAllTypesFail,
  getAllTypesSuccess,
  updateTypeSuccess,
  updateTypeFail,
  getByDocumentSuccess,
  getByDocumentFail,
  associateFail,
  associateSuccess,
  openSuccess,
  openFail,
  rejectSuccess,
  rejectFail

} from "./actions"

import { get, getAll, post, update, disassociateService, getAllTransmissions, getTypes, putTypes, getByDocumentNumber, associateService, openService, rejectService, exportDocument, savePrevalorizationService } from "./Service"
import toast from "react-hot-toast"



function* fetchAll({ payload }) {
  try {
    const response = yield call(getAll, payload)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}

function* getByDocument({ payload }) {
  try {
    const response = yield call(getByDocumentNumber, payload)
    yield put(getByDocumentSuccess(response))
  } catch (error) {
    yield put(getByDocumentFail(error))
  }
}

function* xlsTransmissions({ payload }) {
  try {
    const response = yield call(getAllTransmissions, payload)
    yield put(getAllXmlSuccess(response))
  } catch (error) {
    yield put(getAllXmlFail(error))
  }
}

function* getParametrizationList({ payload }) {
  try {
    const response = yield call(getTypes, payload)
    yield put(getAllTypesSuccess(response))
  } catch (error) {
    yield put(getAllTypesFail(error))
  }
}
function* updateTypes({ payload }) {
  try {
    const response = yield call(putTypes, payload)
    yield put(updateTypeSuccess(response))
  } catch (error) {
    yield put(updateTypeFail(error))
  }
}

function* disassociate({ payload }) {
  try {
    const response = yield call(disassociateService, payload)
    if (response)
      toast.success("Documento desasociado correctamente");
    else
      toast.error("No se puede desasociar el documento, verifique el estado");
    yield put(disassociateSuccess(response))
  } catch (error) {
    yield put(disassociateFail(error))
  }
}
function* associate({ payload }) {
  try {
    const response = yield call(associateService, payload)
    if (response)
      toast.success("Documento associado correctamente");
    else
      toast.error("No se puede associar el documento, verifique el estado");
    yield put(associateSuccess(response))
  } catch (error) {
    yield put(associateFail(error))
  }
}

function* open({ payload }) {
  try {
    const response = yield call(openService, payload)
    if (response)
      toast.success("Documento abierto correctamente");
    else
      toast.error("No se puede abrir el documento, verifique el estado");
    yield put(openSuccess(response))
  } catch (error) {
    yield put(openFail(error))
  }
}


function* rejectDocument({ payload }) {
  try {
    const response = yield call(rejectService, payload)
    if (response)
      toast.success("Documento rechazado");
    else
      toast.error("No se puede rechazar el documento, verifique el estado");
    yield put(rejectSuccess(response))
  } catch (error) {
    toast.error("Error al rechazar el documento. Consulte a los administradores.");
    yield put(rejectFail(error))
  }
}

function* fetchEntity({ payload }) {
  try {
    const response = yield call(get, payload)
    yield put(getSuccess(response))
  } catch (error) {
    yield put(getFail(error))
  }
}

function* save(data) {
  try {
    const response = yield call(post, data.payload)
    yield put(saveSuccess(response))
  } catch (error) {
    yield put(saveFail(error))
  }
}

function* updateEntity(data) {
  try {
    const response = yield call(update, data.payload)
    yield put(update(response))
  } catch (error) {
    yield put(updateFail(error))
  }
}
function* download(data) {
  try {
    const response = yield call(exportDocument, data.payload)
    yield call(FileSaver.saveAs, response,`Documents_${new Date().getMilliseconds()}.xlsx`)
  } catch (error) {
  }
}





function* inboundSaga() {
  yield takeEvery(GET, fetchEntity)
  yield takeEvery(GET_BY_DOCUMENT_NUMBER, getByDocument)
  yield takeEvery(GET_ALL, fetchAll)
  yield takeEvery(SAVE, save)
  yield takeEvery(UPDATE, updateEntity)
  yield takeEvery(DISSASOCIATE, disassociate)
  yield takeEvery(ASSOCIATE, associate)
  yield takeEvery(OPEN, open)
  yield takeEvery(GET_XML_TRANSSMITIONS, xlsTransmissions)
  yield takeEvery(TYPES, getParametrizationList)
  yield takeEvery(UPDATE_TYPES, updateTypes)
  yield takeEvery(REJECT, rejectDocument)
  yield takeEvery(DOWNLOAD, download)
}

export default inboundSaga
