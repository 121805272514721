import React, { Component, useState, useEffect } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';

// actions
import { clearVariables } from "../../store/users/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import ApiErrorList from './ApiErrorList';



const Login = (props) => {

    // const [state, setState] = useState({ username: "admin@themesdesign.in", password: "123456" })
    const [state, setState] = useState({ username: undefined, password: undefined })
    const handleSubmit = (event, values) => {
        props.checkLogin(values, props.history);
    }

    useEffect(() => {
        props.clearVariables();
        props.apiError("");
        document.body.classList.add("auth-body-bg");
        return () => document.body.classList.remove("auth-body-bg");
    }, [])

   
    
    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className=''>
                                                            <img src={logodark} alt="" height="100" class="auth-logo logo-dark mx-auto" />
                                                            <img src={logolight} alt="" height="100" class="auth-logo logo-light mx-auto" />
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Bienvenido!</h4>
                                                    <p className="text-muted">Inicia sesión para continuar.</p>
                                                </div>



                                                <div className="p-2 mt-5">
                                                    {/* {props.loginError && props.loginError ? <Alert color="danger">{props.loginError}</Alert> : null} */}
                                                 
                                                    <ApiErrorList apiResponse={props.loginError?.errors} />
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="username">Usuario</Label>
                                                            <AvField name="username" value={state.username} type="text" className="form-control" id="username" placeholder="Enter username" />
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Contraseña</Label>
                                                            <AvField name="password" value={state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                                        </div>

                                                        <div className="form-check">
                                                            <Input type="checkbox" className="form-check-input" id="customControlInline" />
                                                            <Label className="form-check-label" htmlFor="customControlInline">Recordar mi selección</Label>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">Ingresar</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> ¿Olvidó su contraseña?</Link>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                {/* <div className="mt-5 text-center">
                                                    <p>¿No tienes cuenta? <Link to="/register" className="fw-medium text-primary"> Registrar </Link> </p>
                                                    <p>© 2021 Nazox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                                </div> */}
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError, clearVariables })(Login));