import * as api from "../../helpers/api_helper";
import qs from "qs";
import { GET, GET_ALL} from "./url";

export const getAll = (filter) => api.get(GET_ALL, {
  params: { ...filter },
  paramsSerializer: params => {
    return qs.stringify(params)
  }
});


export const get = ({route,data, automatic}) => api.get(`${process.env.REACT_APP_URL}files/${route}?data=${data}&automatic=${automatic}`);
// export const post = (entity) => api.post(POST, entity);
// export const update = (entity) => api.put(PUT, entity);
// export const putTypes = (entity) => api.put(PUT_TOGGLE, entity);