import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
GET_ALL,
GET_ALL_FAIL,
GET_ALL_SUCCESS,
MARK_AS_READ
} from "./actionTypes"
import {
  getAll,
  getAllFail,
  getAllSuccess
} from "./actions"
import {getAllService, markAsReadService,  } from "./Service"



function* fetchAll() {
  try {
    const response = yield call(getAllService)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}

function* markAsRead({payload}) {
  try {
    const response = yield call(markAsReadService,payload)
    yield put(getAllSuccess(response))
  } catch (error) {
    yield put(getAllFail(error))
  }
}



function* notificationSaga() {
  yield takeEvery(GET_ALL, fetchAll)
  yield takeEvery(MARK_AS_READ, markAsRead)
}

export default notificationSaga
