import {
  GET,
  GET_FAIL,
  GET_SUCCESS,
  GET_ALL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS
} from "./actionTypes"

export const getAll = (payload) => ({
  type: GET_ALL,
  payload
})

export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})



export const get = ({route,data, automatic}) => ({
  type: GET,
  payload: {route,data,automatic},
})

export const getSuccess = entity => ({
  type: GET_SUCCESS,
  payload: entity,
})

export const getFail = error => ({
  type: GET_FAIL,
  payload: error,
})
