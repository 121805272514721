import {
  GET,
  GET_FAIL,
  GET_SUCCESS,
  GET_ALL,
  GET_ALL_FAIL,
  GET_ALL_SUCCESS,
  SAVE,
  SAVE_FAIL,
  SAVE_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  TYPES,
  TYPES_SUCCESS,
  TYPES_FAIL,
  UPDATE_TYPES,
  UPDATE_TYPES_SUCCESS,
  UPDATE_TYPES_FAIL,
  GET_BY_DOCUMENT_NUMBER,
  GET_BY_DOCUMENT_NUMBER_SUCCESS,
  GET_BY_DOCUMENT_NUMBER_FAIL,
  DOWNLOAD,
  GET_COUNT,
  GET_COUNT_SUCCESS,
  REJECT,
  REJECT_SUCCESS,
  REJECT_FAIL,
  ACCEPT,
  ACCEPT_SUCCESS,
  ACCEPT_FAIL,
  ACTIVATE,
  ACTIVATE_SUCCESS,
  ACTIVATE_FAIL,
  SET_FILTER,
  CLEAR_FILTER,
  GET_PREVALUATION,
  GET_PREVALUATION_SUCCESS,
  GET_PREVALUATION_FAIL,
  GET_ALL_STATES,
  GET_ALL_STATES_SUCCESS,
  GET_ALL_STATES_FAIL,
  
} from "./actionTypes"

export const setFilter = (payload) => ({
  type: SET_FILTER,
  payload
})

export const clearFilter = () => ({
  type: CLEAR_FILTER
})


export const getAll = (payload) => ({
  type: GET_ALL,
  payload
})

export const download = (payload) => ({
  type: DOWNLOAD,
  payload
})
export const getAllSuccess = list => ({
  type: GET_ALL_SUCCESS,
  payload: list,
})

export const getAllFail = error => ({
  type: GET_ALL_FAIL,
  payload: error,
})


export const getAllStates = () => ({
  type: GET_ALL_STATES
})

export const getAllStatesSuccess = list => ({
  type: GET_ALL_STATES_SUCCESS,
  payload: list,
})

export const getAllStatesFail = error => ({
  type: GET_ALL_STATES_FAIL,
  payload: error,
})

export const reject = (payload) => ({
  type: REJECT,
  payload
})


export const rejectSuccess = list => ({
  type: REJECT_SUCCESS,
  payload: list,
})

export const rejectFail = error => ({
  type: REJECT_FAIL,
  payload: error,
})

export const accept = (payload) => ({
  type: ACCEPT,
  payload
})


export const acceptSuccess = list => ({
  type: ACCEPT_SUCCESS,
  payload: list,
})

export const acceptFail = error => ({
  type: ACCEPT_FAIL,
  payload: error,
})

export const activate = (payload) => ({
  type: ACTIVATE,
  payload
})


export const activateSuccess = list => ({
  type: ACTIVATE_SUCCESS,
})

export const activateFail = error => ({
  type: ACTIVATE_FAIL,
  payload: error,
})

export const getCount = (payload) => ({
  type: GET_COUNT,
  payload
})


export const getCountSuccess = totalCount => ({
  type: GET_COUNT_SUCCESS,
  payload: totalCount,
})


export const getAllTypes = (payload) => ({
  type: TYPES,
  payload
})

export const getAllTypesSuccess = list => ({
  type: TYPES_SUCCESS,
  payload: list,
})

export const getAllTypesFail = error => ({
  type: TYPES_FAIL,
  payload: error,
})


export const updateType = (entity) => ({
  type: UPDATE_TYPES,
  payload: entity
})

export const updateTypeSuccess = result => ({
  type: UPDATE_TYPES_SUCCESS,
  payload: result,
})

export const updateTypeFail = error => ({
  type: UPDATE_TYPES_FAIL,
  payload: error,
})
export const get = (id) => ({
  type: GET,
  payload: id,
})

export const getSuccess = entity => ({
  type: GET_SUCCESS,
  payload: entity,
})

export const getFail = error => ({
  type: GET_FAIL,
  payload: error,
})


export const getPrevaluation = (id) => ({
  type: GET_PREVALUATION,
  payload: id,
})

export const getPrevaluationSuccess = entity => ({
  type: GET_PREVALUATION_SUCCESS,
  payload: entity,
})

export const getPrevaluationFail = error => ({
  type: GET_PREVALUATION_FAIL,
  payload: error,
})

export const getByDocument = (number) => ({
  type: GET_BY_DOCUMENT_NUMBER,
  payload: number,
})

export const getByDocumentSuccess = entity => ({
  type: GET_BY_DOCUMENT_NUMBER_SUCCESS,
  payload: entity,
})

export const getByDocumentFail = error => ({
  type: GET_BY_DOCUMENT_NUMBER_FAIL,
  payload: error,
})


export const save = (entity) => ({
  type: SAVE,
  payload: entity
})

export const saveSuccess = result => ({
  type: SAVE_SUCCESS,
  payload: result,
})

export const saveFail = error => ({
  type: SAVE_FAIL,
  payload: error,
})

export const update = (entity) => ({
  type: UPDATE,
  payload: entity
})

export const updateSuccess = result => ({
  type: UPDATE_SUCCESS,
  payload: result,
})

export const updateFail = error => ({
  type: UPDATE_FAIL,
  payload: error,
})

